const namespace = 'admin/'

export const Types = {
  getters: {
    GET_ADMIN: namespace + 'GET_ADMIN'
  },
  mutations: {
    SET_ADMIN: namespace + 'SET_ADMIN',
    SYNC_ADMIN: namespace + 'SYNC_ADMIN'
  },
  actions: {
  }
}
