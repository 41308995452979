<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="コイン一覧"/>
      </v-col>
      <v-col cols="12">
        <CoinsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import CoinsListTable from '../../organisms/coins/CoinsListTable'

export default {
  components: {
    PageHeading,
    CoinsListTable
  }
}
</script>
