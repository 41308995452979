import http from '../../../packs/http'
import { removeNamespace } from '../../helper'
import { Types as GlobalTypes } from '../../types'
import { Types } from './types'

const API_PATH = 'admin/departments/'
const _types = removeNamespace('departments/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null
  }
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_DEPARTMENTS_LIST]: state => state.list,
  [_types.getters.GET_DEPARTMENTS_DETAIL]: state => state.detail
}

const mutations = {
  [_types.mutations.SET_DEPARTMENTS_LIST] (state, payload) {
    if (payload.master && state.list.length) return
    state.list = Object.assign([], _initialState.list, payload.list)
  },
  [_types.mutations.SET_DEPARTMENTS_DETAIL] (state, payload) {
    state.detail = Object.assign({}, _initialState.detail, payload)
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    await http.get(API_PATH).then(response => {
      commit(_types.mutations.SET_DEPARTMENTS_LIST, {
        list: response.data.data.map(
          department => Object.assign(
            department.attributes,
            {
              users: department.relationships.users.data
            }
          )
        )
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.CREATE] ({ commit }, payload) {
    const params = JSON.stringify({
      department: payload
    })
    await http.post(API_PATH, params).then(response => {
      commit(_types.mutations.SET_DEPARTMENTS_DETAIL, response.data.data.attributes)
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, '部署を作成しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.SHOW] ({ commit }, id) {
    commit(_types.mutations.SET_DEPARTMENTS_DETAIL, {})
    await http.get(API_PATH + id).then(response => {
      commit(_types.mutations.SET_DEPARTMENTS_DETAIL, Object.assign(response.data.data.attributes, { users: response.data.included }))
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.UPDATE] ({ commit }, payload) {
    const params = JSON.stringify({
      department: payload
    })
    await http.put(API_PATH + payload.id, params).then(response => {
      commit(_types.mutations.SET_DEPARTMENTS_DETAIL, Object.assign(response.data.data.attributes, { users: response.data.included }))
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, '部署を更新しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.DESTROY] ({ commit }, id) {
    await http.delete(API_PATH + id).then(response => {
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, '部署を削除しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
