import axios from 'axios'
import { pick } from 'lodash'
import vueCookie from 'vue-cookie'
// import status from 'http-status'
import store from '../store'
import { Types as GlobalTypes } from '../store/types'

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api/v1/' : process.env.API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use((request) => {
  var session = vueCookie.get('session')
  if (session) {
    session = JSON.parse(session)
    request.headers['access-token'] = session['access-token']
    request.headers['client'] = session['client']
    request.headers['uid'] = session['uid']
  }
  request.timeout = 30000
  store.dispatch(GlobalTypes.actions.REQUEST)
  return request
}, function (error) {
  return Promise.reject(error)
})

http.interceptors.response.use((response) => {
  if ('access-token' in response.headers &&
      'client' in response.headers &&
      'uid' in response.headers) {
    const authHeaders = pick(
      response.headers,
      [
        'access-token',
        'client',
        'expiry',
        'uid',
        'token-type'
      ]
    )
    vueCookie.set('session', JSON.stringify(authHeaders), { expires: '14D' })
  }
  store.dispatch(GlobalTypes.actions.RESPONSE)
  return response
}, (error) => {
  store.dispatch(GlobalTypes.actions.RESPONSE)
  return Promise.reject(error)
})

export default http
