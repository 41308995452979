<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="部署一覧"/>
      </v-col>
      <v-col cols="12">
        <DepartmentsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import DepartmentsListTable from '../../organisms/departments/DepartmentsListTable'

export default {
  components: {
    PageHeading,
    DepartmentsListTable
  }
}
</script>