<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
      <v-row class="d-flex justify-start">
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="incentiveName"
            label="インセンティブ名"
            filled
            disabled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="userName"
            label="交換者"
            filled
            disabled>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="requestedAt"
            label="交換申請日"
            filled
            disabled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="completedAt"
            label="交換完了日"
            filled
            disabled>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="incentiveExchangeModel.status"
            :items="statusOptions"
            label="ステータス"
            :filled="isCanceled"
            :hint="hint"
            :disabled="!isEditable || isCanceled"
            persistent-hint>
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-if="incentiveExchangeModel.status === 3"
            v-model="incentiveExchangeModel.canceled_reason"
            label="キャンセル理由"
            name="incentiveExchange[canceled_reason]"
            v-validate="(incentiveExchangeModel.status === 3 ? 'required' : '')"
            data-vv-as="キャンセル理由"
            :customValidation="isEditable && validated && incentiveExchangeModel.status === 3"
            :isValid="!errors.has('incentiveExchange[canceled_reason]')"
            :error-messages="errors.collect('incentiveExchange[canceled_reason]')"
            :disabled="!isEditable"
            auto-grow>
            <template v-slot:label>
              キャンセル理由<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn v-if="isCanceled" color="primary" @click="onSubmit">
          更新
        </v-btn>
        <v-btn v-else color="primary" @click="toggleChangeStatusDialog">
          更新
        </v-btn>
      </div>
      <v-dialog v-model="showChangeStatusDialog" width="550">
        <v-card>
          <template v-if="incentiveExchangeModel.status === 3">
            <v-card-title class="headline error white--text" primary-title>確認</v-card-title>
            <v-card-text class="pt-5 grey--text text--darken-2">
              一度キャンセルするともう他のステータスに変更することはできません。<br>
              この交換履歴のステータスを変更してよろしいですか？
            </v-card-text>
          </template>
          <template v-else>
            <v-card-title class="headline primary white--text" primary-title>確認</v-card-title>
            <v-card-text class="pt-5 grey--text text--darken-2">この交換履歴のステータスを変更してよろしいですか？</v-card-text>
          </template>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="toggleChangeStatusDialog">キャンセル</v-btn>
            <v-btn
              :class="incentiveExchangeModel.status === 3 ? 'error white--text' : 'primary white--text'"
               @click="onSubmit">
              変更する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as IncentiveExchangesTypes } from '../../../store/modules/incentiveExchanges/types'

export default {
  name: 'IncentiveExchangesForm',
  props: {
    type: String
  },
  data () {
    return {
      incentiveExchangeModel: {},
      statusOptions: [],
      incentiveName: '',
      userName: '',
      requestedAt: '',
      completedAt: '',
      imageSrc: '',
      beforeStatus: '',
      status: '',
      hint: '',
      isEditable: false,
      isCanceled: false,
      showChangeStatusDialog: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      incentiveExchange: IncentiveExchangesTypes.getters.GET_INCENTIVE_EXCHANGES_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetIncentiveExchange: IncentiveExchangesTypes.actions.SHOW,
      apiUpdateIncentiveExchange: IncentiveExchangesTypes.actions.UPDATE,
      apiDestroyIncentiveExchange: IncentiveExchangesTypes.actions.DESTROY,
      resetIncentiveExchange: IncentiveExchangesTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetIncentiveExchange()
      this.incentiveExchangeModel = this.incentiveExchange
    },
    toggleChangeStatusDialog: function () {
      this.showChangeStatusDialog = !this.showChangeStatusDialog
    },
    onSubmit: function () {
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          this.requestUpdate()
        }
      })
    },
    async requestDestroy () {
      await this.apiDestroyIncentiveExchange(this.incentiveExchange.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexIncentiveExchange' })
      }, 30)
    },
    async requestUpdate () {
      if (this.beforeStatus !== 3) this.toggleChangeStatusDialog()
      await this.apiUpdateIncentiveExchange(this.incentiveExchangeModel)
      await this.apiGetIncentiveExchange(this.$route.params.id)
      if (this.incentiveExchange.status === 3) this.isCanceled = true
      this.completedAt = this.incentiveExchange.completed_at ? moment(this.incentiveExchange.completed_at).format('YYYY/MM/DD') : ''
      this.beforeStatus = this.incentiveExchange.status
      this.setStatusOptions()
      this.toggleEditable()
    },
    setStatusOptions: function () {
      if (this.incentiveExchange.status === 0) {
        this.statusOptions = [
          { text: '申請済み', value: 0 },
          { text: '申請受理', value: 1 },
          { text: '管理者キャンセル', value: 3 }
        ]
      } else if (this.incentiveExchange.status === 1) {
        this.statusOptions = [
          { text: '申請受理', value: 1 },
          { text: '交換済み', value: 2 },
          { text: '管理者キャンセル', value: 3 }
        ]
      } else if (this.incentiveExchange.status === 2) {
        this.statusOptions = [
          { text: '申請受理', value: 1 },
          { text: '交換済み', value: 2 }
        ]
      } else if (this.incentiveExchange.status === 3) {
        this.statusOptions = [
          { text: '管理者キャンセル', value: 3 }
        ]
      } else {
        this.statusOptions = [
          { text: 'ユーザーキャンセル', value: 4 }
        ]
      }
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.apiGetIncentiveExchange(this.$route.params.id)
      this.incentiveExchangeModel = this.incentiveExchange
      this.incentiveName = this.incentiveExchangeModel.incentive ? this.incentiveExchangeModel.incentive.attributes.name : '[ このインセンティブは削除されました ]'
      this.userName = this.incentiveExchangeModel.user ? this.incentiveExchangeModel.user.attributes.last_name + this.incentiveExchangeModel.user.attributes.first_name : '[ このユーザーは削除されました ]'
      this.requestedAt = moment(this.incentiveExchangeModel.created_at).format('YYYY/MM/DD')
      this.completedAt = this.incentiveExchangeModel.completed_at ? moment(this.incentiveExchangeModel.completed_at).format('YYYY/MM/DD') : ''
      this.setStatusOptions()
      if (this.incentiveExchange.status === 3 || this.incentiveExchange.status === 4) this.isCanceled = true
      this.beforeStatus = this.incentiveExchange.status
    } else {
      this.isEditable = !this.isEditable
    }
  },
  watch: {
    'incentiveExchangeModel.status' (val) {
      if (this.incentiveExchangeModel.status === 0) {
        this.hint = '「申請済み」 : ユーザーからの申請を、管理者がまだ受理していない状態です'
      } else if (this.incentiveExchangeModel.status === 1) {
        this.hint = '「申請受理」 : ユーザーからの申請を、管理者がすでに受理した状態です'
      } else if (this.incentiveExchangeModel.status === 2) {
        this.hint = '「交換済み」 : 商品が交換された状態です'
      } else if (this.incentiveExchangeModel.status === 3) {
        this.hint = '「管理者キャンセル」 : 管理者によって取引がキャンセルされた状態です'
      } else {
        this.hint = '「ユーザーキャンセル」 : ユーザーによって取引がキャンセルされた状態です'
      }
    }
  }
}
</script>
