const namespace = 'users/'

export const Types = {
  getters: {
    GET_USERS_LIST: namespace + 'GET_USERS_LIST',
    GET_USERS_LIST_PAGENATION: namespace + 'GET_USERS_LIST_PAGENATION'
  },
  mutations: {
    SET_USERS_LIST: namespace + 'SET_USERS_LIST',
    SET_USERS_DETAIL: namespace + 'SET_USERS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS'
  },
  actions: {
    INDEX: namespace + 'INDEX'
  }
}
