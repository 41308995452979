const namespace = 'groups/'

export const Types = {
  getters: {
    GET_GROUPS_LIST: namespace + 'GET_GROUPS_LIST',
    GET_GROUPS_LIST_PAGENATION: namespace + 'GET_GROUPS_LIST_PAGENATION',
    GET_GROUPS_DETAIL: namespace + 'GET_GROUPS_DETAIL',
    GET_GROUPS_EXPORT: namespace + 'GET_GROUPS_EXPORT'
  },
  mutations: {
    SET_GROUPS_LIST: namespace + 'SET_GROUPS_LIST',
    SET_GROUPS_DETAIL: namespace + 'SET_GROUPS_DETAIL',
    SET_GROUPS_DETAIL_USERS: namespace + 'SET_GROUPS_DETAIL_USERS',
    RESET_GROUPS_DETAIL: namespace + 'RESET_GROUPS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS',
    SET_GROUPS_EXPORT: namespace + 'SET_GROUPS_EXPORT'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    EXPORT: namespace + 'EXPORT',
    CHANGE_OWNER: namespace + 'CHANGE_OWNER',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
