const namespace = 'notificationComments/'

export const Types = {
  getters: {
    GET_NOTIFICATION_COMMENTS: namespace + 'GET_NOTIFICATION_COMMENTS'
  },
  mutations: {
    SET_NOTIFICATION_COMMENTS: namespace + 'SET_NOTIFICATION_COMMENTS'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    CREATE: namespace + 'CREATE',
    DESTROY: namespace + 'DESTROY',
    REPORT: namespace + 'REPORT'
  }
}
