const namespace = 'notificationCommentsReports/'

export const Types = {
  getters: {
    GET_NOTIFICATION_COMMENT_REPORTS_LIST: namespace + 'GET_NOTIFICATION_COMMENT_REPORTS_LIST',
    GET_NOTIFICATION_COMMENT_REPORTS_LIST_PAGENATION: namespace + 'GET_NOTIFICATION_COMMENT_REPORTS_LIST_PAGENATION',
    GET_NOTIFICATION_COMMENT_REPORTS_DETAIL: namespace + 'GET_NOTIFICATION_COMMENT_REPORTS_DETAIL',
    GET_NOTIFICATION_COMMENT_REPORTS_EXPORT: namespace + 'GET_NOTIFICATION_COMMENT_REPORTS_EXPORT'
  },
  mutations: {
    SET_NOTIFICATION_COMMENT_REPORTS_LIST: namespace + 'SET_NOTIFICATION_COMMENT_REPORTS_LIST',
    SET_NOTIFICATION_COMMENT_REPORTS_DETAIL: namespace + 'SET_NOTIFICATION_COMMENT_REPORTS_DETAIL',
    RESET_NOTIFICATION_COMMENT_REPORTS_DETAIL: namespace + 'RESET_NOTIFICATION_COMMENT_REPORTS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS',
    SET_NOTIFICATION_COMMENT_REPORTS_EXPORT: namespace + 'SET_NOTIFICATION_COMMENT_REPORTS_EXPORT'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    EXPORT: namespace + 'EXPORT',
    RESTORE: namespace + 'RESTORE',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
