<template>
  <v-app>
    <HeaderSideNav :signIn="hasSignedIn" :displayProgress="shouldDisplayProgress" :admin="currentAdmin"/>
    <ToastMessage v-if="toast_attributes.active" :toast="toast_attributes" />
    <v-content>
      <BreadCrumb v-if="hasSignedIn"/>
      <router-view class="pt-5"/>
    </v-content>
  </v-app>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from './store/types'
import { Types as AuthTypes } from './store/modules/auth/types'
import { Types as AdminTypes } from './store/modules/admin/types'

// components
import HeaderSideNav from './components/molecules/HeaderSideNav'
import BreadCrumb from './components/molecules/BreadCrumb'
import ToastMessage from './components/molecules/ToastMessage'

export default {
  components: {
    HeaderSideNav,
    BreadCrumb,
    ToastMessage
  },
  data: function () {
    return {
      toast_attributes: {
        active: false,
        message: '',
        color: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAdmin: AdminTypes.getters.GET_ADMIN,
      hasSignedIn: AuthTypes.getters.HAS_SIGNED_IN,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      httpError: GlobalTypes.getters.GET_HTTP_ERROR,
      shouldDisplayProgress: GlobalTypes.getters.SHOULD_DISPLAY_PROGRESS
    })
  },
  methods: {
    ...mapActions({
      validateAuth: AuthTypes.actions.VALIDATE,
      resetStatus: GlobalTypes.actions.RESET_STATUS
    })
  },
  async created () {
    await this.validateAuth()
  },
  watch: {
    httpSuccess (success) {
      if (!success) return
      this.toast_attributes.active = true
      this.toast_attributes.message = success
      this.toast_attributes.color = 'success'
    },
    httpError (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 403:
            this.$router.push({ name: 'forbidden' })
            break
          case 404:
            this.$router.push({ name: 'notFound' })
            break
          case 422:
            this.$router.push({ name: 'unprocessableEntity' })
            break
          case 500:
            this.$router.push({ name: 'internalServerError' })
            this.toast_attributes.active = true
            this.toast_attributes.message = '予期せぬエラーが発生しました。'
            this.toast_attributes.color = 'error'
            break
          case 401:
            if (error.response.data.errors) {
              for (const errorMessage of error.response.data.errors) {
                this.toast_attributes.active = true
                this.toast_attributes.message = errorMessage
                this.toast_attributes.color = 'error'
                if (errorMessage === 'ログインが必要です') {
                  this.resetStatus()
                  if (!this.hasSignedIn) {
                    this.$router.push({ name: 'signIn' })
                  }
                }
              }
            } else {
              if (this.$route.path === '/' || this.$route.path === '/sign_in') return
              this.toast_attributes.active = true
              this.toast_attributes.message = '予期せぬエラーが発生しました。'
              this.toast_attributes.color = 'error'
            }
            break
          default:
            if (error.response.data.errors) {
              for (const errorMessage of error.response.data.errors) {
                this.toast_attributes.active = true
                this.toast_attributes.message = errorMessage
                this.toast_attributes.color = 'error'
              }
            } else {
              this.toast_attributes.active = true
              this.toast_attributes.message = '予期せぬエラーが発生しました。'
              this.toast_attributes.color = 'error'
            }
        }
      }
    }
  }
}
</script>

<style>
@import url('./styles/app.css');
</style>
