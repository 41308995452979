<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="コメント通報履歴一覧"/>
      </v-col>
      <v-col cols="12">
        <NotificationCommentReportsSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <NotificationCommentReportsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import NotificationCommentReportsSearchForm from '../../organisms/notificationCommentReports/NotificationCommentReportsSearchForm'
import NotificationCommentReportsListTable from '../../organisms/notificationCommentReports/NotificationCommentReportsListTable'

export default {
  components: {
    PageHeading,
    NotificationCommentReportsSearchForm,
    NotificationCommentReportsListTable
  }
}
</script>
