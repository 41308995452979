const namespace = 'importedPoints/'

export const Types = {
  getters: {
    GET_IMPORTED_POINTS_LIST: namespace + 'GET_IMPORTED_POINTS_LIST',
    GET_IMPORTED_POINTS_LIST_PAGENATION: namespace + 'GET_IMPORTED_POINTS_LIST_PAGENATION',
    GET_IMPORTED_POINTS_DETAIL: namespace + 'GET_IMPORTED_POINTS_DETAIL',
    GET_IMPORTED_POINTS_EXPORT: namespace + 'GET_IMPORTED_POINTS_EXPORT'
  },
  mutations: {
    SET_IMPORTED_POINTS_LIST: namespace + 'SET_IMPORTED_POINTS_LIST',
    SET_IMPORTED_POINTS_DETAIL: namespace + 'SET_IMPORTED_POINTS_DETAIL',
    RESET_IMPORTED_POINTS_DETAIL: namespace + 'RESET_IMPORTED_POINTS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS',
    SET_CREATE_NOTIFICATION: namespace + 'SET_CREATE_NOTIFICATION',
    SET_IMPORTED_POINTS_EXPORT: namespace + 'SET_IMPORTED_POINTS_EXPORT'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    RESTORE: namespace + 'RESTORE',
    EXPORT: namespace + 'EXPORT',
    IMPORT: namespace + 'IMPORT',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
