<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="eventModel.id"
            label="ID"
            disabled
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="createdAt"
            label="ポイント付与日"
            disabled
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="付与ポイント数"
            disabled
            v-model="eventModel.point"
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="eventModel.name"
            name="event[name]"
            v-validate="'required'"
            data-vv-as="イベント名"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('event[name]')"
            :error-messages="errors.collect('event[name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              イベント名<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="付与理由"
            :disabled="!isEditable"
            v-model="eventModel.reason"
            auto-grow>
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-subheader>ポイント付与されたユーザー</v-subheader>
          <v-card class="pa-4 overflow-y-auto mb-5" style="max-height: 500px">
            <v-col cols="12">
              <ul>
                <li v-for="user of event.users" :key="user.id" class="mb-3">{{ user.attributes.last_name + user.attributes.first_name }}</li>
              </ul>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">更新</v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as EventsTypes } from '../../../store/modules/events/types'

export default {
  name: 'ImportPointsForm',
  components: {},
  props: {
    type: String
  },
  data () {
    return {
      eventModel: {},
      createdAt: '',
      isEditable: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      event: EventsTypes.getters.GET_EVENTS_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetEvent: EventsTypes.actions.SHOW,
      apiUpdateEvent: EventsTypes.actions.UPDATE,
      resetEvent: EventsTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetEvent()
      this.eventModel = this.event
    },
    onSubmit: function () {
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          this.requestUpdate()
        }
      })
    },
    async requestUpdate () {
      await this.apiUpdateEvent(this.eventModel)
      if (this.httpSuccess) this.toggleEditable()
    }
  },
  async created () {
    await this.apiGetEvent(this.$route.params.id)
    this.eventModel = this.event
    this.createdAt = moment(this.event.created_at).format('YYYY/MM/DD')
  }
}
</script>

<style>
ul{
  list-style-type: none
}
</style>
