<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn left class="ma-2" outlined rounded color="indigo" @click="$refs.hiddenInputFileElement.click()"><v-icon>mdi-upload</v-icon>CSV一括登録</v-btn>
      <v-dialog v-model="showImportDialog" width="500">
        <v-card>
          <v-card-title class="headline primary white--text" primary-title>確認</v-card-title>
          <v-card-text class="pt-5 grey--text text--darken-2">CSVをインポートしてポイント付与イベントを登録します。よろしいですか？</v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="toggleImportDialog">キャンセル</v-btn>
            <v-btn class="primary white--text" @click="requestImport">インポートする</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <input
        type="file"
        id="hiddenInputFile"
        accept=".csv"
        ref="hiddenInputFileElement"
        @change="selectedImportFile"
        hidden/>
      <v-btn left class="ma-2" outlined rounded color="indigo" @click="requestExport"><v-icon>mdi-download</v-icon>CSV出力</v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
          </tr>
      </thead>
      <tbody>
        <tr v-for="event in eventsList" @click="onClickTr(event.id)" :key="event.id" class="clickable">
          <td>{{ event.id }}</td>
          <td>{{ event.created_at | moment }}</td>
          <td>{{ event.name | truncate(15) }}</td>
          <td>{{ event.reason | truncate(15) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="eventsListPagenation.pages" :current="eventsListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as EventsTypes } from '../../../store/modules/events/types'

// helpers
import { donwloadByVendor } from '../../helper'

// components
import ListPagenation from '../../molecules/ListPagenation'

export default {
  name: 'ImportPointsListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'ポイント付与日',
          sortable: true,
          value: 'created_at'
        },
        {
          text: 'イベント名',
          sortable: false,
          value: 'name'
        },
        {
          text: '付与理由',
          sortable: false,
          value: 'reason'
        }
      ],
      pagenations: {},
      sortOrder: '',
      currentSortCategory: '',
      importFile: '',
      showImportDialog: false
    }
  },
  computed: {
    ...mapGetters({
      event: EventsTypes.getters.GET_EVENTS_DETAIL,
      eventsList: EventsTypes.getters.GET_EVENTS_LIST,
      eventsListPagenation: EventsTypes.getters.GET_EVENTS_LIST_PAGENATION,
      eventsExport: EventsTypes.getters.GET_EVENTS_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetEvents: EventsTypes.actions.INDEX,
      apiGetEvent: EventsTypes.actions.SHOW,
      apiExportEvents: EventsTypes.actions.EXPORT,
      apiImportEvents: EventsTypes.actions.IMPORT
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showEvent', params: { id: id } })
    },
    toggleImportDialog: function () {
      this.showImportDialog = !this.showImportDialog
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetEvents({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetEvents({
        page: page
      })
    },
    async requestExport () {
      await this.apiExportEvents()
      if (this.httpSuccess) {
        donwloadByVendor(this.eventsExport.data, this.eventsExport.fileName)
      }
    },
    async requestImport () {
      await this.apiImportEvents({
        formDataWithImportFile: this.importFile
      })
      this.toggleImportDialog()
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'showEvent', params: { id: this.event.id } })
      }, 30)
    },
    selectedImportFile: function (e) {
      e.preventDefault()
      this.importFile = e.target.files[0]
      this.toggleImportDialog()
      document.getElementById('hiddenInputFile').value = ''
    }
  },
  async created () {
    await this.apiGetEvents()
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
