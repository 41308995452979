const namespace = 'notificationReaders/'

export const Types = {
  getters: {
  },
  mutations: {
  },
  actions: {
    READ: namespace + 'READ',
    APPROVE: namespace + 'APPROVE'
  }
}
