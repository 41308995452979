<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <SignInForm/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import SignInForm from '../organisms/SignInForm'

export default {
  components: {
    SignInForm
  }
}
</script>
