<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn left class="ma-3" outlined rounded color="indigo" @click="requestExport"><v-icon>mdi-download</v-icon>CSV出力</v-btn>
      <v-btn fab top right small color="primary" class="ma-3" @click="onClickNewNotification"><v-icon>mdi-plus</v-icon></v-btn>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="notification in notificationsList" :key="notification.id" class="clickable" @click="onClickTr(notification.id)">
            <td v-if="notification.published_at">
              <p v-if="notification.status === 0" class="d-flex justify-center my-2">
                <v-chip color="warning" outlined small>
                予約投稿
                </v-chip>
              </p>
              <p class="d-flex justify-center mb-2">
                {{ notification.published_at | moment }}
              </p>
            </td>
            <td v-else class="d-flex justify-center align-center">下書き</td>
            <td>{{ notification.user.attributes.last_name + notification.user.attributes.first_name | truncate(10) }}</td>
            <td>{{ notification.title | truncate(10) }}</td>
            <td>
              <span v-if="notification.groups.length > 1">{{ notification.groups.length }}グループ</span>
              <span v-else-if="notification.groups.length === 1">{{ notification.groups[0].attributes.name }}</span>
            </td>
            <td>
              <span v-if="notification.target"><v-icon>mdi-check</v-icon></span>
            </td>
            <td>{{ notification.commented_count }}</td>
            <td>
              <v-chip v-if="notification.is_required_approval" color="primary" outlined>
                有効
              </v-chip>
              <v-chip v-else color="error" outlined>
                無効
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="notificationsListPagenation.pages" :current="notificationsListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as NotificationsTypes } from '../../../store/modules/notifications/types'

// components
import ListPagenation from '../../molecules/ListPagenation'

// helpers
import { donwloadByVendor } from '../../helper'

export default {
  name: 'NotificationsListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: '公開日時',
          sortable: true,
          value: 'published_at'
        },
        {
          text: '投稿者',
          sortable: true,
          value: 'name'
        },
        {
          text: 'タイトル',
          sortable: false,
          value: 'icon'
        },
        {
          text: '投稿先グループ',
          sortable: false,
          value: 'default_point_number'
        },
        {
          text: '全グループ対象',
          sortable: false,
          value: 'target'
        },
        {
          text: 'コメント数',
          sortable: true,
          value: 'commented_count'
        },
        {
          text: '承認設定',
          sortable: false,
          value: 'is_required_approval'
        }
      ],
      pagenations: {},
      sortOrder: '',
      currentSortCategory: '',
      isDrawable: false
    }
  },
  computed: {
    ...mapGetters({
      notificationsList: NotificationsTypes.getters.GET_NOTIFICATIONS_LIST,
      notificationsListPagenation: NotificationsTypes.getters.GET_NOTIFICATIONS_LIST_PAGENATION,
      notificationsExport: NotificationsTypes.getters.GET_NOTIFICATIONS_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetNotifications: NotificationsTypes.actions.INDEX,
      apiExportNotifications: NotificationsTypes.actions.EXPORT
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showNotification', params: { id: id } })
    },
    onClickNewNotification: function () {
      this.$router.push({ name: 'newNotification' })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetNotifications({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    async requestExport () {
      await this.apiExportNotifications()
      if (this.httpSuccess) {
        donwloadByVendor(this.notificationsExport.data, this.notificationsExport.fileName)
      }
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetNotifications({
        page: page
      })
    }
  },
  async created () {
    await this.apiGetNotifications()
    this.isDrawable = true
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
