const namespace = 'dashboards/'

export const Types = {
  getters: {
    GET_TOTAL_CHART_DATA: namespace + 'GET_TOTAL_CHART_DATA',
    GET_GROUP_CHART_DATA: namespace + 'GET_GROUP_CHART_DATA',
    GET_GROUP_ID: namespace + 'GET_GROUP_ID',
    GET_YEARLY: namespace + 'GET_YEARLY',
    GET_MONTHLY: namespace + 'GET_MONTHLY',
    GET_WEEKLY: namespace + 'GET_WEEKLY',
    GET_DAILY: namespace + 'GET_DAILY',
    GET_QUERY_PARAMS: namespace + 'GET_QUERY_PARAMS',
    GET_TOTAL_EXPORT: namespace + 'GET_TOTAL_EXPORT',
    GET_GROUP_EXPORT: namespace + 'GET_GROUP_EXPORT'
  },
  mutations: {
    SET_TOTAL_CHART_DATA: namespace + 'SET_TOTAL_CHART_DATA',
    SET_GROUP_CHART_DATA: namespace + 'SET_GROUP_CHART_DATA',
    SET_GROUP_ID: namespace + 'SET_GROUP_ID',
    SET_YEARLY: namespace + 'SET_YEARLY',
    SET_MONTHLY: namespace + 'SET_MONTHLY',
    SET_WEEKLY: namespace + 'SET_WEEKLY',
    SET_DAILY: namespace + 'SET_DAILY',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS',
    SET_TOTAL_EXPORT: namespace + 'SET_TOTAL_EXPORT',
    SET_GROUP_EXPORT: namespace + 'SET_GROUP_EXPORT'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    CREATE: namespace + 'CREATE',
    DESTROY: namespace + 'DESTROY',
    REPORT: namespace + 'REPORT',
    EXPORT_TOTAL_DATA: namespace + 'EXPORT_TOTAL_DATA',
    EXPORT_GROUP_DATA: namespace + 'EXPORT_GROUP_DATA'
  }
}
