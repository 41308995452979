const namespace = 'messageReports/'

export const Types = {
  getters: {
    GET_MESSAGE_REPORTS_LIST: namespace + 'GET_MESSAGE_REPORTS_LIST',
    GET_MESSAGE_REPORTS_LIST_PAGENATION: namespace + 'GET_MESSAGE_REPORTS_LIST_PAGENATION',
    GET_MESSAGE_REPORTS_DETAIL: namespace + 'GET_MESSAGE_REPORTS_DETAIL',
    GET_MESSAGE_REPORTS_EXPORT: namespace + 'GET_MESSAGE_REPORTS_EXPORT'
  },
  mutations: {
    SET_MESSAGE_REPORTS_LIST: namespace + 'SET_MESSAGE_REPORTS_LIST',
    SET_MESSAGE_REPORTS_DETAIL: namespace + 'SET_MESSAGE_REPORTS_DETAIL',
    RESET_MESSAGE_REPORTS_DETAIL: namespace + 'RESET_MESSAGE_REPORTS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS',
    SET_MESSAGE_REPORTS_EXPORT: namespace + 'SET_MESSAGE_REPORTS_EXPORT'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    CREATE: namespace + 'CREATE',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    EXPORT: namespace + 'EXPORT',
    RESTORE: namespace + 'RESTORE',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
