<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn fab top right small color="primary" class="ma-3" @click="onClickNewHelp"><v-icon>mdi-plus</v-icon></v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="help in helpsList" :key="help.id" class="clickable" @click="onClickTr(help.id)">
          <td>{{ help.category_name | truncate(20) }}</td>
          <td>{{ help.question | truncate(20) }}</td>
          <td>{{ help.answer | truncate(20) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as HelpsTypes } from '../../../store/modules/helps/types'

// components

// helper

export default {
  name: 'HelpsListTable',
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: 'カテゴリ',
          sortable: false,
          value: 'category'
        },
        {
          text: '質問',
          sortable: false,
          value: 'question'
        },
        {
          text: '回答',
          sortable: false,
          value: 'answer'
        }
      ],
      images: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      helpsList: HelpsTypes.getters.GET_HELPS_LIST,
      helpsListPagenation: HelpsTypes.getters.GET_HELPS_LIST_PAGENATION
    })
  },
  methods: {
    ...mapActions({
      apiGetHelps: HelpsTypes.actions.INDEX
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showHelp', params: { id: id } })
    },
    onClickNewHelp: function () {
      this.$router.push({ name: 'newHelp' })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetCoins({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    }
  },
  async created () {
    await this.apiGetHelps()
  },
  filters: {
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
