<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="お知らせ一覧"/>
      </v-col>
      <v-col cols="12">
        <NotificationsSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <NotificationsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import NotificationsSearchForm from '../../organisms/notifications/NotificationsSearchForm'
import NotificationsListTable from '../../organisms/notifications/NotificationsListTable'

export default {
  components: {
    PageHeading,
    NotificationsSearchForm,
    NotificationsListTable
  }
}
</script>
