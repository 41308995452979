<template>
  <v-breadcrumbs large>
    <v-breadcrumbs-item v-for="item in items()" :key="item.name" :disabled="item.disabled">
      <router-link
        class="font-weight-bold grey--text text--darken-1"
        v-if="item.link && !item.disabled"
        :to="{ name: item.link.name, params: item.link.params }">
        {{ item.name }}
      </router-link>
      <span v-else>
        {{ item.name }}
      </span>
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'BreadCrumb',
  data () {
    return {
      item: []
    }
  },
  methods: {
    items () {
      const elements = {
        home: {
          name: 'ホーム',
          link: {
            name: 'home'
          }
        },
        notifications: {
          index: {
            name: 'お知らせ管理',
            link: {
              name: 'indexNotificaion'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        },
        dashboards: {
          index: {
            name: 'ダッシュボード',
            link: {
              name: 'home'
            }
          }
        },
        users: {
          index: {
            name: 'ユーザー管理',
            link: {
              name: 'indexUser'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        },
        groups: {
          index: {
            name: 'グループ管理',
            link: {
              name: 'indexGroup'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        },
        events: {
          index: {
            name: 'ポイント付与イベント管理',
            link: {
              name: 'indexEvent'
            }
          },
          show: {
            name: '詳細'
          }
        },
        coins: {
          index: {
            name: 'コイン管理',
            link: {
              name: 'indexCoin'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        },
        presents: {
          index: {
            name: 'コイン贈呈履歴管理',
            link: {
              name: 'indexPresent'
            }
          },
          show: {
            name: '詳細'
          }
        },
        incentives: {
          index: {
            name: 'インセンティブ管理',
            link: {
              name: 'indexIncentive'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        },
        incentiveExchanges: {
          index: {
            name: 'インセンティブ交換履歴管理',
            link: {
              name: 'indexIncentiveExchange'
            }
          },
          show: {
            name: '詳細'
          }
        },
        messageReports: {
          index: {
            name: 'メッセージ通報履歴管理',
            link: {
              name: 'indexMessageReport'
            }
          },
          show: {
            name: '詳細'
          }
        },
        notificationCommentReport: {
          index: {
            name: 'コメント通報履歴管理',
            link: {
              name: 'indexNotificationCommentReport'
            }
          },
          show: {
            name: '詳細'
          }
        },
        helps: {
          index: {
            name: 'ヘルプ管理',
            link: {
              name: 'indexHelp'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        },
        helpCategories: {
          index: {
            name: 'ヘルプカテゴリ管理',
            link: {
              name: 'indexHelpCategory'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        },
        roles: {
          index: {
            name: '権限管理',
            link: {
              name: 'indexRole'
            }
          },
          show: {
            name: '詳細'
          }
        },
        departments: {
          index: {
            name: '部署管理',
            link: {
              name: 'indexDepartment'
            }
          },
          new: {
            name: '登録'
          },
          show: {
            name: '詳細'
          }
        }
      }
      switch (this.$route.name) {
        case 'home':
          return [
            Object.assign(elements.home, { disabled: true })
          ]
        case 'indexNotificaion':
          return [
            elements.home,
            Object.assign(elements.notifications.index, { disabled: true })
          ]
        case 'newNotification':
          return [
            elements.home,
            elements.notifications.index,
            Object.assign(elements.notifications.new, { disabled: true })
          ]
        case 'showNotification':
          return [
            elements.home,
            elements.notifications.index,
            Object.assign(elements.notifications.show, { disabled: true })
          ]
        case 'indexDashboard':
          return [
            elements.home,
            Object.assign(elements.dashboards.index, { disabled: true })
          ]
        case 'indexUser':
          return [
            elements.home,
            Object.assign(elements.users.index, { disabled: true })
          ]
        case 'newUser':
          return [
            elements.home,
            elements.users.index,
            Object.assign(elements.users.new, { disabled: true })
          ]
        case 'showUser':
          return [
            elements.home,
            elements.users.index,
            Object.assign(elements.users.show, { disabled: true })
          ]
        case 'indexGroup':
          return [
            elements.home,
            Object.assign(elements.groups.index, { disabled: true })
          ]
        case 'newGroup':
          return [
            elements.home,
            elements.groups.index,
            Object.assign(elements.groups.new, { disabled: true })
          ]
        case 'showGroup':
          return [
            elements.home,
            elements.groups.index,
            Object.assign(elements.groups.show, { disabled: true })
          ]
        case 'indexEvent':
          return [
            elements.home,
            Object.assign(elements.events.index, { disabled: true })
          ]
        case 'showEvent':
          return [
            elements.home,
            elements.events.index,
            Object.assign(elements.events.show, { disabled: true })
          ]
        case 'indexCoin':
          return [
            elements.home,
            Object.assign(elements.coins.index, { disabled: true })
          ]
        case 'newCoin':
          return [
            elements.home,
            elements.coins.index,
            Object.assign(elements.coins.new, { disabled: true })
          ]
        case 'showCoin':
          return [
            elements.home,
            elements.coins.index,
            Object.assign(elements.coins.show, { disabled: true })
          ]
        case 'indexPresent':
          return [
            elements.home,
            Object.assign(elements.presents.index, { disabled: true })
          ]
        case 'showPresent':
          return [
            elements.home,
            elements.presents.index,
            Object.assign(elements.presents.show, { disabled: true })
          ]
        case 'indexIncentive':
          return [
            elements.home,
            Object.assign(elements.incentives.index, { disabled: true })
          ]
        case 'showIncentive':
          return [
            elements.home,
            elements.incentives.index,
            Object.assign(elements.incentives.show, { disabled: true })
          ]
        case 'newIncentive':
          return [
            elements.home,
            elements.incentives.index,
            Object.assign(elements.incentives.new, { disabled: true })
          ]
        case 'indexIncentiveExchange':
          return [
            elements.home,
            Object.assign(elements.incentiveExchanges.index, { disabled: true })
          ]
        case 'showIncentiveExchange':
          return [
            elements.home,
            elements.incentiveExchanges.index,
            Object.assign(elements.incentiveExchanges.show, { disabled: true })
          ]
        case 'indexMessageReport':
          return [
            elements.home,
            Object.assign(elements.messageReports.index, { disabled: true })
          ]
        case 'showMessageReport':
          return [
            elements.home,
            elements.messageReports.index,
            Object.assign(elements.messageReports.show, { disabled: true })
          ]
        case 'indexNotificationCommentReport':
          return [
            elements.home,
            Object.assign(elements.notificationCommentReport.index, { disabled: true })
          ]
        case 'showNotificationCommentReport':
          return [
            elements.home,
            elements.notificationCommentReport.index,
            Object.assign(elements.notificationCommentReport.show, { disabled: true })
          ]
        case 'indexHelp':
          return [
            elements.home,
            Object.assign(elements.helps.index, { disabled: true })
          ]
        case 'showHelp':
          return [
            elements.home,
            elements.helps.index,
            Object.assign(elements.helps.show, { disabled: true })
          ]
        case 'newHelp':
          return [
            elements.home,
            elements.helps.index,
            Object.assign(elements.helps.new, { disabled: true })
          ]
        case 'indexHelpCategory':
          return [
            elements.home,
            Object.assign(elements.helpCategories.index, { disabled: true })
          ]
        case 'showHelpCategory':
          return [
            elements.home,
            elements.helpCategories.index,
            Object.assign(elements.helpCategories.show, { disabled: true })
          ]
        case 'newHelpCategory':
          return [
            elements.home,
            elements.helpCategories.index,
            Object.assign(elements.helpCategories.new, { disabled: true })
          ]
        case 'indexRole':
          return [
            elements.home,
            elements.roles.index
          ]
        case 'showRole':
          return [
            elements.home,
            elements.roles.index,
            Object.assign(elements.roles.show, { disabled: true })
          ]
        case 'newDepartment':
          return [
            elements.home,
            elements.departments.index,
            Object.assign(elements.departments.new, { disabled: true })
          ]
        case 'indexDepartment':
          return [
            elements.home,
            elements.departments.index
          ]
        case 'showDepartment':
          return [
            elements.home,
            elements.departments.index,
            Object.assign(elements.departments.show, { disabled: true })
          ]
      }
    }
  }
}
</script>

<style scoped>
.v-breadcrumbs {
  border-bottom: 1px solid #dee2e6;
}
.v-breadcrumbs a {
  text-decoration: none;
}
.v-breadcrumbs li + li::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/";
}
</style>
