<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="インセンティブ交換履歴詳細"/>
      </v-col>
      <v-col cols="12">
        <IncentiveExchangesForm type="edit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import IncentiveExchangesForm from '../../organisms/incentiveExchanges/IncentiveExchangesForm'

export default {
  components: {
    PageHeading,
    IncentiveExchangesForm
  }
}
</script>
