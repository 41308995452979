import Vue from 'vue'
import Vuex from 'vuex'
import { Types } from './types'
import auth from './modules/auth'
import { Types as AuthTypes } from './modules/auth/types'
import admin from './modules/admin'
import dashboards from './modules/dashboards'
import notifications from './modules/notifications'
import notificationComments from './modules/notificationComments'
import notificationReaders from './modules/notificationReaders'
import groups from './modules/groups'
import users from './modules/users'
import usersSelect from './modules/usersSelect'
import importedPoints from './modules/importedPoints'
import events from './modules/events'
import coins from './modules/coins'
import presents from './modules/presents'
import incentives from './modules/incentives'
import incentiveExchanges from './modules/incentiveExchanges'
import messageReports from './modules/messageReports'
import notificationCommentsReports from './modules/notificationCommentsReports'
import helps from './modules/helps'
import helpCategories from './modules/helpCategories'
import roles from './modules/roles'
import departments from './modules/departments'
import vueCookie from 'vue-cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shouldDisplayProgress: false,
    httpSuccess: false,
    httpError: false
  },
  getters: {
    [Types.getters.SHOULD_DISPLAY_PROGRESS]: state => state.shouldDisplayProgress,
    [Types.getters.GET_HTTP_SUCCESS]: state => state.httpSuccess,
    [Types.getters.GET_HTTP_ERROR]: state => state.httpError
  },
  mutations: {
    [Types.mutations.SET_PROGRESS] (state, payload) {
      state.shouldDisplayProgress = payload
    },
    [Types.mutations.SET_HTTP_SUCCESS] (state, payload) {
      state.httpSuccess = payload
    },
    [Types.mutations.SET_HTTP_ERROR] (state, payload) {
      state.httpError = payload
    },
    [Types.mutations.RESET_ALL_STATUS] (state, payload) {
      vueCookie.delete('session')
    }
  },
  actions: {
    [Types.actions.CLEAR_HTTP_STATUS] ({ commit }) {
      commit(Types.mutations.SET_HTTP_SUCCESS, false)
      commit(Types.mutations.SET_HTTP_ERROR, false)
    },
    [Types.actions.REQUEST] ({ commit }) {
      commit(Types.mutations.SET_PROGRESS, true)
      commit(Types.mutations.SET_HTTP_SUCCESS, false)
      commit(Types.mutations.SET_HTTP_ERROR, false)
    },
    [Types.actions.RESPONSE] ({ commit }) {
      commit(Types.mutations.SET_PROGRESS, false)
    },
    async [Types.actions.RESET_STATUS] ({ commit }, payload) {
      commit(AuthTypes.mutations.SIGNED_IN, false)
      commit(Types.mutations.RESET_ALL_STATUS)
    }
  },
  modules: {
    auth,
    admin,
    dashboards,
    notifications,
    notificationComments,
    notificationReaders,
    groups,
    users,
    usersSelect,
    importedPoints,
    events,
    coins,
    presents,
    incentives,
    incentiveExchanges,
    messageReports,
    notificationCommentsReports,
    helps,
    helpCategories,
    departments,
    roles
  }
})
