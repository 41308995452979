<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn left class="ma-3" outlined rounded color="indigo" @click="requestExport"><v-icon>mdi-download</v-icon>CSV出力</v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
          </tr>
      </thead>
      <tbody>
        <tr v-for="report in messageReportsList" @click="onClickTr(report.id)" :key="report.id" class="clickable">
          <td>{{ report.id }}</td>
          <td>{{ report.created_at | moment }}</td>
          <td>
            <template v-if="report.user === null">
              [ このユーザーは削除されました ]
            </template>
            <template v-else>
              {{ report.user.attributes.last_name + report.user.attributes.first_name | truncate(15) }}
            </template>
          </td>
          <td>
            <template v-if="report.message_attributes === null">
              [ このメッセージは削除されました ]
            </template>
            <template v-else-if="report.message_attachments.length > 0" >
              [ 添付ファイルに対する通報 ]
            </template>
            <template v-else-if="report.message_attributes.stamp" >
              [ スタンプに対する通報 ]
            </template>
            <template v-else>
              {{ report.message_attributes.content | truncate(15) }}
            </template>
          </td>
          <td>{{ report.support_content | truncate(15) }}</td>
          <td>
            <v-chip v-if="report.status === 0" color="error" outlined>
              未対応
            </v-chip>
            <v-chip v-else-if="report.status === 1" color="warning" outlined>
              対応中
            </v-chip>
            <v-chip v-else color="primary" outlined>
              対応完了
            </v-chip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="messageReportsListPagenation.pages" :current="messageReportsListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as MessageReportsTypes } from '../../../store/modules/messageReports/types'

// helpers
import { donwloadByVendor } from '../../helper'

// components
import ListPagenation from '../../molecules/ListPagenation'

export default {
  name: 'MessageReportsListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: 'ID',
          sortable: true,
          value: 'id'
        },
        {
          text: '通報日時',
          sortable: true,
          value: 'created_at'
        },
        {
          text: '通報者',
          sortable: true,
          value: 'user_id'
        },
        {
          text: '通報内容',
          sortable: false,
          value: 'content'
        },
        {
          text: '対応内容',
          sortable: false,
          value: 'support_content'
        },
        {
          text: 'ステータス',
          sortable: false,
          value: 'status'
        }
      ],
      status_attributes: [
        { text: '未対応', value: 0 },
        { text: '対応中', value: 1 },
        { text: '対応完了', value: 2 }
      ],
      pagenations: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      messageReportsList: MessageReportsTypes.getters.GET_MESSAGE_REPORTS_LIST,
      messageReportsListPagenation: MessageReportsTypes.getters.GET_MESSAGE_REPORTS_LIST_PAGENATION,
      messageReportsExport: MessageReportsTypes.getters.GET_MESSAGE_REPORTS_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetMessageReports: MessageReportsTypes.actions.INDEX,
      apiExportMessageReports: MessageReportsTypes.actions.EXPORT
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showMessageReport', params: { id: id } })
    },
    returnStatus: function (value) {
      return this.status_attributes.filter(option => option.value === value)[0].text
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetMessageReports({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetMessageReports({
        page: page
      })
    },
    async requestExport () {
      await this.apiExportMessageReports()
      if (this.httpSuccess) {
        donwloadByVendor(this.messageReportsExport.data, this.messageReportsExport.fileName)
      }
    }
  },
  async created () {
    await this.apiGetMessageReports()
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD hh:mm') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
