<template>
  <v-snackbar
    v-model="toast.active"
    :color="toast.color"
    :timeout="timeout"
    top
    right>
    <v-icon v-if="toast.color === 'success'" class="ma-0" color="white">mdi-check-bold</v-icon>
    <v-icon v-else class="ma-0" color="white">mdi-shield-alert</v-icon>
    {{ toast.message }}
    <v-icon color="white" @click="toast.active = false">mdi-close</v-icon>
  </v-snackbar>
</template>

<script>

export default {
  name: 'ToastMessage',
  props: {
    toast: Object
  },
  data () {
    return {
      timeout: 5000
    }
  }
}
</script>
