import http from '../../../packs/http'
import {
  removeNamespace,
  serializeObjectToQueryParameter,
  getFileNameFromContentDisposition
} from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'

const API_PATH = 'admin/notifications/'
const _types = removeNamespace('notifications/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null,
    groups: [],
    user: []
  },
  defaultDetail: {
    groups: [],
    user: []
  },
  pagenation: {},
  queryParams: {},
  export: {
    data: null,
    fileName: null
  }
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_NOTIFICATIONS_LIST]: state => state.list,
  [_types.getters.GET_NOTIFICATIONS_LIST_PAGENATION]: state => state.pagenation,
  [_types.getters.GET_NOTIFICATIONS_DETAIL]: state => state.detail,
  [_types.getters.GET_NOTIFICATIONS_EXPORT]: state => state.export
}

const mutations = {
  [_types.mutations.SET_NOTIFICATIONS_LIST] (state, payload) {
    state.list = Object.assign([], _initialState.list, payload.list)
    state.pagenation = Object.assign({}, _initialState.list, payload.pagenation)
  },
  [_types.mutations.SET_NOTIFICATIONS_DETAIL] (state, payload) {
    state.detail = Object.assign({}, _initialState.detail, payload)
    state.defaultDetail = Object.assign({}, _initialState.defaultDetail, payload)
  },
  [_types.mutations.SET_NOTIFICATIONS_DETAIL_GROUPS] (state, payload) {
    state.detail.groups = Object.assign([], _initialState.detail.groups, payload)
    state.defaultDetail.groups = Object.assign([], _initialState.defaultDetail.groups, payload)
  },
  [_types.mutations.SET_NOTIFICATIONS_DETAIL_USER] (state, payload) {
    state.detail.user = Object.assign([], _initialState.detail.user, payload)
    state.defaultDetail.user = Object.assign([], _initialState.defaultDetail.user, payload)
  },
  [_types.mutations.RESET_NOTIFICATIONS_DETAIL] (state) {
    state.detail = Object.assign({}, _initialState.detail, state.defaultDetail)
  },
  [_types.mutations.SET_QUERY_PARAMS] (state, payload) {
    state.queryParams = payload
  },
  [_types.mutations.SET_NOTIFICATIONS_EXPORT] (state, payload) {
    state.export.data = payload.data
    state.export.fileName = payload.fileName
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    var queryParams = {}
    if (payload) {
      // full取得する場合には前回のクエリパラメータを引き継がない
      queryParams = payload.full ? payload : Object.assign({}, state.queryParams, payload)
    }
    commit(_types.mutations.SET_QUERY_PARAMS, queryParams)
    await http.get(API_PATH + serializeObjectToQueryParameter(queryParams)).then(response => {
      commit(_types.mutations.SET_NOTIFICATIONS_LIST, {
        list: response.data.data.map(
          notification => Object.assign(
            notification.attributes,
            {
              groups: response.data.included
                .filter(resource => resource.type === 'group' && notification.relationships.groups.data.map(group => group.id).includes(resource.id)),
              user: response.data.included
                .filter(resource => resource.type === 'user' && notification.relationships.user.data.id === resource.id)[0]
            }
          )
        ),
        pagenation: response.data.pagenation
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.SHOW] ({ commit }, id) {
    commit(_types.mutations.SET_NOTIFICATIONS_DETAIL, {})
    await http.get(API_PATH + id).then(response => {
      commit(_types.mutations.SET_NOTIFICATIONS_DETAIL, response.data.data.attributes)
      commit(
        _types.mutations.SET_NOTIFICATIONS_DETAIL_GROUPS,
        response.data.included
          .filter((resource) => { return resource.type === 'group' })
          .map(resource => { return resource.attributes })
      )
      commit(
        _types.mutations.SET_NOTIFICATIONS_DETAIL_USER,
        response.data.included
          .filter(resource => resource.type === 'user')[0]
      )
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.CREATE] ({ commit }, payload) {
    const params = JSON.stringify({
      notification: payload
    })
    await http.post(API_PATH, params).then(response => {
      commit(_types.mutations.SET_NOTIFICATIONS_DETAIL, response.data.data.attributes)
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'お知らせを作成しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.UPDATE] ({ commit }, payload) {
    const params = JSON.stringify({
      notification: payload
    })
    await http.put(API_PATH + payload.id, params).then(response => {
      commit(_types.mutations.SET_NOTIFICATIONS_DETAIL, response.data.data.attributes)
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'お知らせを更新しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
      commit(_types.mutations.RESET_NOTIFICATIONS_DETAIL)
    })
  },
  async [_types.actions.DESTROY] ({ commit }, id) {
    await http.delete(API_PATH + id).then(response => {
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'お知らせを削除しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  [_types.actions.RESET_DETAIL] ({ commit }) {
    commit(_types.mutations.RESET_NOTIFICATIONS_DETAIL)
  },
  async [_types.actions.NOTIFY_NOT_READERS] ({ commit }, id) {
    await http.post(API_PATH + id + '/not_readers').then(response => {
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'お知らせの未読者へ通知を送りました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.EXPORT] ({ commit }, payload) {
    await http.get(API_PATH + 'export' + serializeObjectToQueryParameter(state.queryParams), {
      responseType: 'blob'
    }).then(response => {
      commit(_types.mutations.SET_NOTIFICATIONS_EXPORT, {
        data: response.data,
        fileName: getFileNameFromContentDisposition(response.headers['content-disposition'])
      })
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'お知らせ(CSV)をエクスポートしました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
