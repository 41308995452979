<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="グループ一覧"/>
      </v-col>
      <v-col cols="12">
        <GroupsSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <GroupsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import GroupsSearchForm from '../../organisms/groups/GroupsSearchForm'
import GroupsListTable from '../../organisms/groups/GroupsListTable'

export default {
  components: {
    PageHeading,
    GroupsSearchForm,
    GroupsListTable
  }
}
</script>
