<template>
  <v-card class="pa-4">
    <label class="font-weight-bold" border="bottom">ユーザー検索</label>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="姓"
            v-model="params.last_name">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            label="名"
            v-model="params.first_name">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            label="電話番号"
            v-model="params.phone_number">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="departmentsOptions"
            label="所属部署"
            v-model="selectedDepartment">
          </v-select>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-btn class="ma-3" color="indigo" type="submit" outlined rounded><v-icon>mdi-magnify</v-icon>検索</v-btn>
      </v-row>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as DepartmentsTypes } from '../../../store/modules/departments/types'
import { Types as UsersTypes } from '../../../store/modules/users/types'

export default {
  name: 'UsersSearchForm',
  components: {},
  data () {
    return {
      params: {
        // 明示的に検索時にはページ1が指定されるように
        page: 1,
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        department_id: ''
      },
      departmentsOptions: [],
      selectedRole: '',
      selectedDepartment: ''
    }
  },
  computed: {
    ...mapGetters({
      departments: DepartmentsTypes.getters.GET_DEPARTMENTS_LIST
    })
  },
  methods: {
    ...mapActions({
      apiGetDepartmentsList: DepartmentsTypes.actions.INDEX,
      apiGetUsers: UsersTypes.actions.INDEX
    }),
    onSubmit: function () {
      this.params.department_id = this.selectedDepartment
      this.apiGetUsers(this.params)
    },
    setDepartmentsOptions: function () {
      this.departmentsOptions = this.departments.map(department => {
        return {
          text: department.name,
          value: department.id
        }
      })
      this.departmentsOptions.unshift({
        text: '指定なし',
        value: ''
      })
    }
  },
  async created () {
    await this.apiGetDepartmentsList()
    if (this.departments.length) this.setDepartmentsOptions()
  }
}
</script>
