<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ヘルプカテゴリ新規登録"/>
      </v-col>
      <v-col cols="12">
        <HelpCategoriesForm type="new"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import HelpCategoriesForm from '../../organisms/helpCategories/HelpCategoriesForm'

export default {
  components: {
    PageHeading,
    HelpCategoriesForm
  }
}
</script>
