<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="権限一覧"/>
      </v-col>
      <v-col cols="12">
        <RolesListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import RolesListTable from '../../organisms/roles/RolesListTable'

export default {
  components: {
    PageHeading,
    RolesListTable
  }
}
</script>
