<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="インセンティブ交換履歴一覧"/>
      </v-col>
      <v-col cols="12">
        <IncentiveExchangesSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <IncentiveExchangesListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import IncentiveExchangesSearchForm from '../../organisms/incentiveExchanges/IncentiveExchangesSearchForm'
import IncentiveExchangesListTable from '../../organisms/incentiveExchanges/IncentiveExchangesListTable'

export default {
  components: {
    PageHeading,
    IncentiveExchangesSearchForm,
    IncentiveExchangesListTable
  }
}
</script>
