<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <!-- v-btn left class="ma-3" outlined rounded color="indigo"><v-icon>mdi-upload</v-icon>CSV登録</v-btn -->
      <v-btn left class="ma-3" outlined rounded color="indigo" @click="requestExport"><v-icon>mdi-download</v-icon>CSV出力</v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in groupsList" @click="onClickTr(group.id)" :key="group.id" class="clickable">
          <td>{{ group.name | truncate(15) }}</td>
          <td>{{ group.owner.attributes.last_name +  group.owner.attributes.first_name | truncate(10) }}</td>
          <td>{{ group.created_at | moment }}</td>
          <td v-if="isDrawable">
            <template v-for="(user, index) in group.users">
              <span v-if="index <= 1" :key="index">
                {{ user.attributes.last_name +  user.attributes.first_name | truncate(6) }}&emsp;
              </span>
            </template>
            <v-tooltip v-if="group.users.length >= 3" top>
              <template v-slot:activator="{ on }">
                <v-chip class="ml-3" v-on="on">
                  その他{{ group.users.length - 2 }}名
                </v-chip>
              </template>
              <template v-for="(user, index) in group.users">
                <p  v-if="index >= 2" :key="index" class="my-2">
                  <span>
                    {{ user.attributes.last_name +  user.attributes.first_name }}
                  </span>
                </p>
              </template>
            </v-tooltip>
          </td>
          <td>{{ group.users.length }} 名</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="groupsListPagenation.pages" :current="groupsListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as GroupsTypes } from '../../../store/modules/groups/types'

// helpers
import { donwloadByVendor } from '../../helper'

// components
import ListPagenation from '../../molecules/ListPagenation'

export default {
  name: 'GroupsListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isDrawable: true,
      isAsc: false,
      headers: [
        {
          text: 'グループ名',
          sortable: false,
          value: 'name'
        },
        {
          text: 'オーナー',
          sortable: false,
          value: 'owner_id'
        },
        {
          text: '作成日',
          sortable: true,
          value: 'created_at'
        },
        {
          text: 'ユーザー',
          sortable: false,
          value: 'users'
        },
        {
          text: 'ユーザー数',
          sortable: true,
          value: 'group_users_count'
        }
      ],
      pagenations: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      groupsList: GroupsTypes.getters.GET_GROUPS_LIST,
      groupsListPagenation: GroupsTypes.getters.GET_GROUPS_LIST_PAGENATION,
      groupsExport: GroupsTypes.getters.GET_GROUPS_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetGroups: GroupsTypes.actions.INDEX,
      apiExportGroups: GroupsTypes.actions.EXPORT
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showGroup', params: { id: id } })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetGroups({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetGroups({
        page: page,
        sort: this.currentSortCategory ? this.currentSortCategory + ' ' + this.sortOrder : ''
      })
    },
    async requestExport () {
      await this.apiExportGroups()
      if (this.httpSuccess) {
        donwloadByVendor(this.groupsExport.data, this.groupsExport.fileName)
      }
    }
  },
  async created () {
    await this.apiGetGroups()
    this.pagenations = this.groupsListPagenation
    this.isDrawable = true
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('YYYY/MM/DD') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
