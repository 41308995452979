<template>
  <v-card class="pa-4">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.text">
              <v-row v-if="header.sortable">
                <v-col cols="6" class="d-flex justify-center align-center">
                  {{ header.text }}
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-row>
                    <v-col cols="12" class="pa-0 d-flex align-end">
                      <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex align-start">
                      <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  {{ header.text }}
                </v-col>
              </v-row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="role in rolesList" :key="role.id" class="clickable" @click="onClickTr(role.id)">
            <td>{{ role.name | truncate(8) }}</td>
            <td>
              <template v-if="role.is_admin == true">◎</template>
              <template v-else>ー</template>
            </td>
            <template v-if="role.group_creatable_number">
              <td>{{ role.group_creatable_number }}</td>
              <td>{{ role.group_members_min_number }}</td>
            </template>
            <template v-else-if="role.group_creatable_number == 0">
              <td>無制限</td>
              <td>{{ role.group_members_min_number }}</td>
            </template>
            <template v-else>
              <td>ー</td>
              <td>ー</td>
            </template>
            <td>{{ role.coin_sendable_per_month_number }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as RolesTypes } from '../../../store/modules/roles/types'

export default {
  name: 'RolesListTable',
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: '権限名',
          sortable: false,
          value: 'name'
        },
        {
          text: '管理者権限',
          sortable: false,
          value: 'is_admin'
        },
        {
          text: 'グループ作成可能数',
          sortable: false,
          value: 'group_creatable_number'
        },
        {
          text: 'グループ作成時の最少人数',
          sortable: false,
          value: 'group_members_min_number'
        },
        {
          text: '月々の贈呈可能コイン数',
          sortable: false,
          value: 'coin_sendable_per_month_number'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      rolesList: RolesTypes.getters.GET_ROLES_LIST,
    })
  },
  methods: {
    ...mapActions({
      apiGetRoles: RolesTypes.actions.INDEX
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showRole', params: { id: id } })
    }
  },
  async created () {
    await this.apiGetRoles()
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
