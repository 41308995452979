<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ポイント付与イベント一覧"/>
      </v-col>
      <v-col cols="12">
        <EventsSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <EventsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import EventsSearchForm from '../../organisms/events/EventsSearchForm'
import EventsListTable from '../../organisms/events/EventsListTable'

export default {
  components: {
    PageHeading,
    EventsSearchForm,
    EventsListTable
  }
}
</script>
