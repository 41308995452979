import http from '../../../packs/http'
import { removeNamespace } from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'
import { Types as AdminTypes } from '../admin/types'

const API_PATH = 'auth/'
const _types = removeNamespace('auth/', Types)

const state = {
  hasInitialized: false,
  hasSignedIn: false
}

const getters = {
  [_types.getters.HAS_INITIALIZED]: state => state.hasInitialized,
  [_types.getters.HAS_SIGNED_IN]: state => state.hasSignedIn
}

const mutations = {
  [_types.mutations.INITIALIZED] (state) {
    state.hasInitialized = true
  },
  [_types.mutations.SIGNED_IN] (state, payload) {
    state.hasSignedIn = payload
  }
}

const actions = {
  async [_types.actions.VALIDATE] ({ commit }) {
    await http.get(API_PATH + 'validate_token').then(response => {
      commit(_types.mutations.SIGNED_IN, true)
      commit(AdminTypes.mutations.SET_ADMIN,
        Object.assign(
          response.data.data.attributes,
          {
            department: response.data.included
              .filter(resource => resource.type === 'department' && resource.id === response.data.data.relationships.department.data.id)[0],
            role: response.data.included
              .filter(resource => resource.type === 'role' && resource.id === response.data.data.relationships.role.data.id)[0],
            isAdmin: response.data.included
              .filter(resource => resource.type === 'role' && resource.id === response.data.data.relationships.role.data.id)[0].attributes.is_admin
          }
        )
        , { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    }).then(() => {
      commit(_types.mutations.INITIALIZED)
    })
  },
  async [_types.actions.SIGN_IN] ({ commit }, payload) {
    await http.post(API_PATH + 'sign_in', payload).then(response => {
      commit(_types.mutations.SIGNED_IN, true)
      commit(AdminTypes.mutations.SET_ADMIN,
        Object.assign(
          response.data.data.attributes,
          {
            department: response.data.included
              .filter(resource => resource.type === 'department' && resource.id === response.data.data.relationships.department.data.id)[0],
            role: response.data.included
              .filter(resource => resource.type === 'role' && resource.id === response.data.data.relationships.role.data.id)[0],
            isAdmin: response.data.included
              .filter(resource => resource.type === 'role' && resource.id === response.data.data.relationships.role.data.id)[0].attributes.is_admin
          }
        )
        , { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.SIGN_OUT] ({ commit }, payload) {
    await http.delete(API_PATH + 'sign_out').then(response => {
      commit(_types.mutations.SIGNED_IN, false)
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
