<template>
<div class="pa-0 ma-0">
  <v-navigation-drawer
    clipped
    :mobile-break-point="960"
    app
    color="#5976AB"
    dark
    v-model="drawer"
    v-if="this.hasSignedIn">
    <v-list-item to="/" two-line class="list-item__logo">
      <v-list-item-content>
        <v-list-item-title class="d-flex justify-center align-center">
          <img src="../../../assets/images/header_logo.png" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list nav>
      <v-list-item link to="/notifications">
        <v-list-item-action>
          <v-icon>mdi-newspaper-variant-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>お知らせ管理</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/dashboards">
        <v-list-item-action>
          <v-icon>mdi-billboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>ダッシュボード</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-if="admin.isAdmin"
        prepend-icon="mdi-account-multiple"
        no-action>
        <template v-slot:activator>
          <v-list-item-title>ユーザー管理</v-list-item-title>
        </template>
        <v-list-item link to="/users">
          <v-list-item-content>
            <v-list-item-title>ユーザー一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/groups">
          <v-list-item-content>
            <v-list-item-title>グループ一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/events">
          <v-list-item-content>
            <v-list-item-title>ポイント付与イベント一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        v-if="admin.isAdmin"
        prepend-icon="mdi-coins"
        no-action>
        <template v-slot:activator>
          <v-list-item-title>コイン管理</v-list-item-title>
        </template>
        <v-list-item link to="/coins">
          <v-list-item-content>
            <v-list-item-title>コイン一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/presents">
          <v-list-item-content>
            <v-list-item-title>コイン贈呈履歴一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        v-if="admin.isAdmin"
        prepend-icon="mdi-gift"
        no-action>
        <template v-slot:activator>
          <v-list-item-title>インセンティブ管理</v-list-item-title>
        </template>
        <v-list-item link to="/incentives">
          <v-list-item-content>
            <v-list-item-title>インセンティブ一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/incentive_exchanges">
          <v-list-item-content>
            <v-list-item-title>インセンティブ交換履歴一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        v-if="admin.isAdmin"
        prepend-icon="mdi-help-circle"
        no-action>
        <template v-slot:activator>
          <v-list-item-title>ヘルプ管理</v-list-item-title>
        </template>
        <v-list-item link to="/help_categories">
          <v-list-item-content>
            <v-list-item-title>ヘルプカテゴリ一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/helps">
          <v-list-item-content>
            <v-list-item-title>ヘルプ一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        v-if="admin.isAdmin"
        prepend-icon="mdi-alert-octagon"
        no-action>
        <template v-slot:activator>
          <v-list-item-title>通報管理</v-list-item-title>
        </template>
        <v-list-item link to="/message_reports">
          <v-list-item-content>
            <v-list-item-title>メッセージ通報一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/notification_comment_reports">
          <v-list-item-content>
            <v-list-item-title>コメント通報一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        v-if="admin.isAdmin"
        prepend-icon="mdi-settings"
        no-action>
        <template v-slot:activator>
          <v-list-item-title>システム管理</v-list-item-title>
        </template>
        <v-list-item link to="/roles">
          <v-list-item-content>
            <v-list-item-title>権限一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/departments">
          <v-list-item-content>
            <v-list-item-title>部署一覧</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
  <v-app-bar dense dark color="#4F5D98">
    <v-progress-circular
      v-if="displayProgress"
      indeterminate
      color="white"
    ></v-progress-circular>
    <v-app-bar-nav-icon class="d-md-none" @click="drawer =! drawer"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-menu v-if="signIn" offset-y>
      <template v-slot:activator="{ on }">
        <a v-on="on">
          <v-icon>mdi-account</v-icon>
          {{ admin.last_name + admin.first_name }}
          <v-icon>mdi-menu-down</v-icon>
        </a>
      </template>
      <v-list>
        <v-list-item @click="onClickSignOut">
          <v-list-item-title>ログアウト</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</div>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as AuthTypes } from '../../store/modules/auth/types'

export default {
  name: 'HeaderSideNav',
  props: {
    signIn: Boolean,
    displayProgress: Boolean,
    admin: Object
  },
  data () {
    return {
      drawer: true,
      isSignIn: false
    }
  },
  computed: {
    ...mapGetters({
      hasSignedIn: AuthTypes.getters.HAS_SIGNED_IN
    })
  },
  methods: {
    ...mapActions({
      signOut: AuthTypes.actions.SIGN_OUT
    }),
    async onClickSignOut () {
      await this.signOut()
      if (!this.hasSignedIn) {
        this.$router.push({ name: 'signIn' })
      }
    }
  },
  created () {
    if (this.$vuetify.breakpoint.sm) {
      this.drawer = false
    }
  }
}
</script>

<style scoped>
.v-application .primary--text,
.v-application a,
.v-navigation-drawer__content .v-list-item__title,
.v-navigation-drawer__content .v-list-item__subtitle {
  color: #FFF !important;
}
a.list-item__logo {
  color: transparent !important;
}
a.list-item__logo img{
  width: 100%;
  height: auto;
}
.v-list-item__title {
  white-space: pre-wrap !important;
}
</style>
