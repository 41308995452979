<template>
  <v-card class="pa-4">
    <v-row>
      <v-col cols="12" class="d-flex flex-row-reverse">
        <v-btn right class="ma-3" :dark="this.daily" :outlined="!this.daily" rounded color="indigo" @click="setParams('day')">日</v-btn>
        <v-btn right class="ma-3" :dark="this.weekly" :outlined="!this.weekly" dark rounded color="indigo" @click="setParams('week')">週</v-btn>
        <v-btn right class="ma-3" :dark="this.monthly" :outlined="!this.monthly" rounded color="indigo" @click="setParams('month')">月</v-btn>
        <v-btn right class="ma-3" :dark="this.yearly" :outlined="!this.yearly" rounded color="indigo" @click="setParams('year')">年</v-btn>
      </v-col>
      <v-col cols="12">
        <v-subheader>
          <span class="subtitle-1 font-weight-bold">全体</span>&ensp;
          ({{ fromDay }} ~ {{ toDay }})
          <v-btn left class="ma-3" outlined rounded color="indigo" @click="requestTotalExport" small><v-icon>mdi-download</v-icon>CSV出力</v-btn>
        </v-subheader>
        <v-row>
          <v-col
            cols="12"
            class="d-flex body-2 text-center justify-space-between">
            <v-card class="pa-4">
              <div class="mb-0">ユーザー数<p class="subtitle-1 font-weight-bold">{{ totalChartData.users_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">グループ数<p class="subtitle-1 font-weight-bold">{{ totalChartData.groups_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">アクティブユーザー数<p class="subtitle-1 font-weight-bold">{{ totalChartData.active_users_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">会話送信数<p class="subtitle-1 font-weight-bold">{{ totalChartData.messages_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">拍手回数<p class="subtitle-1 font-weight-bold">{{ totalChartData.total_claps_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">コイン贈呈回数<p class="subtitle-1 font-weight-bold">{{ totalChartData.presents_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">お知らせ送信回数<p class="subtitle-1 font-weight-bold">{{ totalChartData.notifications_count }}</p></div>
            </v-card>
            <v-card class="pa-4 group-notifications-card">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="mb-1 group-notifications-help" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
                </template>
                <p class="my-2">
                  通知許可率：グループの通知を許可にしているユーザーの割合
                </p>
              </v-tooltip>
              <div class="mb-0">通知許可率<p class="subtitle-1 font-weight-bold">{{ totalChartData.group_notifications_allow_rate }}</p></div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="mb-1" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
          </template>
          <p class="my-2">
            アクティブ率：<br />メッセージの投稿・拍手・既読、お知らせの投稿・既読、<br />おしらせコメントの投稿・拍手、プレゼント贈呈の<br />いずれかのアクションを行ったユーザーの割合
          </p>
        </v-tooltip>
        <v-card class="pa-4">
          <ActiveUsersAndGroupsChart
            v-if="showActiveUsersAndGroupsChart"
            :chart-data="activeUsersAndGroupsChartData"
            :options="activeUsersAndGroupsChartData.options"
            :height="200" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="mb-1" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
          </template>
          <p class="my-2">
            会話関与率：ユーザーがどのぐらいメッセージを投稿したかを表す指標
          </p>
          <p class="my-2">
            拍手関与率：ユーザーがどのぐらい拍手したかを表す指標
          </p>
          <p class="my-2">
            コイン贈呈関与率：ユーザーがどのぐらいコインを贈呈したかを表す指標
          </p>
        </v-tooltip>
        <v-card class="pa-4">
          <EngagementRateChart
            v-if="showEngagementRateChart"
            :chart-data="engagementRateChartData"
            :options="engagementRateChartData.options"
            :height="200" />
        </v-card>
      </v-col>
      <v-col class="mt-7" cols="6" sm="3">
        <v-card class="pa-4">
          <ActiveUsersRateChart
            v-if="showActiveUsersRateChart"
            :chart-data="activeUsersRateChartData"
            :options="activeUsersRateChartData.options" />
        </v-card>
      </v-col>
      <v-col class="mt-7" cols="6" sm="3">
        <v-card class="pa-4">
          <PresentsChart
            v-if="showPresentsChart"
            :chart-data="presentsChartData"
            :options="presentsChartData.options" />
        </v-card>
      </v-col>
      <v-col class="mt-7" cols="6" sm="3">
        <v-card class="pa-4">
          <MessagesAndClapsChart
            v-if="showMessagesAndClapsChart"
            :chart-data="messagesAndClapsChartData"
            :options="messagesAndClapsChartData.options" />
        </v-card>
      </v-col>
      <v-col cols="6" sm="3">
        <div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon class="mb-1" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
          </template>
          <p class="my-2">
            既読率：お知らせに対して、既読になっている割合
          </p>
        </v-tooltip>
        </div>
        <v-card class="pa-4">
          <NotificationsAndReadersRateChart
            v-if="showNotificationsAndReadersRateChart"
            :chart-data="notificationsAndReadersRateChartData"
            :options="notificationsAndReadersRateChartData.options" />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col cols="12">
        <v-subheader>
          <span class="subtitle-1 font-weight-bold">グループ</span>&ensp;
          ({{ fromDay }} ~ {{ toDay }})
          <v-btn v-if="groupId" left class="ma-3" outlined rounded color="indigo" @click="requestGroupExport" small><v-icon>mdi-download</v-icon>CSV出力</v-btn>
        </v-subheader>
        <v-col cols="8">
          <v-combobox
            v-model="selectedGroup"
            label="グループ"
            @click="toggleGroupsSelectDialog">
          </v-combobox>
        </v-col>
        <v-dialog v-model="showGroupsSelectDialog" width="400">
          <v-card class="group-dialog">
            <v-card-title class="headline primary white--text" primary-title>グループ選択</v-card-title>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" class="d-flex justify-center pt-0">
                <v-card-actions>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th v-for="header in headers" :key="header.text">
                          {{ header.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="group in groupsList" :key="group.id" class="clickable" @click="setGroupData(group.id, group.name)">
                        <td>{{ group.name }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-actions>
              </v-col>
              <v-col cols="12" class="d-flex justify-end pr-5 pb-5 pt-0">
                <div>
                  <ListPagenation :pages="groupsListPagenation.pages" :current="groupsListPagenation.current" :changePageCallback="changePage"/>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center pb-5 pt-0">
                <v-btn color="primary" type="button" @click="toggleGroupsSelectDialog">閉じる</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <v-row v-if="showGroupsChart">
          <v-col
            cols="12"
            class="d-flex body-2 text-center justify-space-between">
            <v-card class="pa-4">
              <div class="mb-0">ユーザー数<p class="subtitle-1 font-weight-bold">{{ groupChartData.users_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">グループ数<p class="subtitle-1 font-weight-bold">ー</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">アクティブユーザー数<p class="subtitle-1 font-weight-bold">{{ groupChartData.active_users_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">会話送信数<p class="subtitle-1 font-weight-bold">{{ groupChartData.messages_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">会話拍手回数<p class="subtitle-1 font-weight-bold">{{ groupChartData.total_claps_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">コイン贈呈回数<p class="subtitle-1 font-weight-bold">{{ groupChartData.presents_count }}</p></div>
            </v-card>
            <v-card class="pa-4">
              <div class="mb-0">お知らせ送信回数<p class="subtitle-1 font-weight-bold">{{ groupChartData.notifications_count }}</p></div>
            </v-card>
            <v-card class="pa-4 group-notifications-card">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon class="mb-1 group-notifications-help" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
                </template>
                <p class="my-2">
                  通知許可率：グループの通知を許可にしているユーザーの割合
                </p>
              </v-tooltip>
              <div class="mb-0">通知許可率<p class="subtitle-1 font-weight-bold">{{ groupChartData.group_notifications_allow_rate }}</p></div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <template v-if="showGroupsChart">
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon class="mb-1" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
            </template>
            <p class="my-2">
              アクティブ率：<br />メッセージの投稿・拍手・既読、お知らせの投稿・既読、<br />おしらせコメントの投稿・拍手、プレゼント贈呈の<br />いずれかのアクションを行ったユーザーの割合
            </p>
          </v-tooltip>
          <v-card class="pa-4">
            <ActiveUsersChartAboutGroups
              :chart-data="activeUsersChartAboutGroupsData"
              :options="activeUsersChartAboutGroupsData.options"
              :height="200" />
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon class="mb-1" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
            </template>
            <p class="my-2">
              会話関与率：ユーザーがどのぐらいメッセージを投稿したかを表す
            </p>
            <p class="my-2">
              拍手関与率：ユーザーがどのぐらい拍手したかを表す
            </p>
            <p class="my-2">
              コイン贈呈関与率：ユーザーがどのぐらいコインを贈呈したかを表す
            </p>
          </v-tooltip>
          <v-card class="pa-4">
            <EngagementRateChartAboutGroups
              :chart-data="engagementRateChartAboutGroupsData"
              :options="engagementRateChartAboutGroupsData.options"
              :height="200" />
          </v-card>
        </v-col>
        <v-col class="mt-7" cols="6" sm="3">
        <v-card class="pa-4">
          <ActiveUsersRateChartAboutGroups
            :chart-data="activeUsersRateChartAboutGroupsData"
            :options="activeUsersRateChartAboutGroupsData.options" />
        </v-card>
        </v-col>
        <v-col class="mt-7" cols="6" sm="3">
          <v-card class="pa-4">
            <PresentsChartAboutGroups
              :chart-data="presentsChartAboutGroupsData"
              :options="presentsChartAboutGroupsData.options" />
          </v-card>
        </v-col>
        <v-col class="mt-7" cols="6" sm="3">
          <v-card class="pa-4">
            <MessagesAndClapsChartAboutGroups
              :chart-data="messagesAndClapsChartAboutGroupsData"
              :options="messagesAndClapsChartAboutGroupsData.options" />
          </v-card>
        </v-col>
        <v-col cols="6" sm="3">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon class="mb-1" v-on="on" color="grey lighten-1">mdi-help-circle</v-icon>
            </template>
            <p class="my-2">
              既読率：お知らせに対して、既読になっている割合
            </p>
          </v-tooltip>
          <v-card class="pa-4">
            <NotificationsAndReadersRateChartAboutGroups
              :chart-data="notificationsAndReadersRateChartAboutGroupsData"
              :options="notificationsAndReadersRateChartAboutGroupsData.options" />
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as DashboardsTypes } from '../../../store/modules/dashboards/types'
import { Types as GroupsTypes } from '../../../store/modules/groups/types'
import { Types as GlobalTypes } from '../../../store/types'

// helpers
import { donwloadByVendor } from '../../helper'

// components
import ActiveUsersAndGroupsChart from '../../organisms/dashboards/ActiveUsersAndGroupsChart'
import EngagementRateChart from '../../organisms/dashboards/EngagementRateChart'
import ActiveUsersRateChart from '../../organisms/dashboards/ActiveUsersRateChart'
import PresentsChart from '../../organisms/dashboards/PresentsChart'
import MessagesAndClapsChart from '../../organisms/dashboards/MessagesAndClapsChart'
import NotificationsAndReadersRateChart from '../../organisms/dashboards/NotificationsAndReadersRateChart'
import ActiveUsersChartAboutGroups from '../../organisms/dashboards/ActiveUsersChartAboutGroups'
import EngagementRateChartAboutGroups from '../../organisms/dashboards/EngagementRateChartAboutGroups'
import ActiveUsersRateChartAboutGroups from '../../organisms/dashboards/ActiveUsersRateChartAboutGroups'
import PresentsChartAboutGroups from '../../organisms/dashboards/PresentsChartAboutGroups'
import MessagesAndClapsChartAboutGroups from '../../organisms/dashboards/MessagesAndClapsChartAboutGroups'
import NotificationsAndReadersRateChartAboutGroups from '../../organisms/dashboards/NotificationsAndReadersRateChartAboutGroups'
import ListPagenation from '../../molecules/ListPagenation'

export default {
  name: 'Dashboards',
  components: {
    ActiveUsersAndGroupsChart,
    EngagementRateChart,
    ActiveUsersRateChart,
    PresentsChart,
    MessagesAndClapsChart,
    NotificationsAndReadersRateChart,
    ActiveUsersChartAboutGroups,
    EngagementRateChartAboutGroups,
    ActiveUsersRateChartAboutGroups,
    PresentsChartAboutGroups,
    MessagesAndClapsChartAboutGroups,
    NotificationsAndReadersRateChartAboutGroups,
    ListPagenation
  },
  data () {
    return {
      totalData: {},
      groupData: {},
      pagenations: {},
      totalOptions: { responsive: true, maintainAspectRatio: false },
      params: {
        // 明示的に検索時にはページ1が指定されるように
        page: 1,
        from_day: '',
        to_day: ''
      },
      headers: [
        {
          text: 'グループ名',
          value: 'name'
        }
      ],
      activeUsersAndGroupsChartData: {},
      activeUsersAndGroupsChartDataLabels: [],
      engagementRateChartData: {},
      engagementRateChartDataLabels: [],
      activeUsersRateChartData: {},
      activeUsersRateChartDataLabels: [],
      presentsChartData: {},
      presentsChartDataLabels: [],
      messagesAndClapsChartData: {},
      messagesAndClapsChartDataLabels: [],
      notificationsAndReadersRateChartData: {},
      notificationsAndReadersRateChartDataLabels: [],
      activeUsersChartAboutGroupsData: {},
      activeUsersChartAboutGroupsDataLabels: [],
      engagementRateChartAboutGroupsData: {},
      engagementRateChartAboutGroupsDataLabels: [],
      activeUsersRateChartAboutGroupsData: {},
      activeUsersRateChartAboutGroupsDataLabels: [],
      presentsChartAboutGroupsData: {},
      presentsChartAboutGroupsDataLabels: [],
      messagesAndClapsChartAboutGroupsData: {},
      messagesAndClapsChartAboutGroupsDataLabels: [],
      notificationsAndReadersRateChartAboutGroupsData: {},
      notificationsAndReadersRateChartAboutGroupsDataLabels: [],
      selectedGroup: '',
      fromDay: '',
      toDay: '',
      showGroupsSelectDialog: false,
      showActiveUsersAndGroupsChart: false,
      showEngagementRateChart: false,
      showActiveUsersRateChart: false,
      showPresentsChart: false,
      showMessagesAndClapsChart: false,
      showNotificationsAndReadersRateChart: false,
      showGroupsChart: false,
      showActiveGroupChart: false,
      showMonthDialog: false
    }
  },
  computed: {
    ...mapGetters({
      totalChartData: DashboardsTypes.getters.GET_TOTAL_CHART_DATA,
      groupChartData: DashboardsTypes.getters.GET_GROUP_CHART_DATA,
      queryParams: DashboardsTypes.getters.GET_QUERY_PARAMS,
      groupId: DashboardsTypes.getters.GET_GROUP_ID,
      yearly: DashboardsTypes.getters.GET_YEARLY,
      monthly: DashboardsTypes.getters.GET_MONTHLY,
      weekly: DashboardsTypes.getters.GET_WEEKLY,
      daily: DashboardsTypes.getters.GET_DAILY,
      groupsList: GroupsTypes.getters.GET_GROUPS_LIST,
      groupsListPagenation: GroupsTypes.getters.GET_GROUPS_LIST_PAGENATION,
      totalExport: DashboardsTypes.getters.GET_TOTAL_EXPORT,
      groupExport: DashboardsTypes.getters.GET_GROUP_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetDashboards: DashboardsTypes.actions.INDEX,
      apiGetGroups: GroupsTypes.actions.INDEX,
      apiExportTotalData: DashboardsTypes.actions.EXPORT_TOTAL_DATA,
      apiExportGroupData: DashboardsTypes.actions.EXPORT_GROUP_DATA
    }),
    toggleGroupsSelectDialog () {
      this.showGroupsSelectDialog = !this.showGroupsSelectDialog
    },
    toggleMonthDialog () {
      this.showMonthDialog = !this.showMonthDialogw
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetGroups({
        page: page
      })
    },
    async requestTotalExport () {
      const params = {
        from_day: this.queryParams.from_day ? this.queryParams.from_day : '',
        to_day: this.queryParams.to_day ? this.queryParams.to_day : '',
      }
      this.queryParams.group_id = ''
      await this.apiExportTotalData(params)
      if (this.httpSuccess) {
        donwloadByVendor(this.totalExport.data, this.totalExport.fileName)
      }
    },
    async requestGroupExport () {
      const params = {
        from_day: this.queryParams.from_day ? this.queryParams.from_day : '',
        to_day: this.queryParams.to_day ? this.queryParams.to_day : '',
      }
      this.queryParams.group_id = this.groupId
      await this.apiExportGroupData(params)
      if (this.httpSuccess) {
        donwloadByVendor(this.groupExport.data, this.groupExport.fileName)
      }
    },
    async setParams (period) {
      switch (period) {
        case 'year':
          this.params = {
            // 日付の絞り込みがされていない場合は、6年前〜前日までを期間として設定
            from_day: this.queryParams.search_from_day ? this.queryParams.search_from_day : moment(new Date()).subtract(6, 'y').startOf('y').format('YYYY/MM/DD'),
            to_day: this.queryParams.search_to_day ? this.queryParams.search_to_day : moment(new Date()).subtract(1, 'd').format('YYYY/MM/DD'),
            yearly: true
          }
          break
        case 'month':
          this.params = {
            // 日付の絞り込みがされていない場合は、6ヶ月前の月初日〜前日までを期間として設定
            from_day: this.queryParams.search_from_day ? this.queryParams.search_from_day : moment(new Date()).subtract(6, 'M').startOf('M').format('YYYY/MM/DD'),
            to_day: this.queryParams.search_to_day ? this.queryParams.search_to_day : moment(new Date()).subtract(1, 'd').format('YYYY/MM/DD'),
            monthly: true
          }
          break
        case 'week':
          this.params = {
            // 日付の絞り込みがされていない場合は、前月の月初日~前日までを期間として設定
            from_day: this.queryParams.search_from_day ? this.queryParams.search_from_day : moment(new Date()).subtract(1, 'M').startOf('M').format('YYYY/MM/DD'),
            to_day: this.queryParams.search_to_day ? this.queryParams.search_to_day : moment(new Date()).subtract(1, 'd').format('YYYY/MM/DD'),
            weekly: true
          }
          break
        case 'day':
          this.params = {
            // 日付の絞り込みがされていない場合は、過去1週間を期間として設定
            from_day: this.queryParams.search_from_day ? this.queryParams.search_from_day : moment(new Date()).subtract(7, 'd').format('YYYY/MM/DD'),
            to_day: this.queryParams.search_to_day ? this.queryParams.search_to_day : moment(new Date()).subtract(1, 'd').format('YYYY/MM/DD'),
            daily: true
          }
          break
        default:
          break
      }
      this.params.search_from_day = this.queryParams.search_from_day ? this.queryParams.search_from_day : ''
      this.params.search_to_day = this.queryParams.search_to_day ? this.queryParams.search_to_day : ''
      await this.apiGetDashboards(this.params)
      if(this.groupId != null) {
        this.params.group_id = this.groupId
        await this.apiGetDashboards(this.params)
      }
    },
    async setGroupData (id, name) {
      this.selectedGroup = name
      const params = {
        from_day: this.queryParams.from_day ? this.queryParams.from_day : moment(new Date()).subtract(7, 'd').format('YYYY/MM/DD'),
        to_day: this.queryParams.to_day ? this.queryParams.to_day : moment(new Date()).subtract(1, 'd').format('YYYY/MM/DD'),
        group_id: id,
        yearly: this.queryParams.yearly ? true : false,
        monthly: this.queryParams.monthly ? true : false,
        weekly: this.queryParams.weekly ? true : false,
        daily: this.queryParams.daily ? true : false,
      }
      await this.apiGetDashboards(params)
      await this.toggleGroupsSelectDialog()
      this.showGroupsChart = true
    },
    async setActiveUsersAndGroupsData () {
      this.showActiveUsersAndGroupsChart = true
    },
    async setEngagementRateChartData () {
      this.showEngagementRateChart = true
    },
    async setActiveUsersRateChartData () {
      this.showActiveUsersRateChart = true
    },
    async setPresentsChartData () {
      this.showPresentsChart = true
    },
    async setMessagesAndClapsChartData () {
      this.showMessagesAndClapsChart = true
    },
    async setNotificationsAndReadersRateChartData () {
      this.showNotificationsAndReadersRateChart = true
    }
  },
  async created () {
    await this.apiGetGroups()
    await this.apiGetDashboards()
    // 7日前
    this.fromDay = moment(new Date()).subtract(7, 'd').format('YYYY/MM/DD')
    // 前日
    this.toDay = moment(new Date()).subtract(1, 'd').format('YYYY/MM/DD')
    await this.setActiveUsersAndGroupsData()
    await this.setEngagementRateChartData()
    await this.setActiveUsersRateChartData()
    await this.setPresentsChartData()
    await this.setMessagesAndClapsChartData()
    await this.setNotificationsAndReadersRateChartData()
  },
  watch: {
    queryParams () {
      this.fromDay = this.queryParams.search_from_day ? this.queryParams.search_from_day : this.queryParams.from_day
      this.toDay = this.queryParams.search_to_day ? this.queryParams.search_to_day : this.queryParams.to_day
    },
    totalChartData () {
      if (this.queryParams.yearly === true) {
        this.chartLabels = Object.keys(this.totalChartData.chart.groups_count).map(function(date) {
          return moment(date).format('YYYY年')
        });
      } else if (this.queryParams.monthly === true) {
        this.chartLabels = Object.keys(this.totalChartData.chart.groups_count).map(function(date) {
          return moment(date).format('YYYY年M月')
        });
      } else if (this.queryParams.weekly === true) {
        this.chartLabels = Object.keys(this.totalChartData.chart.groups_count).map(function(date) {
          // 1日足さないと、表示が1日ずれてしまうため加算
          return moment(date).add('days', 1).format('M月D日の週')
        });
      } else {
        this.chartLabels = Object.keys(this.totalChartData.chart.groups_count).map(function(date) {
          return moment(date).format('M月D日')
        });
      }
      this.activeUsersAndGroupsChartData = {
        labels: this.chartLabels,
        datasets: [{
          type: 'line',
          label: 'アクティブ率',
          borderColor: '#ffd700',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.totalChartData.chart.active_users_rate)
        },{
          type: 'bar',
          label: 'グループ数',
          backgroundColor: '#4169e1',
          data: Object.values(this.totalChartData.chart.groups_count),
        },{
          type: 'bar',
          label: 'アクティブユーザー数',
          backgroundColor: '#ff0000',
          data: Object.values(this.totalChartData.chart.active_users_count)
        }],
        options: {
          title: {
            display: true,
            text: 'アクティブユーザーとグループ'
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.engagementRateChartData = {
        labels: this.chartLabels,
        datasets: [{
          label: '会話関与率数',
          borderColor: '#ff0000',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.totalChartData.chart.messages_commitment_rate)
        },{
          label: '拍手関与率',
          borderColor: '#4169e1',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.totalChartData.chart.claps_commitment_rate)
        },{
          label: 'コイン贈呈関与率',
          borderColor: '#ffd700',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.totalChartData.chart.presents_commitment_rate),
        }],
        options: {
          title: {
            display: true,
            text: '関与率'
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.activeUsersRateChartData = {
        labels: this.chartLabels,
        datasets: [{
          backgroundColor: '#ff0000',
          data: Object.values(this.totalChartData.chart.active_users_rate)
        }],
        options: {
          title: {
            display: true,
            text: 'アクティブ率',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.presentsChartData = {
        labels: this.chartLabels,
        datasets: [{
          backgroundColor: '#ff0000',
          data: Object.values(this.totalChartData.chart.presents_count)
        }],
        options: {
          title: {
            display: true,
            text: 'コイン贈呈回数',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.messagesAndClapsChartData = {
        labels: this.chartLabels,
        datasets: [{
          label: '会話送信数',
          backgroundColor: '#ff0000',
          data: Object.values(this.totalChartData.chart.messages_count)
        },{
          label: '拍手回数',
          backgroundColor: '#4169e1',
          data: Object.values(this.totalChartData.chart.total_claps_count)
        }],
        options: {
          title: {
            display: true,
            text: '会話と拍手',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.notificationsAndReadersRateChartData = {
        labels: this.chartLabels,
        datasets: [{
          type: 'line',
          label: '既読率',
          borderColor: '#ffd700',
          backgroundColor: 'rgba(0,0,0,0)',
          data: Object.values(this.totalChartData.chart.notifications_readers_rate)
        },{
          type: 'bar',
          label: 'お知らせ送信回数',
          backgroundColor: '#ff0000',
          data: Object.values(this.totalChartData.chart.notifications_count)
        }],
        options: {
          title: {
            display: true,
            text: 'お知らせ送信回数と既読率',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      }
    },
    groupChartData () {
      this.activeUsersChartAboutGroupsData = {
        labels: this.chartLabels,
        datasets: [{
          type: 'line',
          label: 'アクティブ率',
          borderColor: '#ffd700',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.groupChartData.chart.active_users_rate)
        },{
          type: 'line',
          label: 'アクティブ率(全社平均)',
          borderColor: '#ffd700',
          backgroundColor: "rgba(0,0,0,0)",
          borderDash: [5, 2],
          data: Object.values(this.totalChartData.chart.active_users_rate)
        },{
          type: 'bar',
          label: 'アクティブユーザー数',
          backgroundColor: '#ff0000',
          data: Object.values(this.groupChartData.chart.active_users_count)
        }],
        options: {
          title: {
            display: true,
            text: 'アクティブユーザーとアクティブ率'
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.engagementRateChartAboutGroupsData = {
        labels: this.chartLabels,
        datasets: [{
          label: '会話関与率数',
          borderColor: '#ff0000',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.groupChartData.chart.messages_commitment_rate)
        },{
          label: '会話関与率数(全社平均)',
          borderColor: '#ff0000',
          backgroundColor: "rgba(0,0,0,0)",
          borderDash: [4, 2],
          data: Object.values(this.totalChartData.chart.messages_commitment_rate)
        },{
          label: '会話拍手関与率',
          borderColor: '#4169e1',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.groupChartData.chart.claps_commitment_rate)
        },{
          label: '拍手関与率(全社平均)',
          borderColor: '#4169e1',
          backgroundColor: "rgba(0,0,0,0)",
          borderDash: [4, 2],
          data: Object.values(this.totalChartData.chart.claps_commitment_rate)
        },{
          label: 'コイン贈呈関与率',
          borderColor: '#ffd700',
          backgroundColor: "rgba(0,0,0,0)",
          data: Object.values(this.groupChartData.chart.presents_commitment_rate),
        },{
          label: 'コイン贈呈関与率(全社平均)',
          borderColor: '#ffd700',
          backgroundColor: "rgba(0,0,0,0)",
          borderDash: [4, 2],
          data: Object.values(this.totalChartData.chart.presents_commitment_rate),
        }],
        options: {
          title: {
            display: true,
            text: '関与率'
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.activeUsersRateChartAboutGroupsData = {
        labels: this.chartLabels,
        datasets: [{
          backgroundColor: "#ff0000",
          data: Object.values(this.groupChartData.chart.active_users_rate)
        }],
        options: {
          title: {
            display: true,
            text: 'アクティブ率',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.presentsChartAboutGroupsData = {
        labels: this.chartLabels,
        datasets: [{
          backgroundColor: "#ff0000",
          data: Object.values(this.groupChartData.chart.presents_count)
        }],
        options: {
          title: {
            display: true,
            text: 'コイン贈呈回数',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.messagesAndClapsChartAboutGroupsData = {
        labels: this.chartLabels,
        datasets: [{
          label: '会話送信数',
          backgroundColor: "#ff0000",
          data: Object.values(this.groupChartData.chart.messages_count)
        },{
          label: '会話拍手回数',
          backgroundColor: "#4169e1",
          data: Object.values(this.groupChartData.chart.total_claps_count)
        }],
        options: {
          title: {
            display: true,
            text: '会話と会話拍手',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      },
      this.notificationsAndReadersRateChartAboutGroupsData = {
        labels: this.chartLabels,
        datasets: [{
          type: 'line',
          label: '既読率',
          borderColor: '#ffd700',
          backgroundColor: 'rgba(0,0,0,0)',
          data: Object.values(this.groupChartData.chart.notifications_readers_rate)
        },{
          type: 'bar',
          label: 'お知らせ送信回数',
          backgroundColor: '#ff0000',
          data: Object.values(this.groupChartData.chart.notifications_count)
        }],
        options: {
          title: {
            display: true,
            text: 'お知らせ送信回数と既読率',
          },
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0
              },
              gridLines: {
                display: false
              }
            }]
          }
        }
      }
    }
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('YYYY/MM/DD') : ''
    }
  }
}
</script>

<style>
.group-notifications-card{
  position: relative;
}
.group-notifications-help{
  position: absolute;
  top: -28px;
  left: 0;
}
</style>
