<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="コイン詳細"/>
      </v-col>
      <v-col cols="12">
        <CoinsForm type="edit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import CoinsForm from '../../organisms/coins/CoinsForm'

export default {
  components: {
    PageHeading,
    CoinsForm
  }
}
</script>
