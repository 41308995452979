<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row class="d-flex justify-start">
        <v-col cols="12" class="avatar-area ml-5">
          <p class="text-left grey--text">インセンティブ画像<span class="ml-2 red--text text--accent-2">必須</span></p>
          <input
            type="file"
            class="d-none"
            accept="image/jpeg,image/png,image/gif"
            ref="fileInput"
            :disabled="!isEditable"
            @change="onImageChange">
          <v-hover v-slot:default="{ hover }" :disabled="!isEditable">
            <v-card
              :elevation="hover ? 24 : 8"
              :class="'d-inline-block mx-auto' + { ' on-hover': hover }">
              <v-avatar
                size="164"
                :class="isEditable ? 'clickable' : ''">
                <img
                  id="preview_img"
                  :src="imageSrc"
                  @click="focusFileInput">
              </v-avatar>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="incentiveModel.name"
            name="incentive[name]"
            v-validate="'required'"
            data-vv-as="インセンティブ名"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('incentive[name]')"
            :error-messages="errors.collect('incentive[name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              インセンティブ名<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="incentiveModel.description"
            name="incentive[description]"
            label="説明"
            auto-grow
            :disabled="!isEditable">
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="incentiveModel.point"
            name="incentive[point]"
            v-validate="'required|numeric|min_value:1'"
            data-vv-as="必要ポイント"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('incentive[point]')"
            :error-messages="errors.collect('incentive[point]')"
            :disabled="!isEditable">
            <template v-slot:label>
              必要ポイント<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="incentiveModel.left_stock"
            name="incentive[left_stock]"
            v-validate="'required|numeric|min_value:1'"
            data-vv-as="残数"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('incentive[left_stock]')"
            :error-messages="errors.collect('incentive[left_stock]')"
            :disabled="!isEditable">
            <template v-slot:label>
              残数<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="incentiveModel.prepared_stock"
            name="incentive[prepared_stock]"
            v-validate="'required|numeric|min_value:1'"
            data-vv-as="準備数"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('incentive[prepared_stock]')"
            :error-messages="errors.collect('incentive[prepared_stock]')"
            :disabled="!isEditable">
            <template v-slot:label>
              準備数<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="incentiveModel.exchangeable_per_user"
            label="一人あたりの交換可能数"
            name="incentive[exchangeable_per_user]"
            v-validate="'numeric'"
            data-vv-as="一人あたりの交換可能数"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('incentive[exchangeable_per_user]')"
            :error-messages="errors.collect('incentive[exchangeable_per_user]')"
            :disabled="!isEditable">
          </v-text-field>
        </v-col>
      </v-row>
      <v-subheader>交換開始日時</v-subheader>
      <v-row>
        <v-col cols="12" sm="6" class="pb-0">
          <v-menu
            v-model="date_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="exchangeStartDate"
                label="日付"
                prepend-icon="mdi-calendar-month"
                readonly
                :disabled="!isEditable"
                @click:clear="exchangeStartDate = null"
                :clearable="type === 'new'"
                v-on="on">
              </v-text-field>
            </template>
            <v-date-picker
              v-model="exchangeStartDate"
              @input="date_menu = false"
              locale="ja-jp"
              :allowed-dates="allowedDate"
              :day-format="date => new Date(date).getDate()">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" class="pb-0">
          <v-menu
            ref="menu"
            v-model="time_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="exchangeStartTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="exchangeStartTime"
                label="時間"
                prepend-icon="mdi-clock-outline"
                readonly
                :disabled="!isEditable"
                v-on="on"
                @click:clear="exchangeStartTime = null"
                :clearable="type === 'new'">
              </v-text-field>
            </template>
            <v-time-picker
              v-if="time_menu"
              v-model="exchangeStartTime"
              format="24hr"
              :allowed-minutes="allowedFiveMinutesStep"
              @click:minute="$refs.menu.save(exchangeStartTime)">
            </v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-subheader>「交換開始日時」を入力しない場合、現在の時間が設定され、ユーザーがすぐに交換できる状態となります</v-subheader>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">
          {{ this.type === 'new' ? '新規登録' : '更新' }}
        </v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'
import loadImage from 'blueimp-load-image'
import objectFitImages from 'object-fit-images'
import moment from 'moment'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as IncentivesTypes } from '../../../store/modules/incentives/types'

// components
import DestroyDialog from '../../molecules/DestroyDialog'

// helper
import { privateMediaApi } from '../../helper'

// mixins
import { ImageCompressible } from '../../mixins/ImageCompressible'

export default {
  name: 'IncentivesForm',
  mixins: [ ImageCompressible ],
  components: {
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのインセンティブを削除してもよろしいですか？'
      },
      incentiveModel: {},
      imageSrc: '',
      exchangeStartDate: '',
      exchangeStartTime: '',
      date_menu: false,
      time_menu: false,
      isEditable: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      incentive: IncentivesTypes.getters.GET_INCENTIVES_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetIncentive: IncentivesTypes.actions.SHOW,
      apiCreateIncentive: IncentivesTypes.actions.CREATE,
      apiUpdateIncentive: IncentivesTypes.actions.UPDATE,
      apiDestroyIncentive: IncentivesTypes.actions.DESTROY,
      apiRestoreIncentive: IncentivesTypes.actions.RESTORE,
      resetIncentive: IncentivesTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetIncentive()
      this.incentiveModel = this.incentive
      this.getImageSrc(this.incentive.image)
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    allowedDate: function (val) {
      let today = new Date()
      today = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      return today <= new Date(val)
    },
    onSubmit: function () {
      this.incentiveModel.start_exchange_at = this.exchangeStartDate + ' ' + this.exchangeStartTime
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          switch (this.type) {
            case 'new':
              this.requestCreate()
              break
            case 'edit':
              this.requestUpdate()
              break
          }
        }
      })
    },
    allowedFiveMinutesStep (m) {
      return m % 5 === 0
    },
    async requestDestroy () {
      await this.apiDestroyIncentive(this.incentive.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexIncentive' })
      }, 30)
    },
    async requestCreate () {
      await this.apiCreateIncentive(this.incentiveModel)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'showIncentive', params: { id: this.incentive.id } })
      }, 30)
    },
    async requestUpdate () {
      await this.apiUpdateIncentive(this.incentiveModel)
      setTimeout(() => {
        if (this.httpSuccess) this.toggleEditable()
      }, 30)
    },
    onImageChange (e) {
      const agent = window.navigator.userAgent.toLowerCase()
      const ie11 = (agent.indexOf('trident/7') !== -1)
      const previewImg = document.getElementById('preview_img')
      const images = e.target.files || e.dataTransfer.files
      if (!images) {
        return
      }
      this.getBase64(images[0]).then(image => {
        this.incentiveModel.image = image
      })
      loadImage.parseMetaData(images[0], (data) => {
        const options = {
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation')
        }
        loadImage(images[0], (canvas) => {
          const data = canvas.toDataURL(images[0].type)
          const blob = this.base64ToBlob(data, images[0].type)
          const originUrl = window.URL.createObjectURL(blob)
          const newimg = new Image()
          newimg.src = originUrl
          newimg.onload = () => {
            const resizedCanvas = this.createResizedCanvasElement(newimg)
            const resizedBase64 = resizedCanvas.toDataURL(images[0].type)
            const resizedBlob = this.base64ToBlob(resizedBase64, images[0].type)
            const url = window.URL.createObjectURL(resizedBlob)
            this.imageSrc = url
            this.incentiveModel.icon = resizedBase64
          }
          // IE11はimgタグのbackground-imageも更新
          if (ie11) previewImg.style.backgroundImage = 'url(' + url + ')'
        }, options)
      })
    },
    focusFileInput: function () {
      this.$refs.fileInput.click()
    },
    getImageSrc: function (url) {
      if (!url) {
        this.imageSrc = require('../../../../assets/images/no_image.png')
        return
      }
      privateMediaApi.getPrivateMedia(url, (imageData) => {
        this.imageSrc = window.URL.createObjectURL(imageData)
      })
    }
  },
  async created () {
    await objectFitImages()
    if (this.$route.params.id) {
      await this.apiGetIncentive(this.$route.params.id)
      this.incentiveModel = this.incentive
      await this.getImageSrc(this.incentive.image)
      this.exchangeStartDate = this.incentiveModel.start_exchange_at ? moment(this.incentiveModel.start_exchange_at).format('YYYY-MM-DD') : ''
      this.exchangeStartTime = this.incentiveModel.start_exchange_at ? moment(this.incentiveModel.start_exchange_at).format('HH:mm') : ''
    } else {
      this.isEditable = !this.isEditable
      this.imageSrc = require('../../../../assets/images/no_image.png')
    }
  }
}
</script>

<style scoped>
.avatar-area .v-card{
  overflow: hidden;
}
.avatar-area .v-card .v-avatar img{
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
</style>
