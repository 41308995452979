<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="インセンティブ新規登録"/>
      </v-col>
      <v-col cols="12">
        <IncentivesForm type="new"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import IncentivesForm from '../../organisms/incentives/IncentivesForm'

export default {
  components: {
    PageHeading,
    IncentivesForm
  }
}
</script>
