import http from '../../../packs/http'
import { removeNamespace, serializeObjectToQueryParameter } from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'

const API_PATH = 'admin/users/'
const _types = removeNamespace('users/', Types)

const _initialState = {
  list: [],
  pagenation: {},
  queryParams: {}
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_USERS_LIST]: state => state.list,
  [_types.getters.GET_USERS_LIST_PAGENATION]: state => state.pagenation,
}

const mutations = {
  [_types.mutations.SET_USERS_LIST] (state, payload) {
    state.list = Object.assign([], _initialState.list, payload.list)
    state.pagenation = Object.assign({}, _initialState.list, payload.pagenation)
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    var queryParams = {}
    if (payload) {
      // full取得する場合には前回のクエリパラメータを引き継がない
      queryParams = payload.full ? payload : Object.assign({}, state.queryParams, payload)
    }
    commit(_types.mutations.SET_QUERY_PARAMS, queryParams)
    await http.get(API_PATH + serializeObjectToQueryParameter(queryParams)).then(response => {
      commit(_types.mutations.SET_USERS_LIST, {
        list: response.data.data.map(object => object.attributes),
        pagenation: response.data.pagenation
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
