<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start absolute">
        <transition-group name="slide-fade">
          <h2 v-if="isEditable" key="edit" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
          <v-btn v-else-if="!isEditable && currentAdmin.isAdmin" key="dialog" color="success" class="ma-3" @click="togglePasswordDialog">パスワード変更</v-btn>
        </transition-group>
        <v-dialog v-model="showPasswordDialog" max-width="600px">
          <v-card>
            <form novalidate @submit.prevent="onChangePassword">
              <v-card-title class="headline green white--text">パスワード変更</v-card-title>
              <v-card-text>
                <v-container>
                    <v-row>
                      <v-col cols="12" v-if="user.id === currentAdmin.id">
                        <v-text-field
                          label="現在のパスワード"
                          v-model="newPasswordModel.current_password"
                          type="password"
                          name="user[current_password]"
                          v-validate="'required|min:6'"
                          data-vv-as="現在のパスワード"
                          :customValidation="isEditable && validated"
                          :isValid="!errors.has('user[current_password]')"
                          :error-messages="errors.collect('user[current_password]')">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="新しいパスワード"
                          v-model="newPasswordModel.password"
                          type="password"
                          name="user[new_password]"
                          ref="new_password"
                          v-validate="'required|min:6'"
                          data-vv-as="新しいパスワード"
                          :customValidation="isEditable && validated"
                          :isValid="!errors.has('user[new_password]')"
                          :error-messages="errors.collect('user[new_password]')">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="新しいパスワード(確認用)"
                          v-model="newPasswordModel.password_confirmation"
                          type="password"
                          name="user[password_confirmation]"
                          v-validate="'required|min:6|confirmed:new_password'"
                          data-vv-as="新しいパスワード(確認用)"
                          :customValidation="isEditable && validated"
                          :isValid="!errors.has('user[password_confirmation]')"
                          :error-messages="errors.collect('user[password_confirmation]')">
                        </v-text-field>
                      </v-col>
                    </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="togglePasswordDialog">キャンセル</v-btn>
                <v-btn type="submit" color="success" class="white--text">変更する</v-btn>
              </v-card-actions>
            </form>
          </v-card>
        </v-dialog>
      </v-col>
      <v-dialog v-model="changePasswordDialog" max-width="600">
        <v-card>
          <template>
            <v-card-title class="headline error white--text" primary-title>確認</v-card-title>
            <v-card-text class="pa-5 grey--text text--darken-2 subtitle-1">
              パスワードを変更すると元に戻せなくなります。<br>
              変更してよろしいですか？
            </v-card-text>
          </template>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="toggleChangePasswordDialog">キャンセル</v-btn>
            <v-btn class="error white--text" @click="changePassword">変更する</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn
          v-if="currentAdmin.id !== userModel.id && user.role_id !== 1"
          color="red"
          class="ma-2 ma-sm-3"
          @click="toggleDestroyDialog"
          fab
          top
          right
          small>
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row class="d-flex justify-start">
        <v-col cols="12" class="avatar-area ml-5">
          <p class="text-left grey--text">アイコン画像</p>
          <input
            type="file"
            class="d-none"
            accept="image/jpeg,image/png,image/gif"
            ref="fileInput"
            :disabled="!isEditable"
            @change="onImageChange">
          <v-hover v-slot:default="{ hover }" :disabled="!isEditable">
            <v-card
              :elevation="hover ? 24 : 8"
              :class="'d-inline-block mx-auto' + { ' on-hover': hover }">
              <v-avatar
                size="164"
                :class="isEditable ? 'clickable' : ''">
                <img
                  id="preview_img"
                  :src="imageSrc"
                  @click="focusFileInput">
              </v-avatar>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field v-if="type === 'edit'" v-model="userModel.id" label="ID" disabled filled></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-if="type === 'edit'" v-model="userModel.useable_points" label="保有ポイント" disabled filled></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="userModel.last_name"
            type="text"
            name="user[last_name]"
            v-validate="'required'"
            data-vv-as="姓"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('user[last_name]')"
            :error-messages="errors.collect('user[last_name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              姓<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="userModel.first_name"
            type="text"
            name="user[first_name]"
            v-validate="'required'"
            data-vv-as="名"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('user[first_name]')"
            :error-messages="errors.collect('user[first_name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              名<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            :items="rolesOptions"
            label="権限"
            v-model="selectedRole"
            :disabled="!isEditable || currentAdmin.id === user.id || user.role_id === 1">
          </v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="selectedDepartment"
            label="所属部署"
            :items="departmentsOptions"
            :disabled="!isEditable">
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="type === 'new'"
            v-model="userModel.password"
            type="password"
            name="user[password]"
            v-validate="'required|min:6'"
            data-vv-as="パスワード"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('user[password]')"
            :error-messages="errors.collect('user[password]')">
            <template v-slot:label>
              パスワード<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="userModel.phone_number"
            label="電話番号"
            name="user[phone_number]"
            hint="電話番号はハイフンなしで入力してください。"
            v-validate="'required|numeric|min:10|max:11'"
            data-vv-as="電話番号"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('user[phone_number]')"
            :error-messages="errors.collect('user[phone_number]')"
            :disabled="!isEditable"
            persistent-hint>
            <template v-slot:label>
              電話番号<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="userModel.email"
            label="メールアドレス"
            name="user[email]"
            v-validate="'email'"
            data-vv-as="メールアドレス"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('user[email]')"
            :error-messages="errors.collect('user[email]')"
            :disabled="!isEditable">
          </v-text-field>
        </v-col>
        <v-col cols="12" class="pb-0">
          <label class="grey--text text--darken-1">性別</label>
          <v-radio-group class="mt-1" v-model="userModel.gender" :disabled="!isEditable">
            <v-radio class="mb-4" label="男性" :value="1"></v-radio>
            <v-radio class="mb-4" label="女性" :value="2"></v-radio>
            <v-radio label="その他" :value="0"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12">
          <v-textarea
            name="input-7-1"
            label="ひとことコメント"
            :disabled="!isEditable"
            v-model="userModel.comment"
            auto-grow>
          </v-textarea>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn x-large color="primary" type="submit">
          {{ this.type === 'new' ? '新規登録' : '更新' }}
        </v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'
import loadImage from 'blueimp-load-image'
import objectFitImages from 'object-fit-images'

// storeTyopes
import { Types as DepartmentsTypes } from '../../../store/modules/departments/types'
import { Types as RolesTypes } from '../../../store/modules/roles/types'
import { Types as GlobalTypes } from '../../../store/types'
import { Types as UsersTypes } from '../../../store/modules/users/types'
import { Types as AdminTypes } from '../../../store/modules/admin/types'

// helper
import { privateMediaApi } from '../../helper'

// components
import DestroyDialog from '../../molecules/DestroyDialog'

// mixins
import { ImageCompressible } from '../../mixins/ImageCompressible'

export default {
  name: 'UsersForm',
  mixins: [ ImageCompressible ],
  components: {
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのユーザーを削除してよろしいですか？'
      },
      userModel: {},
      newPasswordModel: {},
      departmentsOptions: [],
      rolesOptions: [],
      selectedDepartment: {},
      selectedRole: {},
      imageSrc: '',
      isEditable: false,
      showPasswordDialog: false,
      validated: false,
      changePasswordDialog: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      departments: DepartmentsTypes.getters.GET_DEPARTMENTS_LIST,
      roles: RolesTypes.getters.GET_ROLES_LIST,
      user: UsersTypes.getters.GET_USERS_DETAIL,
      notification: UsersTypes.getters.GET_NOTIFICATION,
      currentAdmin: AdminTypes.getters.GET_ADMIN
    })
  },
  methods: {
    ...mapActions({
      apiGetDepartmentsList: DepartmentsTypes.actions.INDEX,
      apiGetRolesList: RolesTypes.actions.INDEX,
      apiGetUser: UsersTypes.actions.SHOW,
      apiCreateUser: UsersTypes.actions.CREATE,
      apiUpdateUser: UsersTypes.actions.UPDATE,
      apiDestroyUser: UsersTypes.actions.DESTROY,
      apiRestoreUser: UsersTypes.actions.RESTORE,
      resetUser: UsersTypes.actions.RESET_DETAIL,
      apiChangePassword: UsersTypes.actions.CHANGE_PASSWORD
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetUser()
      this.userModel = this.user
      this.selectedDepartment = this.user.department_id
      this.selectedRole = this.user.role_id
      this.getImageSrc(this.user.icon.url)
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    togglePasswordDialog: function () {
      this.showPasswordDialog = !this.showPasswordDialog
    },
    onSubmit: function () {
      if (!this.userModel.email) this.userModel.email = null
      this.userModel.department_id = this.selectedDepartment
      this.userModel.role_id = this.selectedRole
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          switch (this.type) {
            case 'new':
              this.requestCreate()
              break
            case 'edit':
              this.requestUpdate()
              break
          }
        }
      })
    },
    changePassword: function() {
      this.newPasswordModel.id = this.user.id
      this.apiChangePassword(this.newPasswordModel)
      this.toggleChangePasswordDialog()
      this.togglePasswordDialog()
    },
    onChangePassword: function () {
      this.newPasswordModel.id = this.user.id
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          this.changePasswordDialog = !this.changePasswordDialog
        }
      })
    },
    toggleChangePasswordDialog: function() {
      this.changePasswordDialog = !this.changePasswordDialog
    },
    async requestDestroy () {
      if (this.currentAdmin.id === this.userModel.id && this.user.role_id === 1) return
      await this.apiDestroyUser(this.user.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexUser' })
      }, 30)
    },
    async requestCreate () {
      await this.apiCreateUser(this.userModel)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'showUser', params: { id: this.user.id } })
      }, 30)
    },
    async requestUpdate () {
      await this.apiUpdateUser(this.userModel)
      setTimeout(() => {
        if (this.httpSuccess) this.toggleEditable()
      }, 30)
    },
    setDepartmentsOptions: function () {
      this.departmentsOptions = this.departments.map(department => {
        return {
          text: department.name,
          value: department.id
        }
      })
    },
    setRolesOptions: function () {
      this.rolesOptions = this.roles.map(role => {
        return {
          text: role.name,
          value: role.id,
          disabled: role.id === 1
        }
      })
    },
      onImageChange (e) {
        const agent = window.navigator.userAgent.toLowerCase()
        const ie11 = (agent.indexOf('trident/7') !== -1)
        const previewImg = document.getElementById('preview_img')
        const images = e.target.files || e.dataTransfer.files
        if (!images) {
          return
        }
        this.getBase64(images[0]).then(image => {
          this.userModel.icon = image
        })
        loadImage.parseMetaData(images[0], (data) => {
          const options = {
            canvas: true
          }
          if (data.exif) {
            options.orientation = data.exif.get('Orientation')
          }
          loadImage(images[0], (canvas) => {
            const data = canvas.toDataURL(images[0].type)
            const blob = this.base64ToBlob(data, images[0].type)
            const originUrl = window.URL.createObjectURL(blob)
            const newimg = new Image()
            newimg.src = originUrl
            newimg.onload = () => {
              const resizedCanvas = this.createResizedCanvasElement(newimg)
              const resizedBase64 = resizedCanvas.toDataURL(images[0].type)
              const resizedBlob = this.base64ToBlob(resizedBase64, images[0].type)
              const url = window.URL.createObjectURL(resizedBlob)
              this.imageSrc = url
              this.userModel.icon = resizedBase64
            }

            // IE11はimgタグのbackground-imageも更新
            if (ie11) previewImg.style.backgroundImage = 'url(' + url + ')'
          }, options)
        })
      },
    focusFileInput: function () {
      this.$refs.fileInput.click()
    },
    getImageSrc: function (url) {
      if (!url) {
        this.imageSrc = require('../../../../assets/images/no_image.png')
        return
      }
      privateMediaApi.getPrivateMedia(url, (imageData) => {
        this.imageSrc = window.URL.createObjectURL(imageData)
      })
    }
  },
  async created () {
    await objectFitImages()
    if (this.$route.params.id) {
      await this.apiGetUser(this.$route.params.id)
      this.userModel = this.user
      this.selectedDepartment = this.user.department_id
      this.selectedRole = this.user.role_id
      await this.getImageSrc(this.user.icon.url)
    } else {
      this.isEditable = !this.isEditable
      this.selectedDepartment = 1
      this.selectedRole = 2
      this.userModel.gender = 1
      this.imageSrc = require('../../../../assets/images/no_image.png')
    }
    await this.apiGetDepartmentsList()
    await this.apiGetRolesList()
    if (this.roles.length) this.setRolesOptions()
    if (this.departments.length) this.setDepartmentsOptions()
  }
}
</script>
<style scoped>
.avatar-area .v-card{
  border-radius: 50%;
  overflow: hidden;
}
.avatar-area .v-card .v-avatar img{
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
.absolute h2,
.absolute button{
  position: absolute;
}
</style>