<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn fab top right small color="primary" class="ma-3" @click="onClickNewCoin"><v-icon>mdi-plus</v-icon></v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="coin in coinsList" :key="coin.id" class="clickable" @click="onClickTr(coin.id)">
          <td>
            <v-chip :color="coin.enabled ? 'primary' : 'error'" outlined="">
              {{ coin.enabled ? '有効' : '無効' }}
            </v-chip>
          </td>
          <td>{{ coin.name | truncate(40) }}</td>
          <td :id="'image_' + coin.id">
            <img width="50" class="mt-2">
          </td>
          <td>{{ coin.default_point_number }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as CoinsTypes } from '../../../store/modules/coins/types'

// components

// helper
import { privateMediaApi } from '../../helper'

export default {
  name: 'CoinsListTable',
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: '有効/無効',
          sortable: false,
          value: 'enabled'
        },
        {
          text: 'コイン名',
          sortable: false,
          value: 'name'
        },
        {
          text: '画像',
          sortable: false,
          value: 'icon'
        },
        {
          text: 'デフォルトポイント数',
          sortable: true,
          value: 'default_point_number'
        }
      ],
      images: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      coinsList: CoinsTypes.getters.GET_COINS_LIST,
      coinsListPagenation: CoinsTypes.getters.GET_COINS_LIST_PAGENATION
    })
  },
  methods: {
    ...mapActions({
      apiGetCoins: CoinsTypes.actions.INDEX
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showCoin', params: { id: id } })
    },
    onClickNewCoin: function () {
      this.$router.push({ name: 'newCoin' })
    },
    getImageSrc: function (coin) {
      privateMediaApi.getPrivateMedia(coin.icon.url, (imageData) => {
        document.getElementById('image_' + coin.id).firstChild.src = window.URL.createObjectURL(imageData)
      })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetCoins({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    }
  },
  async created () {
    await this.apiGetCoins()
    const self = this
    this.coinsList.forEach(coin => {
      self.getImageSrc(coin)
    })
  },
  filters: {
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
