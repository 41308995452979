import http from '../../../packs/http'
import {
  removeNamespace,
  serializeObjectToQueryParameter,
  getFileNameFromContentDisposition
} from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'
import { Types as AdminTypes } from '../admin/types'

const API_PATH = 'admin/events/'
const _types = removeNamespace('events/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null
  },
  defaultDetail: {},
  pagenation: {},
  queryParams: {},
  export: {
    data: null,
    fileName: null
  }
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_EVENTS_LIST]: state => state.list,
  [_types.getters.GET_EVENTS_LIST_PAGENATION]: state => state.pagenation,
  [_types.getters.GET_EVENTS_DETAIL]: state => state.detail,
  [_types.getters.GET_EVENTS_EXPORT]: state => state.export
}

const mutations = {
  [_types.mutations.SET_EVENTS_LIST] (state, payload) {
    state.list = Object.assign([], _initialState.list, payload.list)
    state.pagenation = Object.assign({}, _initialState.list, payload.pagenation)
  },
  [_types.mutations.SET_EVENTS_DETAIL] (state, payload) {
    state.detail = Object.assign({}, _initialState.detail, payload)
    state.defaultDetail = Object.assign({}, _initialState.defaultDetail, payload)
  },
  [_types.mutations.RESET_EVENTS_DETAIL] (state) {
    state.detail = Object.assign({}, _initialState.detail, state.defaultDetail)
  },
  [_types.mutations.SET_QUERY_PARAMS] (state, payload) {
    state.queryParams = payload
  },
  [_types.mutations.SET_EVENTS_EXPORT] (state, payload) {
    state.export.data = payload.data
    state.export.fileName = payload.fileName
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    var queryParams = {}
    if (payload) {
      // full取得する場合には前回のクエリパラメータを引き継がない
      queryParams = payload.full ? payload : Object.assign({}, state.queryParams, payload)
    }
    commit(_types.mutations.SET_QUERY_PARAMS, queryParams)
    await http.get(API_PATH + serializeObjectToQueryParameter(queryParams)).then(response => {
      commit(_types.mutations.SET_EVENTS_LIST, {
        list: response.data.data.map(object => object.attributes),
        pagenation: response.data.pagenation
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.SHOW] ({ commit }, id) {
    commit(_types.mutations.SET_EVENTS_DETAIL, {})
    await http.get(API_PATH + id).then(response => {
      commit(_types.mutations.SET_EVENTS_DETAIL, Object.assign(response.data.data.attributes, { users: response.data.included }))
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.UPDATE] ({ commit }, payload) {
    const params = JSON.stringify({
      event: payload
    })
    await http.put(API_PATH + payload.id, params).then(response => {
      commit(_types.mutations.SET_EVENTS_DETAIL, Object.assign(response.data.data.attributes, { users: response.data.included }))
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'ポイント付与イベントを更新しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
      commit(_types.mutations.RESET_EVENTS_DETAIL)
    })
  },
  [_types.actions.RESET_DETAIL] ({ commit }) {
    commit(_types.mutations.RESET_EVENTS_DETAIL)
  },
  async [_types.actions.EXPORT] ({ commit }, payload) {
    await http.get(API_PATH + 'export' + serializeObjectToQueryParameter(state.queryParams), {
      responseType: 'blob'
    }).then(response => {
      commit(_types.mutations.SET_EVENTS_EXPORT, {
        data: response.data,
        fileName: getFileNameFromContentDisposition(response.headers['content-disposition'])
      })
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'ポイント付与イベント(CSV)をエクスポートしました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.IMPORT] ({ commit }, payload) {
    const formData = new FormData()
    formData.append('import_file', payload.formDataWithImportFile)
    await http.post(API_PATH, formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }).then(response => {
      commit(_types.mutations.SET_EVENTS_DETAIL, Object.assign(response.data.data.attributes, { users: response.data.included }))
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'ポイント付与イベント(CSV)をインポートしました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
