<template>
  <v-card class="pa-5">
    <label class="font-weight-bold">グループ検索</label>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="params.group_name" label="グループ名"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="params.owner_last_name" label="オーナー(姓)"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="params.owner_first_name" label="オーナー(名)"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="params.last_name" label="ユーザー(姓)"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="params.first_name" label="ユーザー(名)"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-btn type="submit" class="ma-3" color="indigo" left outlined rounded><v-icon>mdi-magnify</v-icon>検索</v-btn>
      </v-row>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapActions } from 'vuex'

// storeTyopes
import { Types as GroupsTypes } from '../../../store/modules/groups/types'

export default {
  name: 'GroupsSearchForm',
  data () {
    return {
      params: {
        // 明示的に検索時にはページ1が指定されるように
        page: 1,
        group_name: '',
        owner_last_name: '',
        owner_first_name: '',
        last_name: '',
        first_name: ''
      }
    }
  },
  methods: {
    ...mapActions({
      apiGetGroups: GroupsTypes.actions.INDEX
    }),
    onSubmit: function () {
      this.apiGetGroups(this.params)
    }
  }
}
</script>
