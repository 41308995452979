<template>
  <v-dialog v-model="dialog.active" width="500">
    <v-card>
      <v-card-title class="headline red white--text" primary-title>確認</v-card-title>
      <v-card-text class="pt-5 grey--text text--darken-2">{{ dialog.text }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="toggleDestroyDialog">キャンセル</v-btn>
        <v-btn class="red white--text" @click="requestDestroy">削除する</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DestroyDialog',
  props: {
    dialog: Object,
    toggleDestroyDialog: Function,
    requestDestroy: Function
  }
}
</script>
