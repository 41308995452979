import Vue from 'vue'
import Router from 'vue-router'

import Home from '../components/pages/Home.vue'
import SignIn from '../components/pages/SignIn'

// groups
import IndexGroup from '../components/pages/groups/Index.vue'
import ShowGroup from '../components/pages/groups/Show.vue'

// users
import IndexUser from '../components/pages/users/Index.vue'
import NewUser from '../components/pages/users/New.vue'
import ShowUser from '../components/pages/users/Show.vue'

// users
import IndexEvent from '../components/pages/events/Index.vue'
import ShowEvent from '../components/pages/events/Show.vue'

// coins
import IndexCoin from '../components/pages/coins/Index.vue'
import NewCoin from '../components/pages/coins/New.vue'
import ShowCoin from '../components/pages/coins/Show.vue'

// presents
import IndexPresent from '../components/pages/presents/Index.vue'
import ShowPresent from '../components/pages/presents/Show.vue'

// messageReports
import IndexMessageReport from '../components/pages/messageReports/Index.vue'
import ShowMessageReport from '../components/pages/messageReports/Show.vue'

// notificationCommentReport
import IndexNotificationCommentReport from '../components/pages/notificationCommentReports/Index.vue'
import ShowNotificationCommentReport from '../components/pages/notificationCommentReports/Show.vue'

// notifications
import IndexNotificaion from '../components/pages/notifications/Index.vue'
import NewNotification from '../components/pages/notifications/New.vue'
import ShowNotification from '../components/pages/notifications/Show.vue'

// incentives
import IndexIncentive from '../components/pages/incentives/Index.vue'
import NewIncentive from '../components/pages/incentives/New.vue'
import ShowIncentive from '../components/pages/incentives/Show.vue'

// incentiveExchanges
import IndexIncentiveExchange from '../components/pages/incentiveExchanges/Index.vue'
import NewIncentiveExchange from '../components/pages/incentiveExchanges/New.vue'
import ShowIncentiveExchange from '../components/pages/incentiveExchanges/Show.vue'

// helps
import IndexHelp from '../components/pages/helps/Index.vue'
import NewHelp from '../components/pages/helps/New.vue'
import ShowHelp from '../components/pages/helps/Show.vue'

// helpCategoriesp
import IndexHelpCategory from '../components/pages/helpCategories/Index.vue'
import NewHelpCategory from '../components/pages/helpCategories/New.vue'
import ShowHelpCategory from '../components/pages/helpCategories/Show.vue'

// dashboard
import IndexDashboard from '../components/pages/dashboards/Index.vue'

// roles
import IndexRole from '../components/pages/roles/Index.vue'
import ShowRole from '../components/pages/roles/Show.vue'

// departments
import IndexDepartment from '../components/pages/departments/Index.vue'
import NewDepartment from '../components/pages/departments/New.vue'
import ShowDepartment from '../components/pages/departments/Show.vue'

// errors
import Forbidden from '../components/pages/errors/Forbidden.vue'
import NotFound from '../components/pages/errors/NotFound.vue'
import InternalServerError from '../components/pages/errors/InternalServerError.vue'
import UnprocessableEntity from '../components/pages/errors/UnprocessableEntity.vue'

import store from '../store'
import { Types as GlobalTypes } from '../store/types'
import { Types as AuthTypes } from '../store/modules/auth/types'

Vue.use(Router)

const routes = [
  { path: '/', name: 'home', component: Home, meta: { requires: 'auth' } },
  { path: '/sign_in', name: 'signIn', component: SignIn, meta: { requires: 'unAuth' } },
  // PATH /groups
  { path: '/groups', name: 'indexGroup', component: IndexGroup, meta: { requires: 'auth' } },
  { path: '/groups/:id', name: 'showGroup', component: ShowGroup, meta: { requires: 'auth' } },
  // PATH /users
  { path: '/users', name: 'indexUser', component: IndexUser, meta: { requires: 'auth' } },
  { path: '/users/new', name: 'newUser', component: NewUser, meta: { requires: 'auth' } },
  { path: '/users/:id', name: 'showUser', component: ShowUser, meta: { requires: 'auth' } },
  // PATH /importedPoints
  { path: '/events', name: 'indexEvent', component: IndexEvent, meta: { requires: 'auth' } },
  { path: '/events/:id', name: 'showEvent', component: ShowEvent, meta: { requires: 'auth' } },
  // PATH /coins
  { path: '/coins', name: 'indexCoin', component: IndexCoin, meta: { requires: 'auth' } },
  { path: '/coins/new', name: 'newCoin', component: NewCoin, meta: { requires: 'auth' } },
  { path: '/coins/:id', name: 'showCoin', component: ShowCoin, meta: { requires: 'auth' } },
  // PATH /presents
  { path: '/presents', name: 'indexPresent', component: IndexPresent, meta: { requires: 'auth' } },
  { path: '/presents/:id', name: 'showPresent', component: ShowPresent, meta: { requires: 'auth' } },
  // PATH /messageReports
  { path: '/message_reports', name: 'indexMessageReport', component: IndexMessageReport, meta: { requires: 'auth' } },
  { path: '/message_reports/:id', name: 'showMessageReport', component: ShowMessageReport, meta: { requires: 'auth' } },
  // PATH /notificationCommentReport
  { path: '/notification_comment_reports', name: 'indexNotificationCommentReport', component: IndexNotificationCommentReport, meta: { requires: 'auth' } },
  { path: '/notification_comment_reports/:id', name: 'showNotificationCommentReport', component: ShowNotificationCommentReport, meta: { requires: 'auth' } },
  // PATH /notifications
  { path: '/notifications', name: 'indexNotificaion', component: IndexNotificaion, meta: { requires: 'auth' } },
  { path: '/notifications/new', name: 'newNotification', component: NewNotification, meta: { requires: 'auth' } },
  { path: '/notifications/:id', name: 'showNotification', component: ShowNotification, meta: { requires: 'auth' } },
  // PATH /incentives
  { path: '/incentives', name: 'indexIncentive', component: IndexIncentive, meta: { requires: 'auth' } },
  { path: '/incentives/new', name: 'newIncentive', component: NewIncentive, meta: { requires: 'auth' } },
  { path: '/incentives/:id', name: 'showIncentive', component: ShowIncentive, meta: { requires: 'auth' } },
  // PATH /incentive_exchanges
  { path: '/incentive_exchanges', name: 'indexIncentiveExchange', component: IndexIncentiveExchange, meta: { requires: 'auth' } },
  { path: '/incentive_exchanges/new', name: 'newIncentiveExchange', component: NewIncentiveExchange, meta: { requires: 'auth' } },
  { path: '/incentive_exchanges/:id', name: 'showIncentiveExchange', component: ShowIncentiveExchange, meta: { requires: 'auth' } },
  // PATH /helps
  { path: '/helps', name: 'indexHelp', component: IndexHelp, meta: { requires: 'auth' } },
  { path: '/helps/new', name: 'newHelp', component: NewHelp, meta: { requires: 'auth' } },
  { path: '/helps/:id', name: 'showHelp', component: ShowHelp, meta: { requires: 'auth' } },
  // PATH /helpCategories
  { path: '/help_categories', name: 'indexHelpCategory', component: IndexHelpCategory, meta: { requires: 'auth' } },
  { path: '/help_categories/new', name: 'newHelpCategory', component: NewHelpCategory, meta: { requires: 'auth' } },
  { path: '/help_categories/:id', name: 'showHelpCategory', component: ShowHelpCategory, meta: { requires: 'auth' } },
  // PATH /dashboard
  { path: '/dashboards', name: 'indexDashboard', component: IndexDashboard, meta: { requires: 'auth' } },
  // PATH /roles
  { path: '/roles', name: 'indexRole', component: IndexRole, meta: { requires: 'auth' } },
  { path: '/roles/:id', name: 'showRole', component: ShowRole, meta: { requires: 'auth' } },
  // PATH /departments
  { path: '/departments', name: 'indexDepartment', component: IndexDepartment, meta: { requires: 'auth' } },
  { path: '/departments/new', name: 'newDepartment', component: NewDepartment, meta: { requires: 'auth' } },
  { path: '/departments/:id', name: 'showDepartment', component: ShowDepartment, meta: { requires: 'auth' } },
  // errors
  { path: '/forbidden', name: 'forbidden', component: Forbidden },
  { path: '/unprocessable_entity', name: 'unprocessableEntity', component: UnprocessableEntity },
  { path: '/internal_server_error', name: 'internalServerError', component: InternalServerError },
  { path: '*', name: 'notFound', component: NotFound }
]

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

const nextAuth = (to, from, next, type) => {
  const decide = () => {
    if (type === 'auth' && !store.getters[AuthTypes.getters.HAS_SIGNED_IN]) {
      next({ path: '/sign_in', query: { redirect: to.fullPath } })
    } else if (type === 'unAuth' && store.getters[AuthTypes.getters.HAS_SIGNED_IN]) {
      // ログイン状態で/sign_inに直アクセスした場合
      next({ path: '/' })
    } else {
      next()
    }
  }
  if (store.getters[AuthTypes.getters.HAS_INITIALIZED]) {
    decide()
  } else {
    const unwatch = store.watch((state, getters) => getters[AuthTypes.getters.HAS_INITIALIZED], () => {
      unwatch()
      decide()
    })
  }
}

router.beforeEach((to, from, next) => {
  store.dispatch(GlobalTypes.actions.CLEAR_HTTP_STATUS)
  const requiresType = to.matched.some(record => record.meta.requires) ? to.matched[0].meta.requires : null
  if (requiresType) {
    nextAuth(to, from, next, requiresType)
  } else {
    next()
  }
})

export default router
