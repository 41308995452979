export function removeNamespace (namespace, types) {
  return _.reduce(types, (typeObj, typeValue, typeName) => {
    typeObj[typeName] = _.reduce(typeValue, (obj, v, k) => {
      obj[k] = v.replace(namespace, '')
      return obj
    }, {})
    return typeObj
  }, {})
}

export function serializeObjectToQueryParameter (object) {
  var parameters = []
  for (var p in object) {
    if (object.hasOwnProperty(p)) {
      parameters.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]))
    }
  }
  return '?' + parameters.join('&')
}

export function getFileNameFromContentDisposition (disposition) {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(disposition)
  if (matches != null && matches[1]) {
    const fileName = matches[1].replace(/['"]/g, '')
    return decodeURI(fileName)
  } else {
    return null
  }
}
