const namespace = 'users/'

export const Types = {
  getters: {
    GET_USERS_LIST: namespace + 'GET_USERS_LIST',
    GET_USERS_LIST_PAGENATION: namespace + 'GET_USERS_LIST_PAGENATION',
    GET_USERS_DETAIL: namespace + 'GET_USERS_DETAIL',
    GET_NOTIFICATION: namespace + 'GET_NOTIFICATION',
    GET_USERS_EXPORT: namespace + 'GET_USERS_EXPORT',
    GET_IMPORT_RESPONSE: namespace + 'GET_IMPORT_RESPONSE'
  },
  mutations: {
    SET_USERS_LIST: namespace + 'SET_USERS_LIST',
    SET_USERS_DETAIL: namespace + 'SET_USERS_DETAIL',
    RESET_USERS_DETAIL: namespace + 'RESET_USERS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS',
    SET_CREATE_NOTIFICATION: namespace + 'SET_CREATE_NOTIFICATION',
    SET_USERS_EXPORT: namespace + 'SET_USERS_EXPORT',
    SET_IMPORT_RESPONSE: namespace + 'SET_IMPORT_RESPONSE'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    CREATE: namespace + 'CREATE',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    RESTORE: namespace + 'RESTORE',
    EXPORT: namespace + 'EXPORT',
    IMPORT: namespace + 'IMPORT',
    PRE_IMPORT: namespace + 'PRE_IMPORT',
    RESET_DETAIL: namespace + 'RESET_DETAIL',
    CHANGE_PASSWORD: namespace + 'CHANGE_PASSWORD'
  }
}
