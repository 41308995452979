<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1 class="font-weight-bold">500 Internal Server Error</h1>
        <p class="font-weight-bold grey--text">サーバーエラーが発生しました。</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  }
}
</script>
