<script>
// libraries
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  name: 'PresentsChart',
  props: {
    chartData: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
