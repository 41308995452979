<template>
  <h2 class="h2-responsive font-weight-bold grey--text text--darken-1">{{ heading }}</h2>
</template>

<script>
export default {
  name: 'PageHeading',
  props: {
    heading: String
  }
}
</script>

<style>
</style>
