<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start absolute">
        <transition-group name="slide-fade">
          <h2 v-if="isEditable" key="edit" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
          <v-btn v-else-if="!isEditable" key="dialog" color="success" class="ma-3" @click="toggleChangeOwnerDialog">オーナー変更</v-btn>
        </transition-group>
        <v-dialog v-model="showChangeOwnerDialog" max-width="600px">
          <v-card>
            <form novalidate @submit.prevent="requestChangeOwner">
              <v-card-title class="headline green white--text">オーナー変更</v-card-title>
              <v-card-text>
                <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="currentOwner"
                          label="現在のオーナー"
                          filled
                          disabled>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-combobox
                          v-model="selectedOwner"
                          label="新しいオーナー"
                          @click="toggleOwnerSelectDialog"
                          readonly>
                        </v-combobox>
                      </v-col>
                      <v-dialog v-model="showOwnerSelectDialog" width="500">
                        <v-card>
                          <v-card-title class="headline primary white--text" primary-title>オーナー選択</v-card-title>
                          <v-divider></v-divider>
                          <v-row class="px-5">
                            <v-col cols="12" class="d-flex justify-center">
                              <v-card-actions>
                                <v-simple-table>
                                  <thead>
                                    <tr>
                                      <th v-for="header in headers" :key="header.text">
                                        {{ header.text }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="user in group.users" :key="user.id" class="clickable" @click="setOwner(user)" v-if="user.id != group.owner_id">
                                      <td>{{ user.last_name + user.first_name }}</td>
                                      <td>{{ user.role_name }}</td>
                                      <td>{{ user.department_name }}</td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </v-card-actions>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center pb-5 pt-0">
                              <v-btn color="primary" type="button" @click="toggleOwnerSelectDialog">閉じる</v-btn>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-dialog>
                    </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="toggleChangeOwnerDialog">キャンセル</v-btn>
                <v-btn type="submit" color="success" class="white--text">変更する</v-btn>
              </v-card-actions>
            </form>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row class="d-flex justify-start">
        <v-col cols="12" class="avatar-area ml-5">
          <p class="text-left grey--text">アイコン画像</p>
          <input
            type="file"
            class="d-none"
            accept="image/jpeg,image/png,image/gif"
            ref="fileInput"
            :disabled="!isEditable"
            @change="onImageChange">
          <v-hover v-slot:default="{ hover }" :disabled="!isEditable">
            <v-card
              :elevation="hover ? 24 : 8"
              :class="'d-inline-block mx-auto' + { ' on-hover': hover }">
              <v-avatar
                size="164"
                :class="isEditable ? 'clickable' : ''">
                <img
                  id="preview_img"
                  :src="imageSrc"
                  @click="focusFileInput">
              </v-avatar>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="groupModel.name"
            name="group[name]"
            v-validate="'required'"
            data-vv-as="グループ名"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('group[name]')"
            :error-messages="errors.collect('group[name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              グループ名<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="selectedUsers"
            label="ユーザー"
            :disabled="!isEditable"
            :readonly="isEditable"
            @click="toggleUsersSelectDialog"
            return-object
            multiple
            chips>
            <template v-slot:selection="{ item }">
              <v-chip
                :color="item.id === group.owner_id ? 'blue lighten-4' : ''"
                @click="toggleUsersSelectDialog">
                <v-icon v-if="item.id === group.owner_id" class="mr-1">mdi-crown-outline</v-icon><span>{{ item.text }}</span>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
        <v-dialog v-model="showUsersSelectDialog" width="500">
          <v-card>
            <v-card-title class="headline primary white--text" primary-title>ユーザー選択</v-card-title>
            <v-divider></v-divider>
            <v-row class="px-5">
              <v-col cols="12" class="d-flex justify-center">
                <v-card-actions>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th></th>
                        <th v-for="header in headers" :key="header.text">
                          {{ header.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="user in usersList" :key="user.id">
                        <td>
                          <v-checkbox
                            v-if="user.id !== group.owner_id"
                            v-model="selectedUsersId"
                            :value="user.id"
                            style="margin: 0px; padding: 0px;"
                            @change="changeSelectedUsers(user.id, user.last_name + user.first_name)"
                            hide-details/>
                          <v-icon v-else color="blue accent-1">mdi-crown-outline</v-icon>
                        </td>
                        <td>{{ user.last_name + user.first_name }}</td>
                        <td>{{ user.role_name }}</td>
                        <td>{{ user.department_name }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-actions>
              </v-col>
              <v-col cols="12" class="d-flex justify-end pr-5 pb-5 pt-0 ">
                <div>
                  <ListPagenation :pages="usersListPagenation.pages" :current="usersListPagenation.current" :changePageCallback="changePage"/>
                </div>
              </v-col>
              <v-col cols="12" class="d-flex justify-center pb-5 pt-0">
                <v-btn color="primary" type="button" @click="toggleUsersSelectDialog">閉じる</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">更新</v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'
import loadImage from 'blueimp-load-image'
import objectFitImages from 'object-fit-images'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as UsersSelectTypes } from '../../../store/modules/usersSelect/types'
import { Types as GroupsTypes } from '../../../store/modules/groups/types'

// components
import ListPagenation from '../../molecules/ListPagenation'
import DestroyDialog from '../../molecules/DestroyDialog'

// helper
import { privateMediaApi } from '../../helper'

// mixins
import { ImageCompressible } from '../../mixins/ImageCompressible'

export default {
  name: 'GroupsForm',
  mixins: [ ImageCompressible ],
  components: {
    ListPagenation,
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのグループを削除してもよろしいですか？'
      },
      groupModel: {},
      groupOwnerModel: {},
      currentOwnerAttributes: {},
      headers: [
        {
          text: '名前',
          value: 'name'
        },
        {
          text: '権限',
          value: 'role_id'
        },
        {
          text: '所属部署',
          value: 'department_id'
        }
      ],
      usersOptions: [],
      defaultSelectedUsers: [],
      selectedUsers: [],
      selectedUsersId: [],
      imageSrc: '',
      selectedOwner: '',
      currentOwner: '',
      isEditable: false,
      showUsersSelectDialog: false,
      showChangeOwnerDialog: false,
      showOwnerSelectDialog: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      usersList: UsersSelectTypes.getters.GET_USERS_LIST,
      usersListPagenation: UsersSelectTypes.getters.GET_USERS_LIST_PAGENATION,
      group: GroupsTypes.getters.GET_GROUPS_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetUsers: UsersSelectTypes.actions.INDEX,
      apiGetGroup: GroupsTypes.actions.SHOW,
      apiUpdateGroup: GroupsTypes.actions.UPDATE,
      apiDestroyGroup: GroupsTypes.actions.DESTROY,
      apiChangeGroupOwner: GroupsTypes.actions.CHANGE_OWNER,
      resetGroup: GroupsTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetGroup()
      this.groupModel = this.group
      this.setUsersOptions()
      this.getImageSrc(this.group.icon)
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    toggleUsersSelectDialog: function () {
      if (!this.isEditable) return
      this.showUsersSelectDialog = !this.showUsersSelectDialog
    },
    toggleChangeOwnerDialog: function () {
      this.showChangeOwnerDialog = !this.showChangeOwnerDialog
    },
    toggleOwnerSelectDialog: function () {
      this.showOwnerSelectDialog = !this.showOwnerSelectDialog
    },
    setOwner (user) {
      this.selectedOwner = user.last_name + user.first_name
      this.groupOwnerModel.new_owner_id = user.id
      this.groupOwnerModel.group_id = this.group.id
      this.toggleOwnerSelectDialog()
    },
    changeSelectedUsers: function (id, name) {
      if (!this.selectedUsersId.includes(id)) {
        this.selectedUsers = this.selectedUsers.filter(user => user.id !== id)
      } else {
        this.selectedUsers.push({
          id: id,
          text: name
        })
      }
    },
    setUsersOptions: function () {
      this.selectedUsers = this.group.users.map(user => {
        return {
          text: user.last_name + user.first_name,
          id: user.id
        }
      })
      this.selectedUsersId = this.selectedUsers.map(user => {
        return user.id
      })
    },
    onSubmit: function () {
      this.groupModel.user_ids = this.selectedUsersId
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          this.apiUpdateGroup(this.groupModel)
          this.isEditable = !this.isEditable
        }
      })
    },
    async requestDestroy () {
      await this.apiDestroyGroup(this.group.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexGroup' })
      }, 30)
    },
    async requestChangeOwner () {
      await this.apiChangeGroupOwner(this.groupOwnerModel)
      this.currentOwnerAttributes = this.group.users.filter(user =>
        this.group.owner_id === user.id
      )[0]
      this.currentOwner = this.currentOwnerAttributes.last_name + this.currentOwnerAttributes.first_name
      this.selectedOwner = ''
      this.groupOwnerModel.current_owner_id = this.group.owner_id
      this.groupOwnerModel.new_owner_id = this.group.owner_id
      this.toggleChangeOwnerDialog()
    },
    onImageChange (e) {
      const agent = window.navigator.userAgent.toLowerCase()
      const ie11 = (agent.indexOf('trident/7') !== -1)
      const previewImg = document.getElementById('preview_img')
      const images = e.target.files || e.dataTransfer.files
      if (!images) {
        return
      }
      this.getBase64(images[0]).then(image => {
        this.groupModel.icon = image
      })
      loadImage.parseMetaData(images[0], (data) => {
        const options = {
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation')
        }
        loadImage(images[0], (canvas) => {
          const data = canvas.toDataURL(images[0].type)
          const blob = this.base64ToBlob(data, images[0].type)
          const originUrl = window.URL.createObjectURL(blob)
          const newimg = new Image()
          newimg.src = originUrl
          newimg.onload = () => {
            const resizedCanvas = this.createResizedCanvasElement(newimg)
            const resizedBase64 = resizedCanvas.toDataURL(images[0].type)
            const resizedBlob = this.base64ToBlob(resizedBase64, images[0].type)
            const url = window.URL.createObjectURL(resizedBlob)
            this.imageSrc = url
            this.groupModel.icon = resizedBase64
          }
          // IE11はimgタグのbackground-imageも更新
          if (ie11) previewImg.style.backgroundImage = 'url(' + url + ')'
        }, options)
      })
    },
    focusFileInput: function () {
      this.$refs.fileInput.click()
    },
    getImageSrc: function (url) {
      if (!url) {
        this.imageSrc = require('../../../../assets/images/no_image.png')
        return
      }
      privateMediaApi.getPrivateMedia(url, (imageData) => {
        this.imageSrc = window.URL.createObjectURL(imageData)
      })
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetUsers({
        page: page
      })
    }
  },
  async created () {
    await objectFitImages()
    await this.apiGetGroup(this.$route.params.id)
    this.groupModel = this.group
    await this.apiGetUsers()
    this.pagenations = this.usersListPagenation
    if (this.usersList.length) this.setUsersOptions()
    await this.getImageSrc(this.group.icon)
    this.currentOwnerAttributes = this.group.users.filter(user =>
      this.group.owner_id === user.id
    )[0]
    this.currentOwner = this.currentOwnerAttributes.last_name + this.currentOwnerAttributes.first_name
    this.groupOwnerModel.current_owner_id = this.group.owner_id
    this.groupOwnerModel.new_owner_id = this.group.owner_id
  }
}
</script>
<style scoped>
.avatar-area .v-card{
  border-radius: 50%;
  overflow: hidden;
}
.avatar-area .v-card .v-avatar img{
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
.absolute h2,
.absolute button{
  position: absolute;
}
</style>
