<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ヘルプ一覧"/>
      </v-col>
      <v-col cols="12">
        <HelpsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import HelpsListTable from '../../organisms/helps/HelpsListTable'

export default {
  components: {
    PageHeading,
    HelpsListTable
  }
}
</script>
