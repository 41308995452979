import { removeNamespace } from '../../helper'
import { Types } from './types'

const _types = removeNamespace('admin/', Types)

const _initialState = {
  admin: {
    id: null,
    isAdmin: false
  }
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_ADMIN]: state => state.admin
}

const mutations = {
  [_types.mutations.SET_ADMIN] (state, payload) {
    state.admin = Object.assign({}, _initialState.admin, payload)
  },
  [_types.mutations.SYNC_ADMIN] (state, payload) {
    if (payload.id === state.admin.id) {
      state.admin = Object.assign({}, _initialState.admin, payload)
      state.admin.isAdmin = true
    }
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
