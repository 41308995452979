<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="コイン贈呈履歴一覧"/>
      </v-col>
      <v-col cols="12">
        <PresentsSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <PresentsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import PresentsSearchForm from '../../organisms/presents/PresentsSearchForm'
import PresentsListTable from '../../organisms/presents/PresentsListTable'

export default {
  components: {
    PageHeading,
    PresentsSearchForm,
    PresentsListTable
  }
}
</script>
