<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="インセンティブ一覧"/>
      </v-col>
      <v-col cols="12">
        <IncentivesListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import IncentivesListTable from '../../organisms/incentives/IncentivesListTable'

export default {
  components: {
    PageHeading,
    IncentivesListTable
  }
}
</script>
