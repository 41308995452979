const namespace = 'helpCategories/'

export const Types = {
  getters: {
    GET_HELP_CATEGORIES_LIST: namespace + 'GET_HELP_CATEGORIES_LIST',
    GET_HELP_CATEGORIES_LIST_PAGENATION: namespace + 'GET_HELP_CATEGORIES_LIST_PAGENATION',
    GET_HELP_CATEGORIES_DETAIL: namespace + 'GET_HELP_CATEGORIES_DETAIL'
  },
  mutations: {
    SET_HELP_CATEGORIES_LIST: namespace + 'SET_HELP_CATEGORIES_LIST',
    SET_HELP_CATEGORIES_DETAIL: namespace + 'SET_HELP_CATEGORIES_DETAIL',
    RESET_HELP_CATEGORIES_DETAIL: namespace + 'RESET_HELP_CATEGORIES_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    CREATE: namespace + 'CREATE',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    RESTORE: namespace + 'RESTORE',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
