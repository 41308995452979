<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-2">
      <v-btn left class="ma-sm-2" outlined rounded color="indigo" @click="showCorrespondenceTableDialog = true"><v-icon>mdi-table-large</v-icon>csv対応表</v-btn>
      <v-dialog v-model="showCorrespondenceTableDialog" max-width="1200px">
        <template>
          <v-simple-table class="pa-5 text-no-wrap">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>csv対応表</v-toolbar-title>
              </v-toolbar>
            </template>
            <template>
              <thead>
                <tr>
                  <th class="font-weight-bold text-center">値</th>
                  <th class="text-center" v-for="index of tableHeaderLength" :key="index">{{ index - 1 }}</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr>
                  <td class="font-weight-bold">権限</td>
                  <td v-for="index of tableHeaderLength" :key="index">
                    <template v-if="rolesList[index - 2]">
                      {{ rolesList[index - 2].name}}
                    </template>
                    <template v-else>
                      ー
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">部署</td>
                  <td v-for="index of tableHeaderLength" :key="index">
                    <template v-if="departmentsList[index -2]">
                      {{ departmentsList[index -2].name}}
                    </template>
                    <template v-else>
                      ー
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">性別</td>
                  <td>その他</td>
                  <td>男性</td>
                  <td>女性</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </v-dialog>
      <v-btn left class="ma-sm-2" outlined rounded color="indigo" @click="$refs.hiddenInputFileElement.click()"><v-icon>mdi-upload</v-icon>CSV一括登録</v-btn>
      <v-dialog v-model="showImportDialog" width="500">
        <v-card>
          <v-card-title class="headline primary white--text" primary-title>確認</v-card-title>
          <v-card-text class="pt-5 grey--text text--darken-2">
            <p>新しく追加されるユーザー： {{ importResponse.new_users_count }} 人</p>
            <p>更新されるユーザー： {{ importResponse.update_users_count }} 人</p>
            <p>上記の内容でCSVインポートしてよろしいですか？</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="toggleImportDialog">キャンセル</v-btn>
            <v-btn class="primary white--text" @click="requestImport">インポートする</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <input
        type="file"
        id="hiddenInputFile"
        accept=".csv"
        ref="hiddenInputFileElement"
        @change="selectedImportFile"
        hidden/>
      <v-btn left class="ma-sm-2" outlined rounded color="indigo" @click="requestExport"><v-icon>mdi-download</v-icon>CSV出力</v-btn>
      <v-btn fab top right small color="primary" class="ma-sm-2" @click="onClickNewUser"><v-icon>mdi-plus</v-icon></v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in usersList" @click="onClickTr(user.id)" :key="user.id" :class="user.id === currentAdmin.id ? 'current-admin clickable' : 'clickable'">
          <td>{{ user.last_name + user.first_name }}</td>
          <td>{{ user.role_name }}</td>
          <td class="py-3">{{ user.department_name }}</td>
          <td>{{ returnGender(user.gender) }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone_number }}</td>
          <td>{{ user.useable_points }}</td>
          <td>{{ user.current_sign_in_at | moment }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="usersListPagenation.pages" :current="usersListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as UsersTypes } from '../../../store/modules/users/types'
import { Types as AdminTypes } from '../../../store/modules/admin/types'
import { Types as RolesTypes } from '../../../store/modules/roles/types'
import { Types as DepartmentsTypes } from '../../../store/modules/departments/types'

// components
import ListPagenation from '../../molecules/ListPagenation'

// helpers
import { donwloadByVendor } from '../../helper'

export default {
  name: 'UsersListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isAsc: false,
      gender_attributes: [
        { text: 'その他', value: 0 },
        { text: '男性', value: 1 },
        { text: '女性', value: 2 }
      ],
      headers: [
        {
          text: '名前',
          sortable: false,
          value: 'name'
        },
        {
          text: '権限',
          sortable: true,
          value: 'role_id'
        },
        {
          text: '所属部署',
          sortable: true,
          value: 'department_id'
        },
        {
          text: '性別',
          sortable: true,
          value: 'gender'
        },
        {
          text: 'メールアドレス',
          sortable: true,
          value: 'email'
        },
        {
          text: '電話番号',
          sortable: true,
          value: 'phone_number'
        },
        {
          text: '保有ポイント',
          sortable: true,
          value: 'useable_points'
        },
        {
          text: '最終アクティブ',
          sortable: true,
          value: 'current_sign_in_at'
        }
      ],
      pagenations: {},
      sortOrder: '',
      currentSortCategory: '',
      importFile: '',
      showImportDialog: false,
      showCorrespondenceTableDialog: false,
      tableHeaderLength: ''
    }
  },
  computed: {
    ...mapGetters({
      currentAdmin: AdminTypes.getters.GET_ADMIN,
      usersList: UsersTypes.getters.GET_USERS_LIST,
      usersListPagenation: UsersTypes.getters.GET_USERS_LIST_PAGENATION,
      usersExport: UsersTypes.getters.GET_USERS_EXPORT,
      importResponse: UsersTypes.getters.GET_IMPORT_RESPONSE,
      rolesList: RolesTypes.getters.GET_ROLES_LIST,
      departmentsList: DepartmentsTypes.getters.GET_DEPARTMENTS_LIST,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetUsers: UsersTypes.actions.INDEX,
      apiExportUsers: UsersTypes.actions.EXPORT,
      apiPreImportUsers: UsersTypes.actions.PRE_IMPORT,
      apiImportUsers: UsersTypes.actions.IMPORT,
      apiGetRoles: RolesTypes.actions.INDEX,
      apiGetDepartments: DepartmentsTypes.actions.INDEX
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showUser', params: { id: id } })
    },
    onClickNewUser: function () {
      this.$router.push({ name: 'newUser' })
    },
    returnGender: function (id) {
      return this.gender_attributes.filter(option => option.value === id)[0].text
    },
    toggleImportDialog: function () {
      this.showImportDialog = !this.showImportDialog
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetUsers({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetUsers({
        page: page,
        sort: this.currentSortCategory ? this.currentSortCategory + ' ' + this.sortOrder : ''
      })
    },
    async requestExport () {
      await this.apiExportUsers()
      if (this.httpSuccess) {
        donwloadByVendor(this.usersExport.data, this.usersExport.fileName)
      }
    },
    async requestImport () {
      await this.apiImportUsers({
        formDataWithImportFile: this.importFile
      })
      this.pagenations = this.usersListPagenation
      this.toggleImportDialog()
    },
    async selectedImportFile (e) {
      e.preventDefault()
      this.importFile = e.target.files[0]
      await this.apiPreImportUsers({
        formDataWithImportFile: this.importFile
      })
      this.toggleImportDialog()
      document.getElementById('hiddenInputFile').value = ''
    }
  },
  async created () {
    await this.apiGetUsers()
    await this.apiGetRoles()
    await this.apiGetDepartments()
    this.tableHeaderLength = this.rolesList.length > this.departmentsList.length ? this.rolesList.length : this.departmentsList.length + 1
    this.pagenations = this.usersListPagenation
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD') : ''
    }
  }
}
</script>
