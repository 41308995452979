<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ユーザー一覧"/>
      </v-col>
      <v-col cols="12">
        <UsersSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <UsersListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import UsersSearchForm from '../../../components/organisms/users/UsersSearchForm'
import UsersListTable from '../../../components/organisms/users/UsersListTable'

export default {
  components: {
    PageHeading,
    UsersSearchForm,
    UsersListTable
  }
}
</script>
