import http from '../packs/http'

export const privateMediaApi = {
  getPrivateMedia: (url, callback) => {
    http.get(url, { responseType: 'blob' }).then(response => {
      callback(response.data)
    }).catch(error => {
    })
  }
}

export function donwloadByVendor (data, fileName, type = 'text/csv') {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(data, fileName)
  } else {
    const url = URL.createObjectURL(new Blob([data], { type: type }))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
  }
}
