<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ホーム"/>
      </v-col>
      <v-col cols="12">
        <Notifications />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// components
import PageHeading from '../atoms/PageHeading'
import Notifications from '../molecules/Notifications'

export default {
  components: {
    PageHeading,
    Notifications
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
    })
  },
  created () {
  }
}
</script>
