import http from '../../../packs/http'
import { removeNamespace, serializeObjectToQueryParameter } from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'

const API_PATH = 'admin/helps/'
const _types = removeNamespace('helps/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null
  },
  defaultDetail: {},
  pagenation: {},
  queryParams: {}
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_HELPS_LIST]: state => state.list,
  [_types.getters.GET_HELPS_LIST_PAGENATION]: state => state.pagenation,
  [_types.getters.GET_HELPS_DETAIL]: state => state.detail
}

const mutations = {
  [_types.mutations.SET_HELPS_LIST] (state, payload) {
    state.list = Object.assign([], _initialState.list, payload.list)
    state.pagenation = Object.assign({}, _initialState.list, payload.pagenation)
  },
  [_types.mutations.SET_HELPS_DETAIL] (state, payload) {
    state.detail = Object.assign({}, _initialState.detail, payload)
    state.defaultDetail = Object.assign({}, _initialState.defaultDetail, payload)
  },
  [_types.mutations.RESET_HELPS_DETAIL] (state) {
    state.detail = Object.assign({}, _initialState.detail, state.defaultDetail)
  },
  [_types.mutations.SET_QUERY_PARAMS] (state, payload) {
    state.queryParams = payload
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    var queryParams = {}
    if (payload) {
      // full取得する場合には前回のクエリパラメータを引き継がない
      queryParams = payload.full ? payload : Object.assign({}, state.queryParams, payload)
    }
    commit(_types.mutations.SET_QUERY_PARAMS, queryParams)
    await http.get(API_PATH + serializeObjectToQueryParameter(queryParams)).then(response => {
      commit(_types.mutations.SET_HELPS_LIST, {
        list: response.data.data.map(object => object.attributes),
        pagenation: response.data.pagenation
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.SHOW] ({ commit }, id) {
    commit(_types.mutations.SET_HELPS_DETAIL, {})
    await http.get(API_PATH + id).then(response => {
      commit(_types.mutations.SET_HELPS_DETAIL, response.data.data.attributes)
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.CREATE] ({ commit }, payload) {
    const params = JSON.stringify({
      help: payload
    })
    await http.post(API_PATH, params).then(response => {
      commit(_types.mutations.SET_HELPS_DETAIL, response.data.data.attributes)
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'ヘルプを作成しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.UPDATE] ({ commit }, payload) {
    const params = JSON.stringify({
      help: payload
    })
    await http.put(API_PATH + payload.id, params).then(response => {
      commit(_types.mutations.SET_HELPS_DETAIL, response.data.data.attributes)
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'ヘルプを更新しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
      commit(_types.mutations.RESET_HELPS_DETAIL)
    })
  },
  async [_types.actions.DESTROY] ({ commit }, id) {
    await http.delete(API_PATH + id).then(response => {
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'ヘルプを削除しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  [_types.actions.RESET_DETAIL] ({ commit }) {
    commit(_types.mutations.RESET_HELPS_DETAIL)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
