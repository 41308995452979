export const Types = {
  getters: {
    SHOULD_DISPLAY_PROGRESS: 'SHOULD_DISPLAY_PROGRESS',
    SET_HTTP_SUCCESS: 'SET_HTTP_SUCCESS',
    GET_HTTP_ERROR: 'GET_HTTP_ERROR'
  },
  mutations: {
    SET_PROGRESS: 'SET_PROGRESS',
    SET_HTTP_SUCCESS: 'SET_HTTP_SUCCESS',
    SET_HTTP_ERROR: 'SET_HTTP_ERROR',
    RESET_ALL_STATUS: 'RESET_ALL_STATUS'
  },
  actions: {
    REQUEST: 'REQUEST',
    RESPONSE: 'RESPONSE',
    CLEAR_HTTP_STATUS: 'CLEAR_HTTP_STATUS',
    FETCH_MASTERS: 'FETCH_MASTERS',
    RESET_STATUS: 'RESET_STATUS'
  }
}
