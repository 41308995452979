<template>
  <v-card class="pa-5">
    <p class="font-weight-bold mb-5">お知らせ検索</p>
    <form @submit.prevent="onSubmit">
      <label class="grey--text text--darken-1">絞り込み期間(公開日時)</label>
      <v-row>
        <v-col cols="12" sm="5">
          <v-menu
            v-model="from_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
            offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="from_day"
                label="開始日"
                prepend-icon="mdi-calendar-month"
                v-on="on"
                readonly>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="from_day"
              @input="from_menu = false"
              locale="ja-jp"
              :day-format="date => new Date(date).getDate()">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex justify-center" align-self="center">
          <span class="headline">〜</span>
        </v-col>
        <v-col cols="12" sm="5">
          <v-menu
            v-model="to_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
            offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="to_day"
                label="終了日"
                prepend-icon="mdi-calendar-month"
                v-on="on"
                readonly>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="to_day"
              @input="to_menu = false"
              locale="ja-jp"
              :day-format="date => new Date(date).getDate()">
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-btn type="submit" class="ma-3" color="indigo" left outlined rounded><v-icon>mdi-magnify</v-icon>検索</v-btn>
      </v-row>
    </form>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as NotificationsTypes } from '../../../store/modules/notifications/types'

export default {
  name: 'NotificationsSearchForm',
  data () {
    return {
      params: {
        // 明示的に検索時にはページ1が指定されるように
        page: 1,
        from_day: '',
        to_day: ''
      },
      from_day: '',
      to_day: '',
      from_menu: false,
      to_menu: false
    }
  },
  computed: {
    ...mapGetters({
      notificationsList: NotificationsTypes.getters.GET_NOTIFICATIONS_LIST
    })
  },
  methods: {
    ...mapActions({
      apiGetNotifications: NotificationsTypes.actions.INDEX
    }),
    onSubmit: function () {
      if (this.from_day) this.params.from_day = moment(this.from_day).format('YYYY/MM/DD')
      if (this.to_day) this.params.to_day = moment(this.to_day).format('YYYY/MM/DD')
      this.apiGetNotifications(this.params)
    }
  }
}
</script>
