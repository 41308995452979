const namespace = 'auth/'

export const Types = {
  getters: {
    HAS_INITIALIZED: namespace + 'HAS_INITIALIZED',
    HAS_SIGNED_IN: namespace + 'HAS_SIGNED_IN'
  },
  mutations: {
    INITIALIZED: namespace + 'INITIALIZED',
    SIGNED_IN: namespace + 'SIGNED_IN'
  },
  actions: {
    VALIDATE: namespace + 'VALIDATE',
    SIGN_IN: namespace + 'SIGN_IN',
    SIGN_OUT: namespace + 'SIGN_OUT'
  }
}
