<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="メッセージ通報履歴詳細"/>
      </v-col>
      <v-col cols="12">
        <MessageReportsForm type="edit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import MessageReportsForm from '../../organisms/messageReports/MessageReportsForm'

export default {
  components: {
    PageHeading,
    MessageReportsForm
  }
}
</script>
