const namespace = 'departments/'

export const Types = {
  getters: {
    GET_DEPARTMENTS_LIST: namespace + 'GET_DEPARTMENTS_LIST',
    GET_DEPARTMENTS_DETAIL: namespace + 'GET_DEPARTMENTS_DETAIL'
  },
  mutations: {
    SET_DEPARTMENTS_LIST: namespace + 'SET_DEPARTMENTS_LIST',
    SET_DEPARTMENTS_DETAIL: namespace + 'SET_DEPARTMENTS_DETAIL'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    CREATE: namespace + 'CREATE',
    SHOW: namespace + 'SHOW',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY'
  }
}
