<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn left class="ma-3" outlined rounded color="indigo" @click="requestExport"><v-icon>mdi-download</v-icon>CSV出力</v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="present in presentsList" @click="onClickTr(present.id)" :key="present.id" class="clickable">
          <td>{{ present.created_at | moment }}</td>
          <td>
            <template v-if="present.from === null">
              [ このユーザーは削除されました ]
            </template>
            <template v-else>
              {{ present.from.attributes.last_name + present.from.attributes.first_name | truncate(10) }}
            </template>
          </td>
          <td>
            <template v-if="present.to === null">
              [ このユーザーは削除されました ]
            </template>
            <template v-else>
              {{ present.to.attributes.last_name + present.to.attributes.first_name | truncate(10) }}
            </template>
          </td>
          <td>
            <template v-if="present.coin === null">
              [ このコインは削除されました ]
            </template>
            <template v-else>
              {{ present.coin.attributes.name | truncate(10) }}
            </template>
          </td>
          <td>{{ present.point_number }}</td>
          <td>{{ present.comment | truncate(15) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="presentsListPagenation.pages" :current="presentsListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as PresentsTypes } from '../../../store/modules/presents/types'

// helpers
import { donwloadByVendor } from '../../helper'

// components
import ListPagenation from '../../molecules/ListPagenation'

export default {
  name: 'PresentsListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: '日付',
          sortable: true,
          value: 'created_at'
        },
        {
          text: '贈呈者',
          sortable: true,
          value: 'from_id'
        },
        {
          text: '受贈者',
          sortable: true,
          value: 'to_id'
        },
        {
          text: '贈呈コイン名',
          sortable: true,
          value: 'coin_id'
        },
        {
          text: '贈呈ポイント数',
          sortable: true,
          value: 'point_number'
        },
        {
          text: 'コメント',
          sortable: false,
          value: 'comment'
        }
      ],
      pagenations: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      presentsList: PresentsTypes.getters.GET_PRESENTS_LIST,
      presentsListPagenation: PresentsTypes.getters.GET_PRESENTS_LIST_PAGENATION,
      presentsExport: PresentsTypes.getters.GET_PRESENTS_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetPresents: PresentsTypes.actions.INDEX,
      apiExportPresents: PresentsTypes.actions.EXPORT
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showPresent', params: { id: id } })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetPresents({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetPresents({
        page: page
      })
    },
    async requestExport () {
      await this.apiExportPresents()
      if (this.httpSuccess) {
        donwloadByVendor(this.presentsExport.data, this.presentsExport.fileName)
      }
    }
  },
  async created () {
    await this.apiGetPresents()
    this.pagenations = this.presentsListPagenation
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).format('YYYY/MM/DD') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
