<template>
  <v-card class="pa-5">
    <label class="font-weight-bold">コイン贈呈履歴検索</label>
    <form @submit.prevent="onSubmit">
      <v-row class="mb-3">
        <v-col cols="12" sm="6">
          <v-select
            :items="departmentsOptions"
            label="所属部署(贈呈者)"
            v-model="selectedFromDepartment">
          </v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            :items="departmentsOptions"
            label="所属部署(受贈者)"
            v-model="selectedToDepartment">
          </v-select>
        </v-col>
      </v-row>
      <label class="grey--text text--darken-1">絞り込み期間</label>
      <v-row>
        <v-col cols="12" sm="5">
          <v-menu
            v-model="from_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
            offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="from_day"
                label="開始日"
                prepend-icon="mdi-calendar-month"
                v-on="on"
                readonly>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="from_day"
              @input="from_menu = false"
              locale="ja-jp"
              :day-format="date => new Date(date).getDate()">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2" class="d-flex justify-center" align-self="center">
          <span class="headline">〜</span>
        </v-col>
        <v-col cols="12" sm="5">
          <v-menu
            v-model="to_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="290px"
            offset-y>
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="to_day"
                label="終了日"
                prepend-icon="mdi-calendar-month"
                v-on="on"
                readonly>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="to_day"
              @input="to_menu = false"
              locale="ja-jp"
              :day-format="date => new Date(date).getDate()">
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-btn type="submit" class="ma-3" color="indigo" left outlined rounded><v-icon>mdi-magnify</v-icon>検索</v-btn>
      </v-row>
    </form>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as DepartmentsTypes } from '../../../store/modules/departments/types'
import { Types as PresentsTypes } from '../../../store/modules/presents/types'

export default {
  name: 'PresentsSearchForm',
  data () {
    return {
      params: {
        // 明示的に検索時にはページ1が指定されるように
        page: 1,
        from_day: '',
        to_day: '',
        from_department_id: '',
        to_department_id: ''
      },
      from_day: '',
      to_day: '',
      from_department_id: '',
      to_department_id: '',
      departmentsOptions: [],
      selectedFromDepartment: '',
      selectedToDepartment: '',
      from_menu: false,
      to_menu: false
    }
  },
  computed: {
    ...mapGetters({
      departments: DepartmentsTypes.getters.GET_DEPARTMENTS_LIST
    })
  },
  methods: {
    ...mapActions({
      apiGetDepartmentsList: DepartmentsTypes.actions.INDEX,
      apiGetPresents: PresentsTypes.actions.INDEX
    }),
    onSubmit: function () {
      this.params.from_department_id = this.selectedFromDepartment
      this.params.to_department_id = this.selectedToDepartment
      if (this.from_day) this.params.from_day = moment(this.from_day).format('YYYY/MM/DD')
      if (this.to_day) this.params.to_day = moment(this.to_day).format('YYYY/MM/DD')
      this.apiGetPresents(this.params)
    },
    setDepartmentsOptions: function () {
      this.departmentsOptions = this.departments.map(department => {
        return {
          text: department.name,
          value: department.id
        }
      })
      this.departmentsOptions.unshift({
        text: '指定なし',
        value: ''
      })
    }
  },
  async created () {
    await this.apiGetDepartmentsList()
    if (this.departments.length) this.setDepartmentsOptions()
  }
}
</script>
