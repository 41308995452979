const namespace = 'incentives/'

export const Types = {
  getters: {
    GET_INCENTIVES_LIST: namespace + 'GET_INCENTIVES_LIST',
    GET_INCENTIVES_LIST_PAGENATION: namespace + 'GET_INCENTIVES_LIST_PAGENATION',
    GET_INCENTIVES_DETAIL: namespace + 'GET_INCENTIVES_DETAIL'
  },
  mutations: {
    SET_INCENTIVES_LIST: namespace + 'SET_INCENTIVES_LIST',
    SET_INCENTIVES_DETAIL: namespace + 'SET_INCENTIVES_DETAIL',
    RESET_INCENTIVES_DETAIL: namespace + 'RESET_INCENTIVES_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    CREATE: namespace + 'CREATE',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    RESTORE: namespace + 'RESTORE',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
