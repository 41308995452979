<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="roleModel.id"
            label="ID"
            disabled
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="roleModel.name"
            name="role[name]"
            v-validate="'required'"
            data-vv-as="権限名"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('role[name]')"
            :error-messages="errors.collect('role[name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              権限名<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="roleModel.is_admin"
            disabled
            label="管理者権限">
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="selectedRole"
            label="グループ作成権限"
            :items="rolesOptions"
            :disabled="!isEditable">
          </v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="roleModel.group_creatable_number"
            name="role[group_creatable_number]"
            v-validate="selectedRole === true ? 'required|numeric|min_value:1' : ''"
            data-vv-as="グループ作成可能数"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('role[group_creatable_number]')"
            :error-messages="errors.collect('role[group_creatable_number]')"
            :disabled="!isEditable || selectedRole != true">
            <template v-slot:label>グループ作成可能数</template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="roleModel.group_members_min_number"
            name="role[group_members_min_number]"
            v-validate="selectedRole !== '' ? 'required|numeric|min_value:1' : ''"
            data-vv-as="グループ最小ユーザー数"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('role[group_members_min_number]')"
            :error-messages="errors.collect('role[group_members_min_number]')"
            :disabled="!isEditable || selectedRole === ''">
            <template v-slot:label>グループ最小ユーザー数</template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="roleModel.coin_sendable_per_month_number"
            name="role[coin_sendable_per_month_number]"
            v-validate="'required|numeric|min_value:1'"
            data-vv-as="月々の贈呈可能コイン数"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('role[coin_sendable_per_month_number]')"
            :error-messages="errors.collect('role[coin_sendable_per_month_number]')"
            :disabled="!isEditable">
            <template v-slot:label>
              月々の贈呈可能コイン数<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">更新</v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as RolesTypes } from '../../../store/modules/roles/types'

export default {
  name: 'RolesForm',
  components: {},
  props: {
    type: String
  },
  data () {
    return {
      roleModel: {},
      createdAt: '',
      isEditable: false,
      validated: false,
      rolesOptions: [],
      selectedRole: ''
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      role: RolesTypes.getters.GET_ROLES_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetRole: RolesTypes.actions.SHOW,
      apiUpdateRole: RolesTypes.actions.UPDATE,
      resetRole: RolesTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetRole()
      this.roleModel = this.role
      if (this.role.group_creatable_number === 0) {
        this.role.group_creatable_number = ''
      }
    },
    setRolesOptions: function () {
      this.rolesOptions = [
        { text: '権限あり(グループ作成数無制限)', value: 0 },
        { text: '権限あり(グループ作成数に上限あり)', value: true},
        { text: '権限なし', value: '' },
      ]
      return this.rolesOptions
    },
    onSubmit: function () {
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          this.requestUpdate()
        }
      })
    },
    async requestUpdate () {
      if (this.selectedRole === 0) {
        this.role.group_creatable_number = 0
      } else if(this.selectedRole === '') {
        this.role.group_creatable_number = ''
        this.role.group_members_min_number = ''
      }
      await this.apiUpdateRole(this.roleModel)
      this.setRolesOptions()
      this.toggleEditable()
    }
  },
  async created () {
    await this.apiGetRole(this.$route.params.id)
    this.roleModel = this.role
    this.createdAt = moment(this.role.created_at).format('YYYY/MM/DD')
    if (this.role.group_creatable_number === null) {
      this.selectedRole = ''
    } else if (this.role.group_creatable_number === 0) {
      this.selectedRole = 0
    } else {
      this.selectedRole = true
    }
    if (this.role.group_creatable_number === 0) {
      this.role.group_creatable_number = ''
    }
    this.setRolesOptions()
  }
}
</script>

<style>
ul{
  list-style-type: none
}
</style>
