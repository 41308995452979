const namespace = 'coins/'

export const Types = {
  getters: {
    GET_COINS_LIST: namespace + 'GET_COINS_LIST',
    GET_COINS_LIST_PAGENATION: namespace + 'GET_COINS_LIST_PAGENATION',
    GET_COINS_DETAIL: namespace + 'GET_COINS_DETAIL'
  },
  mutations: {
    SET_COINS_LIST: namespace + 'SET_COINS_LIST',
    SET_COINS_DETAIL: namespace + 'SET_COINS_DETAIL',
    RESET_COINS_DETAIL: namespace + 'RESET_COINS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    CREATE: namespace + 'CREATE',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    RESTORE: namespace + 'RESTORE',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
