<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ヘルプカテゴリ一覧"/>
      </v-col>
      <v-col cols="12">
        <HelpCategoriesListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import HelpCategoriesListTable from '../../organisms/helpCategories/HelpCategoriesListTable'

export default {
  components: {
    PageHeading,
    HelpCategoriesListTable
  }
}
</script>
