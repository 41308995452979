<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="メッセージ通報履歴一覧"/>
      </v-col>
      <v-col cols="12">
        <MessageReportsSearchForm class="mb-4"/>
      </v-col>
      <v-col cols="12">
        <MessageReportsListTable/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import MessageReportsSearchForm from '../../organisms/messageReports/MessageReportsSearchForm'
import MessageReportsListTable from '../../organisms/messageReports/MessageReportsListTable'

export default {
  components: {
    PageHeading,
    MessageReportsSearchForm,
    MessageReportsListTable
  }
}
</script>
