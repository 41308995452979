<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="部署詳細"/>
      </v-col>
      <v-col cols="12">
        <DepartmentsForm type="edit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import DepartmentsForm from '../../organisms/departments/DepartmentsForm'

export default {
  components: {
    PageHeading,
    DepartmentsForm
  }
}
</script>