import http from '../../../packs/http'
import {
  removeNamespace
} from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'

const API_PATH = 'notification_comments/'
function apiPathWithPathParam (notificationId, id = '') {
  return 'admin/notifications/' + notificationId + '/' + API_PATH + id
}
function apiPathForReportWithPathParam (notificationId, id = '') {
  return 'notifications/' + notificationId + '/' + API_PATH + id + '/reports'
}
const _types = removeNamespace('notificationComments/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null
  }
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_NOTIFICATION_COMMENTS]: state => state.list
}

const mutations = {
  [_types.mutations.SET_NOTIFICATION_COMMENTS] (state, payload) {
    state.list = Object.assign([], _initialState.list, payload.list)
    state.pagenation = Object.assign({}, _initialState.list, payload.pagenation)
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, notificationId) {
    await http.get(apiPathWithPathParam(notificationId)).then(response => {
      commit(_types.mutations.SET_NOTIFICATION_COMMENTS, {
        list: response.data.data.map(object => object.attributes),
        pagenation: response.data.pagenation
      })
      commit(_types.mutations.SET_GROUPS_LIST, {
        list: response.data.data.map(
          notificationComment => Object.assign(
            notificationComment.attributes,
            {
              user: response.data.included
                .filter(resource => resource.type === 'user' && notificationComment.relationships.user.data.id === resource.id)[0]
            }
          )
        )
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.CREATE] ({ commit }, payload) {
    const params = JSON.stringify({
      notification_comment: payload.notification_comment
    })
    await http.post(apiPathWithPathParam(payload.id), params).then(response => {
      setTimeout(() => {
        commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'お知らせにコメントを投稿しました。', { root: true })
      }, 50)
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.DESTROY] ({ commit }, payload) {
    await http.delete(apiPathWithPathParam(payload.notificationId, payload.commentId)).then(response => {
      setTimeout(() => {
        commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'コメントを削除しました。', { root: true })
      }, 50)
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.REPORT] ({ commit }, payload) {
    const params = JSON.stringify({
      notification_comment_report: {
        report_content: payload.report_content
      }
    })
    await http.post(apiPathForReportWithPathParam(payload.notificationId, payload.commentId), params).then(response => {
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'コメントを通報しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
