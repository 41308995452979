import Vue from 'vue'
import App from 'App.vue'
import '@mdi/font/css/materialdesignicons.css'
import 'babel-polyfill'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VeeValidate, { Validator } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja'
import router from './router'
import i18n from './i18n'
import store from '../store'

Vue.config.productionTip = false
Validator.localize('ja', ja)
Vue.use(VeeValidate, { locale: ja })
Vue.use(Vuetify, {
  iconfont: 'mdi'
})

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    vuetify: new Vuetify(),
    router,
    i18n,
    store,
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
})
