import http from '../../../packs/http'
import {
  removeNamespace,
  serializeObjectToQueryParameter,
  getFileNameFromContentDisposition
} from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'

const API_PATH = 'admin/dashboards'
const _types = removeNamespace('dashboards/', Types)

const _initialState = {
  groupId: '',
  yearly: false,
  monthly: false,
  weekly: false,
  daily: false,
  totalData: [],
  groupData: [],
  detail: {
    id: null
  },
  defaultDetail: {},
  queryParams: {},
  totalExportData: {
    data: null,
    fileName: null
  },
  groupExportData: {
    data: null,
    fileName: null
  }
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_TOTAL_CHART_DATA]: state => state.totalData,
  [_types.getters.GET_GROUP_CHART_DATA]: state => state.groupData,
  [_types.getters.GET_GROUP_ID]: state => state.groupId,
  [_types.getters.GET_YEARLY]: state => state.yearly,
  [_types.getters.GET_MONTHLY]: state => state.monthly,
  [_types.getters.GET_WEEKLY]: state => state.weekly,
  [_types.getters.GET_DAILY]: state => state.daily,
  [_types.getters.GET_QUERY_PARAMS]: state => state.queryParams,
  [_types.getters.GET_TOTAL_EXPORT]: state => state.totalExportData,
  [_types.getters.GET_GROUP_EXPORT]: state => state.groupExportData
}

const mutations = {
  [_types.mutations.SET_TOTAL_CHART_DATA] (state, payload) {
    state.totalData = Object.assign([], _initialState.totalData, payload.totalData)
  },
  [_types.mutations.SET_GROUP_CHART_DATA] (state, payload) {
    state.groupData = Object.assign([], _initialState.groupData, payload.groupData)
  },
  [_types.mutations.SET_GROUP_ID] (state, payload) {
    state.groupId = payload
  },
  [_types.mutations.SET_YEARLY] (state, payload) {
    state.yearly = payload
  },
  [_types.mutations.SET_MONTHLY] (state, payload) {
    state.monthly = payload
  },
  [_types.mutations.SET_WEEKLY] (state, payload) {
    state.weekly = payload
  },
  [_types.mutations.SET_DAILY] (state, payload) {
    state.daily = payload
  },
  [_types.mutations.SET_QUERY_PARAMS] (state, payload) {
    state.queryParams = payload
  },
  [_types.mutations.SET_TOTAL_EXPORT] (state, payload) {
    state.totalExportData.data = payload.data
    state.totalExportData.fileName = payload.fileName
  },
  [_types.mutations.SET_GROUP_EXPORT] (state, payload) {
    state.groupExportData.data = payload.data
    state.groupExportData.fileName = payload.fileName
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    var queryParams = {}
    if (payload) {
      // full取得する場合には前回のクエリパラメータを引き継がない
      queryParams = payload
      // 絞り込み期間の日付がある場合はそちら優先に
      queryParams.from_day = payload.search_from_day ? payload.search_from_day : payload.from_day
      queryParams.to_day = payload.search_to_day ? payload.search_to_day : payload.to_day
    }
    if (queryParams.group_id != null) {
      commit(_types.mutations.SET_GROUP_ID, queryParams.group_id)
    }
    if (queryParams.yearly === true) {
      commit(_types.mutations.SET_YEARLY, true)
      commit(_types.mutations.SET_MONTHLY, false)
      commit(_types.mutations.SET_WEEKLY, false)
      commit(_types.mutations.SET_DAILY, false)
    }
    if (queryParams.monthly === true) {
      commit(_types.mutations.SET_MONTHLY, true)
      commit(_types.mutations.SET_YEARLY, false)
      commit(_types.mutations.SET_WEEKLY, false)
      commit(_types.mutations.SET_DAILY, false)
    }
    if (queryParams.weekly === true) {
      commit(_types.mutations.SET_WEEKLY, true)
      commit(_types.mutations.SET_YEARLY, false)
      commit(_types.mutations.SET_MONTHLY, false)
      commit(_types.mutations.SET_DAILY, false)
    }
    if (queryParams.daily === true) {
      commit(_types.mutations.SET_DAILY, true)
      commit(_types.mutations.SET_YEARLY, false)
      commit(_types.mutations.SET_MONTHLY, false)
      commit(_types.mutations.SET_WEEKLY, false)
    }
    commit(_types.mutations.SET_QUERY_PARAMS, queryParams)
    await http.get(API_PATH + serializeObjectToQueryParameter(queryParams)).then(response => {
      if (queryParams.group_id != null) {
        commit(_types.mutations.SET_GROUP_CHART_DATA, {
          groupData: response.data.data.dashboards
        })
      } else {
        commit(_types.mutations.SET_TOTAL_CHART_DATA, {
          totalData: response.data.data.dashboards
        })
      }
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.EXPORT_TOTAL_DATA] ({ commit }, payload) {
    await http.get(API_PATH + '/export' + serializeObjectToQueryParameter(state.queryParams), {
      responseType: 'blob'
    }).then(response => {
      commit(_types.mutations.SET_TOTAL_EXPORT, {
        data: response.data,
        fileName: getFileNameFromContentDisposition(response.headers['content-disposition'])
      })
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, '全社データをエクスポートしました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.EXPORT_GROUP_DATA] ({ commit }, payload) {
    await http.get(API_PATH + '/export' + serializeObjectToQueryParameter(state.queryParams), {
      responseType: 'blob'
    }).then(response => {
      commit(_types.mutations.SET_GROUP_EXPORT, {
        data: response.data,
        fileName: getFileNameFromContentDisposition(response.headers['content-disposition'])
      })
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'グループデータをエクスポートしました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
