<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="12" sm="8" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1" key="edit">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
          <h2 v-else class="edit-text ma-3 h2-responsive headline font-weight-bold grey--text text--darken-1" key="status">
              このお知らせは
              <template v-if="notification.status === 1">
                <p class="d-inline primary--text">公開済み</p> です
              </template>
              <template v-else-if="notification.status === 0 && notification.published_at">
                <p class="d-inline warning--text">予約投稿</p> です<v-subheader class="d-inline">({{ notification.published_at | moment }} 公開予定)</v-subheader>
              </template>
              <template v-else>
                <p class="d-inline warning--text">下書き</p> です
              </template>
          </h2>
        </transition>
      </v-col>
      <v-col cols="12" sm="4" class="d-flex justify-end" v-if="currentAdmin.isAdmin || currentAdmin.id == notification.user.id">
        <v-btn
          class="text-right ma-2 ma-sm-3"
          :color="isEditable ? 'grey' : 'primary'"
          @click="toggleEditable"
          fab
          top
          right
          small>
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form>
      <v-row>
        <v-col cols="12" :sm="type === 'edit' ? 7 : ''">
          <v-col cols="12">
            <v-text-field
              v-if="type === 'edit'"
              v-model="contributor"
              label="投稿者"
              disabled
              filled>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="notificationModel.title"
              name="notificationModel[title]"
              v-validate="'required'"
              data-vv-as="タイトル"
              :customValidation="isEditable && validated"
              :isValid="!errors.has('notificationModel[title]')"
              :error-messages="errors.collect('notificationModel[title]')"
              :disabled="!isEditable">
              <template v-slot:label>
                タイトル<span class="ml-2 red--text text--accent-2">必須</span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-combobox
              v-model="selectedGroups"
              label="投稿先グループ"
              name="notificationModel[groups]"
              :disabled="!isEditable"
              :readonly="isEditable"
              v-validate="'required'"
              data-vv-as="投稿先グループ"
              :customValidation="isEditable && validated"
              :isValid="!errors.has('notificationModel[groups]')"
              :error-messages="errors.collect('notificationModel[groups]')"
              @click="toggleGroupsSelectDialog"
              return-object
              multiple
              chips>
              <template v-slot:label>
                投稿先グループ<span class="ml-2 red--text text--accent-2">必須</span>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip
                  @click="toggleGroupsSelectDialog">
                  <span>{{ item.text }}</span>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-dialog v-model="showGroupsSelectDialog" width="400">
            <v-card class="group-dialog">
              <v-card-title class="headline primary white--text" primary-title>投稿先グループ選択</v-card-title>
              <v-divider></v-divider>
              <v-row class="px-5">
                <v-col cols="12" class="d-flex justify-center pt-0">
                  <v-card-actions>
                  <v-col cols="12">
                    <v-checkbox
                      class="d-block"
                      v-model="postToAllGroups"
                      style="margin: 0px; padding: 0px;"
                      label="全グループに投稿"
                      hide-details/>
                  </v-col>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th></th>
                          <th v-for="header in headers" :key="header.text">
                            {{ header.text }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="group in groupsList" :key="group.id">
                          <td>
                            <v-checkbox
                              v-model="selectedGroupsId"
                              :value="group.id"
                              style="margin: 0px; padding: 0px;"
                              @change="changeSelectedGroups(group.id, group.name)"
                              :disabled="postToAllGroups"
                              hide-details/>
                          </td>
                          <td>{{ group.name }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-card-actions>
                </v-col>
                <v-col cols="12" class="d-flex justify-end pr-5 pb-5 pt-0">
                  <div>
                    <ListPagenation :pages="groupsListPagenation.pages" :current="groupsListPagenation.current" :changePageCallback="changePage"/>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pb-5 pt-0">
                  <v-btn color="primary" type="button" @click="toggleGroupsSelectDialog">閉じる</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
          <v-col cols="12">
            <v-textarea
              v-model="notificationModel.content"
              name="notification[content]"
              label="投稿内容"
              auto-grow
              v-validate="'required'"
              data-vv-as="投稿内容"
              :customValidation="isEditable && validated"
              :isValid="!errors.has('notification[content]')"
              :error-messages="errors.collect('notification[content]')"
              :disabled="!isEditable">
              <template v-slot:label>
                投稿内容<span class="ml-2 red--text text--accent-2">必須</span>
              </template>
            </v-textarea>
          </v-col>
          <v-subheader>画像/PDF (最大3つまで)</v-subheader>
          <v-row class="pa-3">
            <v-col
              v-for="index of 3"
              :key="index"
              :cols="type === 'new' ? 3 : 4"
              class="avatar-area">
              <input
                type="file"
                class="d-none"
                accept="
                  image/jpeg,
                  image/png,
                  image/gif,
                  video/mp4,
                  video/quicktime,
                  application/pdf,
                  application/vnd.ms-excel,
                  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                  application/vnd.ms-powerpoint,
                  application/vnd.openxmlformats-officedocument.presentationml.presentation,
                  application/msword,
                  application/vnd.openxmlformats-officedocument.wordprocessingml.document
                "
                :ref="'fileInput_' + index"
                :disabled="!isEditable"
                @change="onImageChange(index, $event)">
              <v-btn
                color="grey"
                class="mb-3 d-block"
                @click="clearImage(index)"
                :disabled="!isEditable"
                dark
                small>
                <v-icon small>mdi-close</v-icon>削除
              </v-btn>
              <v-hover v-slot:default="{ hover }" :disabled="!isEditable">
                <v-card
                  :elevation="hover ? 24 : 8"
                  :class="'d-inline-block mx-auto' + { ' on-hover': hover }">
                  <v-avatar
                    size="164"
                    :class="isEditable ? 'clickable' : ''">
                    <img
                      :id="'preview_img_' + index"
                      @click="focusFileInput('fileInput_' + index)">
                  </v-avatar>
                </v-card>
              </v-hover>
              <v-subheader :id="'file_name_' + index"></v-subheader>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-checkbox
                v-model="notificationModel.is_required_read"
                :disabled="!isEditable || notificationModel.status === 1"
                label="必読設定">
              </v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="notificationModel.is_required_approval"
                :disabled="!isEditable || notificationModel.status === 1"
                label="承認設定">
              </v-checkbox>
            </v-col>
          </v-row>
            <template v-if="notificationModel.is_required_approval">
            <v-subheader>承認期限</v-subheader>
            <v-row>
            <v-col cols="12" sm="6" class="pt-0">
              <v-menu
                v-model="approve_date_menu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="approveDeadlineDate"
                    label="日付"
                    prepend-icon="mdi-calendar-month"
                    :disabled="!isEditable || notificationModel.status === 1"
                    v-on="on"
                    readonly>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="approveDeadlineDate"
                  @input="approve_date_menu = false"
                  locale="ja-jp"
                  :allowed-dates="allowedDate"
                  :day-format="date => new Date(date).getDate()">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0">
              <v-menu
                ref="approve_time_menu"
                v-model="approve_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="approveDeadlineTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="approveDeadlineTime"
                    label="時間"
                    prepend-icon="mdi-clock-outline"
                    :disabled="!isEditable || notificationModel.status === 1"
                    v-on="on"
                    readonly>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="approve_time_menu"
                  v-model="approveDeadlineTime"
                  format="24hr"
                  :allowed-minutes="allowedFiveMinutesStep"
                  @click:minute="$refs.approve_time_menu.save(approveDeadlineTime)">
                </v-time-picker>
              </v-menu>
            </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="notificationModel.approval_confirmation"
                  placeholder="承認しますか？"
                  name="notification[is_required_approval]"
                  v-validate="(notificationModel.is_required_approval ? 'required' : '')"
                  data-vv-as="承認ボタン用文言"
                  :customValidation="isEditable && validated && notificationModel.is_required_approval"
                  :isValid="!errors.has('notification[is_required_approval]')"
                  :error-messages="errors.collect('notification[is_required_approval]')"
                  :disabled="!isEditable || notificationModel.status === 1">
                  <template v-slot:label>
                    承認ボタン用文言<span class="ml-2 red--text text--accent-2">必須</span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            </template>
          <v-subheader>
            <template v-if="type === 'new' || notificationModel.status === 0">
              予約投稿日時
            </template>
            <template v-else>
              公開日時
            </template>
          </v-subheader>
          <v-row>
            <v-col cols="12" sm="6" class="pt-0">
              <v-menu
                v-model="post_date_menu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="postStartDate"
                    label="日付"
                    prepend-icon="mdi-calendar-month"
                    :disabled="!isEditable || notificationModel.status === 1"
                    v-on="on"
                    @click:clear="postStartDate = null"
                    clearable
                    readonly>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="postStartDate"
                  @input="date_menu = false"
                  locale="ja-jp"
                  :allowed-dates="allowedDate"
                  :day-format="date => new Date(date).getDate()">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" class="pt-0">
              <v-menu
                ref="post_time_menu"
                v-model="post_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="postStartTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="postStartTime"
                    label="時間"
                    prepend-icon="mdi-clock-outline"
                    :disabled="!isEditable || notificationModel.status === 1"
                    v-on="on"
                    @click:clear="postStartTime = null"
                    clearable
                    readonly>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="post_time_menu"
                  v-model="postStartTime"
                  :allowed-minutes="allowedThirtyMinutesStep"
                  format="24hr"
                  @click:minute="$refs.post_time_menu.save(postStartTime)">
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="type === 'edit'" cols="12" sm="5">
          <v-subheader>コメント({{ notificationComments.length }})</v-subheader>
          <v-card class="pa-4 overflow-y-auto mb-5" style="max-height: 500px">
            <v-col cols="12">
              <div v-for="comment in notificationComments" :key="comment.id" class="mb-5">
                <v-subheader>
                  {{ comment.user.attributes.last_name + comment.user.attributes.first_name }} {{ comment.created_at | moment }}
                  <v-btn
                    v-if="currentAdmin.isAdmin || currentAdmin.id == comment.user.id"
                    class="ml-3"
                    color="error"
                    @click="toggleDestroyCommentDialog(comment.id, comment.user.id)"
                    small>
                    削除
                  </v-btn>
                  <v-dialog v-model="showDestroyCommentDialog" width="500">
                    <v-card>
                      <v-card-title class="headline red white--text" primary-title>確認</v-card-title>
                      <v-card-text class="pt-5 grey--text text--darken-2">本当にこのコメントを削除してもよろしいですか？</v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" outlined @click="toggleDestroyCommentDialog">キャンセル</v-btn>
                        <v-btn class="red white--text" @click="requestDestroyComment">削除する</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-subheader>
                <p v-if="hasAttachments(comment.attachments)" class="ml-5 pl-5">
                  <img
                    @click="downloadFile(comment.attachments[0])"
                    class="comment_attachment_files clickable"
                    :src="fileSrc[comment.attachments[0].id]"
                    :ref="comment.attachments[0].id"/>
                  <v-subheader
                    v-if="fileName[comment.attachments[0].id]"
                    :ref="'comment_file_name' + comment.attachments[0].id"
                    class="pl-0">
                    {{ fileName[comment.attachments[0].id] }}
                  </v-subheader>
                </p>
                <p v-else-if="comment.stamp" class="ml-5 pl-5 comment_stamp">
                  <img
                    :src="require('../../../../assets/images/stamps/' + comment.stamp.slice(1) + '.png')"
                    :ref="comment.id"/>
                </p>
                <p v-else class="ml-5 pl-5">{{ comment.content }}</p>
              </div>
            </v-col>
          </v-card>
          <v-expansion-panels v-if="currentAdmin.isAdmin || currentAdmin.id == notification.user.id">
            <v-expansion-panel class="mb-5">
              <v-expansion-panel-header>未読者({{ notReadersCount }})</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <ul>
                    <li v-for="notReader of notReaders" :key="notReader.id" class="mb-3">{{ notReader.attributes.last_name + notReader.attributes.first_name }}</li>
                  </ul>
                </v-col>
                <v-col cols="12" class="text-center" v-if="notReadersCount > 0">
                  <v-btn color="warning" @click="toggleNotReadersDialog">
                    未読者に再度通知を送る
                  </v-btn>
                </v-col>
                <v-dialog v-model="showNotReadersDialog" width="500">
                  <v-card>
                    <v-card-title class="headline orange darken-1 white--text" primary-title>確認</v-card-title>
                    <v-card-text class="pt-5 grey--text text--darken-2">このお知らせの未読者へ通知を送ってよろしいですか？</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined @click="toggleNotReadersDialog">キャンセル</v-btn>
                      <v-btn class="orange darken-1 white--text" @click="requestNotify">通知を送る</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="notification.is_required_approval && notApprovingUsersCount > 0">
              <v-expansion-panel-header>未承認者({{ notApprovingUsersCount }})</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <ul>
                    <li v-for="notApprovingUser of notApprovingUsers" :key="notApprovingUser.id" class="mb-3">
                      {{ notApprovingUser.attributes.last_name + notApprovingUser.attributes.first_name }}
                    </li>
                  </ul>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <div class="text-center py-4 mt-3">
        <template v-if="isEditable">
          <template  v-if="!postStartDate && !postStartTime">
            <v-btn color="primary" @click="onSubmit(1)">
              {{ this.type === 'new' ? '即時公開' : '更新' }}
            </v-btn>
            <v-btn v-if="type === 'new'" color="warning" class="ml-5" @click="onSubmit(0)">
              下書き保存
            </v-btn>
          </template>
          <template v-else>
            <v-btn v-if="type === 'new'" color="warning" @click="onSubmit(0)">
              予約投稿
            </v-btn>
            <v-btn v-else color="primary" @click="onSubmit(0)">
              更新
            </v-btn>
          </template>
        </template>
        <template v-else>
          <template v-if="currentAdmin.isAdmin || currentAdmin.id == notification.user.id">
            <v-btn v-if="notification.status" color="warning" class="mr-5" @click="toggleChangeStatusDialog">
              お知らせを非公開にする
            </v-btn>
            <v-btn v-else color="primary" @click="toggleChangeStatusDialog">
              お知らせを公開する
            </v-btn>
          </template>
          <v-dialog v-model="showChangeStatusDialog" width="500">
            <v-card>
              <template v-if="notification.status">
                <v-card-title class="headline orange darken-1 white--text" primary-title>確認</v-card-title>
                <v-card-text class="pt-5 grey--text text--darken-2">このお知らせを下書き状態に戻して非公開にしてよろしいですか？</v-card-text>
              </template>
              <template v-else>
                <v-card-title class="headline primary white--text" primary-title>確認</v-card-title>
                <v-card-text class="pt-5 grey--text text--darken-2">このお知らせを公開してよろしいですか？</v-card-text>
              </template>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined @click="toggleChangeStatusDialog">キャンセル</v-btn>
                <v-btn v-if="notification.status" class="orange darken-1 white--text" @click="changeStatus(0)">非公開にする</v-btn>
                <v-btn v-else class="primary white--text" @click="changeStatus(1)">公開する</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'
import loadImage from 'blueimp-load-image'
import moment from 'moment'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as GroupsTypes } from '../../../store/modules/groups/types'
import { Types as NotificationsTypes } from '../../../store/modules/notifications/types'
import { Types as NotificationCommentsTypes } from '../../../store/modules/notificationComments/types'
import { Types as AdminTypes } from '../../../store/modules/admin/types'

// components
import ListPagenation from '../../molecules/ListPagenation'
import DestroyDialog from '../../molecules/DestroyDialog'

// helper
import { privateMediaApi } from '../../helper'

// mixins
import { ImageCompressible } from '../../mixins/ImageCompressible'

export default {
  name: 'NotificationsForm',
  mixins: [ ImageCompressible ],
  components: {
    ListPagenation,
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのお知らせを削除してもよろしいですか？'
      },
      notificationModel: {},
      pagenations: {},
      fileSrc: {},
      fileName: {},
      headers: [
        {
          text: 'グループ名',
          value: 'name'
        }
      ],
      originalSelectedGroups: [],
      selectedGroups: [],
      selectedGroupsId: [],
      groupsOptions: [],
      attachmentsAttributes: [],
      notReaders: [],
      notApprovingUsers: [],
      contributor: '',
      postStartDate: '',
      postStartTime: '',
      approveDeadlineDate: '',
      approveDeadlineTime: '',
      commentId: '',
      commentUserId: '',
      notReadersCount: '',
      notApprovingUsersCount: '',
      fileDescription1: '',
      fileDescription2: '',
      fileDescription3: '',
      post_date_menu: false,
      post_time_menu: false,
      approve_date_menu: false,
      approve_time_menu: false,
      isEditable: false,
      showGroupsSelectDialog: false,
      showNotReadersDialog: false,
      showDestroyCommentDialog: false,
      showChangeStatusDialog: false,
      postToAllGroups: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      currentAdmin: AdminTypes.getters.GET_ADMIN,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      groupsList: GroupsTypes.getters.GET_GROUPS_LIST,
      groupsListPagenation: GroupsTypes.getters.GET_GROUPS_LIST_PAGENATION,
      notification: NotificationsTypes.getters.GET_NOTIFICATIONS_DETAIL,
      notificationComments: NotificationCommentsTypes.getters.GET_NOTIFICATION_COMMENTS
    })
  },
  methods: {
    ...mapActions({
      apiGetGroups: GroupsTypes.actions.INDEX,
      apiGetNotification: NotificationsTypes.actions.SHOW,
      apiCreateNotification: NotificationsTypes.actions.CREATE,
      apiUpdateNotification: NotificationsTypes.actions.UPDATE,
      apiDestroyNotification: NotificationsTypes.actions.DESTROY,
      apiRestoreNotification: NotificationsTypes.actions.RESTORE,
      apiNotifyNotReaders: NotificationsTypes.actions.NOTIFY_NOT_READERS,
      resetNotification: NotificationsTypes.actions.RESET_DETAIL,
      apiGetNotificationComments: NotificationCommentsTypes.actions.INDEX,
      apiDestroyNotificationComment: NotificationCommentsTypes.actions.DESTROY
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    toggleDestroyCommentDialog: function (id, userId) {
      this.commentId = id
      this.commentUserId = userId
      this.showDestroyCommentDialog = !this.showDestroyCommentDialog
    },
    toggleGroupsSelectDialog: function () {
      if (!this.isEditable) return
      this.showGroupsSelectDialog = !this.showGroupsSelectDialog
    },
    toggleNotReadersDialog: function () {
      this.showNotReadersDialog = !this.showNotReadersDialog
    },
    toggleChangeStatusDialog: function () {
      this.showChangeStatusDialog = !this.showChangeStatusDialog
    },
    allowedDate: function (val) {
      let today = new Date()
      today = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      )
      return today <= new Date(val)
    },
    onSubmit: function (status) {
      if (this.postToAllGroups) {
        this.notificationModel.target = 1
      } else {
        this.notificationModel.target = 0
      }
      this.notificationModel.published_at = this.postStartDate + ' ' + this.postStartTime
      this.notificationModel.approving_deadline_at = this.approveDeadlineDate + ' ' + this.approveDeadlineTime
      this.notificationModel.group_ids = this.selectedGroupsId
      this.notificationModel.attachments_attributes = this.attachmentsAttributes
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          if (this.type === 'new') {
            this.notificationModel.status = status
          } else {
            this.notificationModel.status = this.notification.status
          }
          switch (this.type) {
            case 'new':
              this.requestCreate()
              break
            case 'edit':
              this.requestUpdate()
              break
          }
        }
      })
    },
    changeStatus: function (status) {
      if (!this.currentAdmin.isAdmin && this.currentAdmin.id != this.notification.user.id) return
      this.notificationModel.status = status
      this.requestUpdate()
      this.toggleEditable()
      this.toggleChangeStatusDialog()
    },
    allowedThirtyMinutesStep (m) {
      return m % 30 === 0
    },
    allowedFiveMinutesStep (m) {
      return m % 5 === 0
    },
    async requestDestroy () {
      if (!this.currentAdmin.isAdmin && this.currentAdmin.id != this.notification.user.id) return
      await this.apiDestroyNotification(this.notification.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexNotificaion' })
      }, 30)
    },
    async requestCreate () {
      await this.apiCreateNotification(this.notificationModel)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'showNotification', params: { id: this.notification.id } })
      }, 80)
    },
    async requestUpdate () {
      if (!this.currentAdmin.isAdmin && this.currentAdmin.id != this.notification.user.id) return
      await this.apiUpdateNotification(this.notificationModel)
      await this.apiGetNotification(this.$route.params.id)
      this.postStartDate = this.notification.published_at ? moment(this.notification.published_at).format('YYYY-MM-DD') : ''
      this.postStartTime = this.notification.published_at ? moment(this.notification.published_at).format('HH:mm') : ''
      this.approveDeadlineDate = this.notification.approving_deadline_at ? moment(this.notification.approving_deadline_at).format('YYYY-MM-DD') : ''
      this.approveDeadlineTime = this.notification.approving_deadline_at ? moment(this.notification.approving_deadline_at).format('HH:mm') : ''
      this.postStartTime = this.notification.published_at ? moment(this.notification.published_at).format('HH:mm') : ''
      for (let i = 1; i <= 3; i++) {
        const img = document.getElementById('preview_img_' + i)
        img.src = require('../../../../assets/images/no_image.png')
      }
      this.attachmentsAttributes = this.notification.attachments
      await this.getImageSrc(this.notification.attachments)
      this.toggleEditable()
    },
    async requestNotify () {
      if (!this.currentAdmin.isAdmin && this.currentAdmin.id != this.notification.user.id) return
      await this.apiNotifyNotReaders(this.notification.id)
      this.toggleNotReadersDialog()
    },
    async requestDestroyComment () {
      if (!this.currentAdmin.isAdmin && this.currentAdmin.id != this.commentUserId) return
      await this.apiDestroyNotificationComment({
        notificationId: this.notification.id,
        commentId: this.commentId
      })
      this.toggleDestroyCommentDialog()
      await this.apiGetNotificationComments(this.$route.params.id)
    },
    downloadFile (file) {
      if (file.url === null) return
      privateMediaApi.getPrivateMedia(file.url, (imageData) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(imageData)
        link.download = file.name
        link.click()
      })
    },
    hasAttachments (attachments) {
      if (attachments.length > 0) {
        const attachment = attachments[0]
        if (this.fileSrc[attachment.id]) return true
        let fileName, fileSrc
        if (attachment.url === null) {
          fileSrc = require('../../../../assets/images/expired_icon.jpeg')
          fileName = '期限切れファイル'
          if (!this.fileName[attachment.id] && fileName) this.fileName = { ...this.fileName, [attachment.id]: fileName }
          if (!this.fileSrc[attachment.id] && fileSrc) this.fileSrc = { ...this.fileSrc, [attachment.id]: fileSrc }
          return true
        } else {
          privateMediaApi.getPrivateMedia(attachment.url, (imageData) => {
            const pos = attachment.name.lastIndexOf('.')
            if (attachment.name.slice(pos + 1) === 'pdf') {
              fileName = attachment.name
              fileSrc = require('../../../../assets/images/pdf_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'xls' ||
              attachment.name.slice(pos + 1) === 'xlsx'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../../assets/images/excel_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'ppt' ||
              attachment.name.slice(pos + 1) === 'pptx'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../../assets/images/ppt_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'doc' ||
              attachment.name.slice(pos + 1) === 'docx'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../../assets/images/docs_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'mov' ||
              attachment.name.slice(pos + 1) === 'MOV' ||
              attachment.name.slice(pos + 1) === 'mp4'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../../assets/images/movie_icon.jpeg')
            } else {
              fileSrc = window.URL.createObjectURL(imageData)
            }
            if (!this.fileName[attachment.id] && fileName) this.fileName = { ...this.fileName, [attachment.id]: fileName }
            if (!this.fileSrc[attachment.id] && fileSrc) this.fileSrc = { ...this.fileSrc, [attachment.id]: fileSrc }
          })
        }
        return true
      } else {
        return false
      }
    },
    async onImageChange (index, e) {
      const images = e.target.files || e.dataTransfer.files
      if (!images) {
        return
      }
      this.getBase64(images[0]).then(image => {
        if (this.type === 'new' || !this.attachmentsAttributes[index - 1]) {
          this.attachmentsAttributes[index - 1] = {
            file: image,
            name: images[0].name
          }
        } else {
          this.attachmentsAttributes[index - 1] = {
            id: this.attachmentsAttributes[index - 1].id,
            file: image,
            name: images[0].name
          }
        }
      })
      loadImage.parseMetaData(images[0], (data) => {
        const options = {
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation')
        }
        loadImage(images[0], (canvas) => {
          const pos = images[0].name.lastIndexOf('.')
          const img = document.getElementById('preview_img_' + index)
          const fileName = document.getElementById('file_name_' + index)
          if (images[0].name.slice(pos + 1) === 'pdf') {
            img.src = require('../../../../assets/images/pdf_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'xls' ||
            images[0].name.slice(pos + 1) === 'xlsx'
          ) {
            img.src = require('../../../../assets/images/excel_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'ppt' ||
            images[0].name.slice(pos + 1) === 'pptx'
          ) {
            img.src = require('../../../../assets/images/ppt_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'doc' ||
            images[0].name.slice(pos + 1) === 'docx'
          ) {
            img.src = require('../../../../assets/images/docs_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'mov' ||
            images[0].name.slice(pos + 1) === 'mp4'
          ) {
            img.src = require('../../../../assets/images/movie_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else {
            const data = canvas.toDataURL(images[0].type)
            const blob = this.base64ToBlob(data, images[0].type)
            const originUrl = window.URL.createObjectURL(blob)
            const newimg = new Image()
            newimg.src = originUrl
            newimg.onload = () => {
              const resizedCanvas = this.createResizedCanvasElement(newimg)
              const resizedBase64 = resizedCanvas.toDataURL(images[0].type)
              const resizedBlob = this.base64ToBlob(resizedBase64, images[0].type)
              const url = window.URL.createObjectURL(resizedBlob)
              img.src = url
              fileName.innerHTML = ''
              if (this.type === 'new' || !this.attachmentsAttributes[index - 1]) {
                    this.attachmentsAttributes[index - 1] = {
                    file: resizedBase64,
                    name: images[0].name
                  }
                } else {
                  this.attachmentsAttributes[index - 1] = {
                    id: this.attachmentsAttributes[index - 1].id,
                    file: resizedBase64,
                    name: images[0].name
                  }
              }
            }
          }
        }, options)
      })
    },
    clearImage: function (index) {
      if (!this.attachmentsAttributes[index - 1]) return
      const img = document.getElementById('preview_img_' + index)
      const fileName = document.getElementById('file_name_' + index)
      img.src = require('../../../../assets/images/file_sample.jpeg')
      this.attachmentsAttributes[index - 1] = {
        id: this.attachmentsAttributes[index - 1].id,
        _destroy: 1
      }
      fileName.innerHTML = ''
    },
    focusFileInput: function (refsName) {
      this.$refs[refsName][0].click()
    },
    getImageSrc: function (attachments) {
      for (let i = 1; i <= attachments.length; i++) {
        const img = document.getElementById('preview_img_' + i)
        const pos = attachments[i - 1].name.lastIndexOf('.')
        const fileName = document.getElementById('file_name_' + i)
        if (attachments[i - 1].url === null) {
          img.src = require('../../../../assets/images/expired_icon.jpeg')
          img.classList.remove('clickable')
          fileName.innerHTML = '期限切れファイル'
        } else {
          privateMediaApi.getPrivateMedia(attachments[i - 1].url, (imageData) => {
            if (attachments[i - 1].name.slice(pos + 1) === 'pdf') {
              img.src = require('../../../../assets/images/pdf_icon.jpeg')
              fileName.innerHTML = attachments[i - 1].name
            } else if (
              attachments[i - 1].name.slice(pos + 1) === 'xls' ||
              attachments[i - 1].name.slice(pos + 1) === 'xlsx'
            ) {
              img.src = require('../../../../assets/images/excel_icon.jpeg')
              fileName.innerHTML = attachments[i - 1].name
            } else if (
              attachments[i - 1].name.slice(pos + 1) === 'ppt' ||
              attachments[i - 1].name.slice(pos + 1) === 'pptx'
            ) {
              img.src = require('../../../../assets/images/ppt_icon.jpeg')
              fileName.innerHTML = attachments[i - 1].name
            } else if (
              attachments[i - 1].name.slice(pos + 1) === 'doc' ||
              attachments[i - 1].name.slice(pos + 1) === 'docx'
            ) {
              img.src = require('../../../../assets/images/docs_icon.jpeg')
              fileName.innerHTML = attachments[i - 1].name
            } else if (
              attachments[i - 1].name.slice(pos + 1) === 'mov' ||
              attachments[i - 1].name.slice(pos + 1) === 'mp4'
            ) {
              img.src = require('../../../../assets/images/movie_icon.jpeg')
              fileName.innerHTML = attachments[i - 1].name
            } else {
              img.src = window.URL.createObjectURL(imageData)
            }
          })
        }
      }
    },
    changeSelectedGroups: function (id, name) {
      if (!this.selectedGroupsId.includes(id)) {
        this.selectedGroups = this.selectedGroups.filter(group => group.id !== id)
      } else {
        this.selectedGroups.push({
          id: id,
          text: name
        })
      }
      this.originalSelectedGroups = this.selectedGroups
    },
    setGroupsOptions: function () {
      this.selectedGroups = this.notification.groups.map(group => {
        return {
          id: group.id,
          text: group.name
        }
      })
      this.selectedGroupsId = this.selectedGroups.map(group => {
        return group.id
      })
      this.originalSelectedGroups = this.selectedGroups
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetGroups({
        page: page
      })
    }
  },
  async created () {
    await this.apiGetGroups()
    for (let i = 1; i <= 3; i++) {
      const img = document.getElementById('preview_img_' + i)
      img.src = require('../../../../assets/images/no_image.png')
    }
    if (this.$route.params.id) {
      await this.apiGetNotificationComments(this.$route.params.id)
      await this.apiGetNotification(this.$route.params.id)
      this.notificationModel = this.notification
      this.attachmentsAttributes = this.notification.attachments
      await this.getImageSrc(this.notificationModel.attachments)
      if (this.groupsList.length) this.setGroupsOptions()
      this.contributor = this.notification.user.attributes.last_name + this.notification.user.attributes.first_name
      this.postStartDate = this.notificationModel.published_at ? moment(this.notificationModel.published_at).format('YYYY-MM-DD') : ''
      this.postStartTime = this.notificationModel.published_at ? moment(this.notificationModel.published_at).format('HH:mm') : ''
      this.approveDeadlineDate = this.notificationModel.approving_deadline_at ? moment(this.notificationModel.approving_deadline_at).format('YYYY-MM-DD') : ''
      this.approveDeadlineTime = this.notificationModel.approving_deadline_at ? moment(this.notificationModel.approving_deadline_at).format('HH:mm') : ''
      this.notReaders = this.notification.not_readers.data
      this.notReadersCount = this.notification.not_readers.data.length
      if (this.notification.not_approving_users) {
        this.notApprovingUsers = this.notification.not_approving_users.data
        this.notApprovingUsersCount = this.notification.not_approving_users.data.length
      }
    } else {
      this.isEditable = !this.isEditable
    }
  },
  watch: {
    postToAllGroups () {
      if (this.postToAllGroups) {
        this.selectedGroups = [{
          id: 0,
          text: '全グループ'
        }]
      } else {
        this.selectedGroups = this.originalSelectedGroups
      }
    }
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : ''
    }
  }
}
</script>

<style scoped>
.avatar-area .v-card{
  border: none !important;
  overflow: hidden;
}
.avatar-area .v-card .v-avatar img{
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
.group-dialog .v-card__actions{
  display: block !important;
}
ul{
  list-style-type: none
}
.comment_stamp img{
  width: 130px;
}
.comment_attachment_files{
  width: 70px;
}
</style>
