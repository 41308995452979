import http from '../../../packs/http'
import {
  removeNamespace
} from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'

const API_PATH = 'readers/'
function apiPathWithPathParam (notificationId, id = '') {
  return 'notifications/' + notificationId + '/' + API_PATH
}
const _types = removeNamespace('notificationReaders/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null
  }
}

const state = Object.assign({}, _initialState)

const getters = {}

const mutations = {}

const actions = {
  async [_types.actions.READ] ({ commit, state }, notificationId) {
    await http.post(apiPathWithPathParam(notificationId)).then(response => {
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.APPROVE] ({ commit }, payload) {
    const params = JSON.stringify({
      notification_reader: {
        is_approved: payload.is_approved
      }
    })
    await http.put(apiPathWithPathParam(payload.id), params).then(response => {
      setTimeout(() => {
        commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'お知らせを承認しました。', { root: true })
      }, 50)
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
