<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn left class="ma-3" outlined rounded color="indigo" @click="requestExport"><v-icon>mdi-download</v-icon>CSV出力</v-btn>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.text">
              <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
                <v-col cols="6" class="d-flex justify-center align-center">
                  {{ header.text }}
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-row>
                    <v-col cols="12" class="pa-0 d-flex align-end">
                      <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex align-start">
                      <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  {{ header.text }}
                </v-col>
              </v-row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="exchange in incentiveExchangesList" :key="exchange.id" class="clickable" @click="onClickTr(exchange.id)">
            <td>
              <template v-if="exchange.incentive === null">
                [ このインセンティブは削除されました ]
              </template>
              <template v-else>
                {{ exchange.incentive.attributes.name | truncate(20) }}
              </template>
            </td>
            <td>
              <template v-if="exchange.user === null">
                [ このユーザーは削除されました ]
              </template>
              <template v-else>
                {{ exchange.user.attributes.last_name + exchange.user.attributes.first_name | truncate(20) }}
              </template>
            </td>
            <td>{{ exchange.created_at | moment }}</td>
            <td>{{ exchange.completed_at | moment }}</td>
            <td>
              <v-chip v-if="exchange.status === 0" color="warning" outlined>
                申請済み
              </v-chip>
              <v-chip v-else-if="exchange.status === 1" color="primary" outlined>
                申請受理
              </v-chip>
              <v-chip v-else-if="exchange.status === 2" color="success" outlined>
                交換済み
              </v-chip>
              <v-chip v-else-if="exchange.status === 3" color="error" outlined>
                管理者キャンセル
              </v-chip>
              <v-chip v-else color="error" outlined>
                ユーザーキャンセル
              </v-chip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="incentiveExchangesListPagenation.pages" :current="incentiveExchangesListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as IncentiveExchangesTypes } from '../../../store/modules/incentiveExchanges/types'

// helpers
import { donwloadByVendor } from '../../helper'

// components
import ListPagenation from '../../molecules/ListPagenation'

export default {
  name: 'IncentiveExchangesListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: 'インセンティブ名',
          sortable: false,
          value: 'name'
        },
        {
          text: '交換者',
          sortable: true,
          value: 'user_id'
        },
        {
          text: '交換申請日',
          sortable: true,
          value: 'created_at'
        },
        {
          text: '交換完了日',
          sortable: true,
          value: 'completed_at'
        },
        {
          text: 'ステータス',
          sortable: false,
          value: 'status'
        }
      ],
      images: {},
      pagenations: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      incentiveExchangesList: IncentiveExchangesTypes.getters.GET_INCENTIVE_EXCHANGES_LIST,
      incentiveExchangesListPagenation: IncentiveExchangesTypes.getters.GET_INCENTIVE_EXCHANGES_LIST_PAGENATION,
      incentiveExchangesExport: IncentiveExchangesTypes.getters.GET_INCENTIVE_EXCHANGES_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetIncentiveExchanges: IncentiveExchangesTypes.actions.INDEX,
      apiExportIncentiveExchanges: IncentiveExchangesTypes.actions.EXPORT
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showIncentiveExchange', params: { id: id } })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetIncentiveExchanges({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    changePage (page) {
      this.pagenations.current = page
      this.apiGetIncentiveExchanges({
        page: page,
        sort: this.currentSortCategory ? this.currentSortCategory + ' ' + this.sortOrder : ''
      })
    },
    async requestExport () {
      await this.apiExportIncentiveExchanges()
      if (this.httpSuccess) {
        donwloadByVendor(this.incentiveExchangesExport.data, this.incentiveExchangesExport.fileName)
      }
    }
  },
  async created () {
    await this.apiGetIncentiveExchanges()
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
