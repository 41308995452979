<template>
  <v-card class="pa-5">
    <label class="font-weight-bold">通報履歴検索</label>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="params.status"
            :items="statusOptions"
            label="ステータス"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-end">
        <v-btn type="submit" class="ma-3" color="indigo" left outlined rounded><v-icon>mdi-magnify</v-icon>検索</v-btn>
      </v-row>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapActions } from 'vuex'

// storeTyopes
import { Types as MessageReportsTypes } from '../../../store/modules/messageReports/types'

export default {
  name: 'MessageReportsSearchForm',
  data () {
    return {
      params: {
        // 明示的に検索時にはページ1が指定されるように
        page: 1,
        status: ''
      },
      statusOptions: [
        { text: '指定なし', value: '' },
        { text: '未対応', value: 0 },
        { text: '対応中', value: 1 },
        { text: '対応完了', value: 2 }
      ]
    }
  },
  methods: {
    ...mapActions({
      apiGetMessageReports: MessageReportsTypes.actions.INDEX
    }),
    onSubmit: function () {
      this.apiGetMessageReports(this.params)
    }
  }
}
</script>
