<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row class="d-flex justify-start">
        <v-col cols="12" class="avatar-area ml-5">
          <p class="text-left grey--text">アイコン画像<span class="ml-2 red--text text--accent-2">必須</span></p>
          <input
            type="file"
            class="d-none"
            accept="image/jpeg,image/png,image/gif"
            ref="fileInput"
            :disabled="!isEditable"
            @change="onImageChange">
          <v-hover v-slot:default="{ hover }" :disabled="!isEditable">
            <v-card
              :elevation="hover ? 24 : 8"
              :class="'d-inline-block mx-auto' + { ' on-hover': hover }">
              <v-avatar
                size="164"
                :class="isEditable ? 'clickable' : ''">
                <img
                  id="preview_img"
                  :src="imageSrc"
                  @click="focusFileInput">
              </v-avatar>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="coinModel.enabled"
            :disabled="!isEditable"
            label="有効化">
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="coinModel.name"
            name="coin[name]"
            v-validate="'required'"
            data-vv-as="コイン名"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('coin[name]')"
            :error-messages="errors.collect('coin[name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              コイン名<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="coinModel.default_point_number"
            name="coin[default_point]"
            v-validate="'required|numeric|min_value:1'"
            data-vv-as="デフォルトポイント数"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('coin[default_point]')"
            :error-messages="errors.collect('coin[default_point]')"
            :disabled="!isEditable">
            <template v-slot:label>
              デフォルトポイント数<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">
          {{ this.type === 'new' ? '新規登録' : '更新' }}
        </v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'
import loadImage from 'blueimp-load-image'
import objectFitImages from 'object-fit-images'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as CoinsTypes } from '../../../store/modules/coins/types'

// components
import DestroyDialog from '../../molecules/DestroyDialog'

// helper
import { privateMediaApi } from '../../helper'

// mixins
import { ImageCompressible } from '../../mixins/ImageCompressible'

export default {
  name: 'CoinsForm',
  mixins: [ ImageCompressible ],
  components: {
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのコインを削除してもよろしいですか？'
      },
      coinModel: {},
      imageSrc: '',
      isEditable: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      coin: CoinsTypes.getters.GET_COINS_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetCoin: CoinsTypes.actions.SHOW,
      apiCreateCoin: CoinsTypes.actions.CREATE,
      apiUpdateCoin: CoinsTypes.actions.UPDATE,
      apiDestroyCoin: CoinsTypes.actions.DESTROY,
      apiRestoreCoin: CoinsTypes.actions.RESTORE,
      resetCoin: CoinsTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetCoin()
      this.coinModel = this.coin
      this.getImageSrc(this.coin.icon.url)
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    isNumber: function (value) {
      return ((typeof value === 'number') && (isFinite(value)))
    },
    onSubmit: function () {
      const defaultPoint = this.coinModel.default_point_number
      if (defaultPoint && this.isNumber(defaultPoint)) {
        this.coinModel.default_point_number = Number(defaultPoint)
      }
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          switch (this.type) {
            case 'new':
              this.requestCreate()
              break
            case 'edit':
              this.requestUpdate()
              break
          }
        }
      })
    },
    async requestDestroy () {
      await this.apiDestroyCoin(this.coin.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexCoin' })
      }, 30)
    },
    async requestCreate () {
      await this.apiCreateCoin(this.coinModel)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'showCoin', params: { id: this.coin.id } })
      }, 30)
    },
    async requestUpdate () {
      await this.apiUpdateCoin(this.coinModel)
      setTimeout(() => {
        if (this.httpSuccess) this.toggleEditable()
      }, 30)
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    onImageChange (e) {
      const agent = window.navigator.userAgent.toLowerCase()
      const ie11 = (agent.indexOf('trident/7') !== -1)
      const previewImg = document.getElementById('preview_img')
      const images = e.target.files || e.dataTransfer.files
      if (!images) {
        return
      }
      this.getBase64(images[0]).then(image => {
        this.coinModel.icon = image
      })
      loadImage.parseMetaData(images[0], (data) => {
        const options = {
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation')
        }
        loadImage(images[0], (canvas) => {
          const data = canvas.toDataURL(images[0].type)
          const blob = this.base64ToBlob(data, images[0].type)
          const originUrl = window.URL.createObjectURL(blob)
          const newimg = new Image()
          newimg.src = originUrl
          newimg.onload = () => {
            const resizedCanvas = this.createResizedCanvasElement(newimg)
            const resizedBase64 = resizedCanvas.toDataURL(images[0].type)
            const resizedBlob = this.base64ToBlob(resizedBase64, images[0].type)
            const url = window.URL.createObjectURL(resizedBlob)
            this.imageSrc = url
            this.coinModel.icon = resizedBase64
          }
          // IE11はimgタグのbackground-imageも更新
          if (ie11) previewImg.style.backgroundImage = 'url(' + url + ')'
        }, options)
      })
    },
    focusFileInput: function () {
      this.$refs.fileInput.click()
    },
    getImageSrc: function (url) {
      if (!url) {
        this.imageSrc = require('../../../../assets/images/no_image.png')
        return
      }
      privateMediaApi.getPrivateMedia(url, (imageData) => {
        this.imageSrc = window.URL.createObjectURL(imageData)
      })
    }
  },
  async created () {
    await objectFitImages()
    if (this.$route.params.id) {
      await this.apiGetCoin(this.$route.params.id)
      this.coinModel = this.coin
      await this.getImageSrc(this.coin.icon.url)
    } else {
      this.isEditable = !this.isEditable
      this.imageSrc = require('../../../../assets/images/no_image.png')
    }
  }
}
</script>

<style scoped>
.avatar-area .v-card{
  overflow: hidden;
}
.avatar-area .v-card .v-avatar img{
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
</style>
