<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ポイント付与イベント詳細"/>
      </v-col>
      <v-col cols="12">
        <EventsForm type="edit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import EventsForm from '../../organisms/events/EventsForm'

export default {
  components: {
    PageHeading,
    EventsForm
  }
}
</script>
