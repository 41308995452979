<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn fab top right small color="primary" class="ma-3" @click="onClickNewDepartment"><v-icon>mdi-plus</v-icon></v-btn>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.text">
              <v-row v-if="header.sortable">
                <v-col cols="6" class="d-flex justify-center align-center">
                  {{ header.text }}
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-row>
                    <v-col cols="12" class="pa-0 d-flex align-end">
                      <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex align-start">
                      <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  {{ header.text }}
                </v-col>
              </v-row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="department in departmentsList" :key="department.id" class="clickable" @click="onClickTr(department.id)">
            <td>{{ department.name | truncate(8) }}</td>
            <td>{{ department.users.length }} 人</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as DepartmentsTypes } from '../../../store/modules/departments/types'

export default {
  name: 'DepartmentsListTable',
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: '部署名',
          sortable: false,
          value: 'name'
        },
        {
          text: '所属ユーザー数',
          sortable: false,
          value: 'users_count'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      departmentsList: DepartmentsTypes.getters.GET_DEPARTMENTS_LIST,
    })
  },
  methods: {
    ...mapActions({
      apiGetDepartments: DepartmentsTypes.actions.INDEX
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showDepartment', params: { id: id } })
    },
    onClickNewDepartment: function () {
      this.$router.push({ name: 'newDepartment' })
    }
  },
  async created () {
    await this.apiGetDepartments()
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
