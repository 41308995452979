<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="created_at" label="日時" disabled filled></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="fromUser" label="贈呈者" disabled filled></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="toUser" label="受贈者" disabled filled></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="selectedCoin" label="贈呈コイン名" disabled filled></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field v-model="present.point_number" label="贈呈ポイント数" disabled filled></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="present.comment" label="コメント" disabled filled></v-text-field>
        </v-col>
      </v-row>
    </form>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as PresentsTypes } from '../../../store/modules/presents/types'

// components
import DestroyDialog from '../../molecules/DestroyDialog'

export default {
  name: 'PresentsForm',
  components: {
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのコイン贈呈履歴を削除してもよろしいですか？'
      },
      fromUser: '',
      toUser: '',
      selectedCoin: '',
      created_at: '',
      isEditable: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      present: PresentsTypes.getters.GET_PRESENTS_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetPresent: PresentsTypes.actions.SHOW,
      apiDestroyPresent: PresentsTypes.actions.DESTROY,
      resetPresent: PresentsTypes.actions.RESET_DETAIL
    }),
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    async requestDestroy () {
      await this.apiDestroyPresent(this.present.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexPresent' })
      }, 30)
    }
  },
  async created () {
    await this.apiGetPresent(this.$route.params.id)
    this.fromUser = this.present.from ? this.present.from.attributes.last_name + this.present.from.attributes.first_name : '[ このユーザーは削除されました ]'
    this.toUser = this.present.to ? this.present.to.attributes.last_name + this.present.to.attributes.first_name : '[ このユーザーは削除されました ]'
    this.created_at = moment(this.present.created_at).format('YYYY/MM/DD')
    this.selectedCoin = this.present.coin ? this.present.coin.attributes.name : '[ このコインは削除されました ]'
  }
}
</script>
