<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn fab top right small color="primary" class="ma-3" @click="onClickNewHelpCategory"><v-icon>mdi-plus</v-icon></v-btn>
    </v-row>
    <v-simple-table>
      <thead>
        <tr>
          <th v-for="header in headers" :key="header.text">
            <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
              <v-col cols="6" class="d-flex justify-center align-center">
                {{ header.text }}
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <v-row>
                  <v-col cols="12" class="pa-0 d-flex align-end">
                    <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                  </v-col>
                  <v-col cols="12" class="pa-0 d-flex align-start">
                    <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                {{ header.text }}
              </v-col>
            </v-row>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="helpCategory in helpCategoriesList" :key="helpCategory.id" class="clickable" @click="onClickTr(helpCategory.id)">
          <td>{{ helpCategory.id }}</td>
          <td>{{ helpCategory.name }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as HelpCategoriesTypes } from '../../../store/modules/helpCategories/types'

// components

// helper

export default {
  name: 'HelpCategoriesListTable',
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'id'
        },
        {
          text: 'カテゴリ名',
          sortable: false,
          value: 'name'
        }
      ],
      images: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      helpCategoriesList: HelpCategoriesTypes.getters.GET_HELP_CATEGORIES_LIST,
      helpCategoriesListPagenation: HelpCategoriesTypes.getters.GET_HELP_CATEGORIES_LIST_PAGENATION
    })
  },
  methods: {
    ...mapActions({
      apiGetHelpCategories: HelpCategoriesTypes.actions.INDEX
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showHelpCategory', params: { id: id } })
    },
    onClickNewHelpCategory: function () {
      this.$router.push({ name: 'newHelpCategory' })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetHelpCategories({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    }
  },
  async created () {
    await this.apiGetHelpCategories()
  }
}
</script>
