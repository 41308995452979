<template>
  <v-card class="pa-5">
    <v-row v-for="(notification, index) in notificationsList" :key="notification.id" class="mb-5">
      <v-col cols="12" class="d-flex justify-center pt-0 pb-5 mb-5">
        <v-expansion-panels
          v-model="openedPanels[index]"
          popout
          flat>
          <v-expansion-panel>
            <v-expansion-panel-header @click="getFilesAndComments(notification, index)">
              <v-row class="mb-0">
                <v-col cols="6" sm="2" class="d-flex justify-center align-center pb-2">
                  <v-avatar
                    :id="'image_' + notification.id"
                    size="120">
                    <img />
                  </v-avatar>
                </v-col>
                <v-col cols="6" sm="10" class="pb-2">
                  <p v-if="notification.is_required_read">
                    <v-chip color="warning" outlined>
                      必読
                    </v-chip>
                  </p>
                  <v-subheader class="mb-4">
                    <p class="headline font-weight-bold">
                      {{ notification.title }}
                      <template v-if="notification.is_required_read">
                        <v-chip
                          v-if="notification.reader_ids.indexOf(currentAdmin.id) === -1 && notification.user.id != currentAdmin.id"
                          class="ml-3 font-weight-bold"
                          color="error"
                          text-color="white">
                          未読
                        </v-chip>
                      </template>
                      <template v-if="notification.is_required_approval">
                        <v-chip
                          v-if="notification.approving_users_ids.indexOf(currentAdmin.id) === -1 && notification.user.id != currentAdmin.id"
                          class="ml-3 font-weight-bold"
                          color="error"
                          text-color="white">
                          未承認
                        </v-chip>
                        <v-chip
                          v-if="notification.approving_users_ids.indexOf(currentAdmin.id) !== -1"
                          class="ml-3 font-weight-bold"
                          color="success"
                          text-color="white">
                          承認済
                        </v-chip>
                      </template>
                    </p>
                  </v-subheader>
                  <p>{{ notification.published_at | moment }}</p>
                  <p><span class="mr-3">投稿者：</span>{{ notification.user.attributes.last_name + notification.user.attributes.first_name }}</p>
                  <p>
                    <span class="mr-3">To：</span>
                    <template v-for="(group, index) in notification.groups">
                      <span v-if="index <= 2" :key="index">
                        {{ group.attributes.name }}&emsp;
                      </span>
                    </template>
                    <v-tooltip v-if="notification.groups.length >= 4" top>
                      <template v-slot:activator="{ on }">
                        <v-chip class="ml-3" v-on="on">
                          その他{{ notification.groups.length - 3 }}グループ
                        </v-chip>
                      </template>
                      <template v-for="(group, index) in notification.groups">
                        <p  v-if="index >= 3" :key="index" class="my-2">
                          <span>
                            {{ group.attributes.name }}
                          </span>
                        </p>
                      </template>
                    </v-tooltip>
                  </p>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="openedNotification.includes(notification.id)">
              <v-row>
                <v-col cols="12">
                  <p>{{ notification.content }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-for="index of 3" :key="index" cols="12" sm="4">
                  <template v-if="notification.attachments[index - 1]">
                    <img
                      @click="downloadFile(notification.attachments[index - 1])"
                      class="attachment_files clickable"
                      :id="'preview_image_' + notification.attachments[index - 1].id"/>
                    <v-subheader :id="'file_name_' + notification.attachments[index - 1].id"></v-subheader>
                  </template>
                </v-col>
              </v-row>
              <v-row v-if="notification.is_required_approval">
                <v-col cols="12" class="d-flex justify-center mt-3 pb-0">
                  <v-btn
                    color="success"
                    @click="toggleApprovalDialog"
                    :disabled="!checkIfCurrentUserIsInNotApprovers(notification)"
                    x-large
                    rounded>
                    {{ notification.approval_confirmation }}
                  </v-btn>
                </v-col>
                <v-col v-if="notification.approving_deadline_at" cols="12" class="d-flex justify-center mb-3">
                  <v-subheader>承認期限： {{ notification.approving_deadline_at | moment }}</v-subheader>
                </v-col>
                <v-dialog v-model="showApprovalDialog" width="500">
                  <v-card>
                    <v-card-title class="headline success white--text" primary-title>確認</v-card-title>
                    <v-card-text class="pt-5 grey--text text--darken-2">{{ notification.approval_confirmation }}</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined @click="toggleApprovalDialog">いいえ</v-btn>
                      <v-btn class="success white--text" @click="requestApproveNotification">はい</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-subheader>コメント({{ notificationComments.length }})</v-subheader>
                  <div class="comment_area pa-4 overflow-y-auto mb-5" style="max-height: 500px">
                    <v-col cols="12">
                      <div v-for="comment in notificationComments" :key="comment.id" class="mb-5">
                        <v-subheader :class="currentAdmin.id == comment.user.id ? 'font-weight-bold' :''">
                          {{ comment.user.attributes.last_name + comment.user.attributes.first_name }} {{ comment.created_at | moment }}
                          <v-btn
                            v-if="currentAdmin.id != comment.user.id"
                            class="ml-3"
                            color="error"
                            @click="toggleReportCommentDialog"
                            small>
                            通報
                          </v-btn>
                          <v-dialog v-model="showReportCommentDialog" width="500">
                            <v-card>
                              <v-card-title class="headline red white--text" primary-title>確認</v-card-title>
                              <v-card-text class="pt-5 grey--text text--darken-2">
                                通報理由を記入して「通報する」ボタンをクリックしてください。
                                <v-container>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-textarea
                                        v-model="report_content"
                                        label="通報理由"
                                        name="notificationComment[report_content]"
                                        v-validate="'required'"
                                        data-vv-as="通報理由"
                                        :customValidation="validated"
                                        :isValid="!errors.has('notificationComment[report_content]')"
                                        :error-messages="errors.collect('notificationComment[report_content]')"
                                        required>
                                      </v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" outlined @click="toggleReportCommentDialog">キャンセル</v-btn>
                                <v-btn class="red white--text" @click="requestReportComment(comment.id)">通報する</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-subheader>
                        <p v-if="hasAttachments(comment.attachments)" class="ml-5 pl-5">
                          <img
                            @click="downloadFile(comment.attachments[0])"
                            class="comment_attachment_files clickable"
                            :src="fileSrc[comment.attachments[0].id]"
                            :ref="comment.attachments[0].id"/>
                          <v-subheader
                            v-if="fileName[comment.attachments[0].id]"
                            :ref="'comment_file_name' + comment.attachments[0].id"
                            class="pl-0">
                            {{ fileName[comment.attachments[0].id] }}
                          </v-subheader>
                        </p>
                        <p v-else-if="comment.stamp" class="ml-5 pl-5 comment_stamp">
                          <img
                            :src="require('../../../assets/images/stamps/' + comment.stamp.slice(1) + '.png')"
                            :ref="comment.id"/>
                        </p>
                        <p v-else class="ml-5 pl-5">{{ comment.content }}</p>
                      </div>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="comment"
                    label="コメント">
                    <template slot="append-outer">
                      <v-icon @click="toggleStampDialog" large>mdi-emoticon</v-icon>
                      <v-icon @click="focusFileInput('fileInput_1')" large>mdi-file-plus-outline</v-icon>
                    </template>
                  </v-text-field>
                  <input
                    type="file"
                    class="d-none"
                    accept="
                      image/jpeg,
                      image/png,
                      image/gif,
                      video/mp4,
                      video/quicktime,
                      application/pdf,
                      application/vnd.ms-excel,
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/vnd.ms-powerpoint,
                      application/vnd.openxmlformats-officedocument.presentationml.presentation,
                      application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document
                    "
                    ref="fileInput_1"
                    @change="onImageChange">
                  <v-dialog v-model="showStampDialog" width="800">
                    <v-card>
                      <v-card-title class="headline primary white--text" primary-title>スタンプ選択</v-card-title>
                      <v-card-text class="pt-5 grey--text text--darken-2">
                        <v-row class="stamps_area">
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/A1.png" class="clickable" @click="setPostingStamp('A1')"/>
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/A2.png" class="clickable" @click="setPostingStamp('A2')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/A3.png" class="clickable" @click="setPostingStamp('A3')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/A4.png" class="clickable" @click="setPostingStamp('A4')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/A5.png" class="clickable" @click="setPostingStamp('A5')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/A6.png" class="clickable" @click="setPostingStamp('A6')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/A7.png" class="clickable" @click="setPostingStamp('A7')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/B1.png" class="clickable" @click="setPostingStamp('B1')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/B2.png" class="clickable" @click="setPostingStamp('B2')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/B3.png" class="clickable" @click="setPostingStamp('B3')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/B4.png" class="clickable" @click="setPostingStamp('B4')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/B8.png" class="clickable" @click="setPostingStamp('B8')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/C1.png" class="clickable" @click="setPostingStamp('C1')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/C2.png" class="clickable" @click="setPostingStamp('C2')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/C3.png" class="clickable" @click="setPostingStamp('C3')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/C7.png" class="clickable" @click="setPostingStamp('C7')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/C8.png" class="clickable" @click="setPostingStamp('C8')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/C10.png" class="clickable" @click="setPostingStamp('C10')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/C11.png" class="clickable" @click="setPostingStamp('C11')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/d1.png" class="clickable" @click="setPostingStamp('d1')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/itterasyai.png" class="clickable" @click="setPostingStamp('itterasyai')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/kaigi.png" class="clickable" @click="setPostingStamp('kaigi')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/karou.png" class="clickable" @click="setPostingStamp('karou')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/kyukanbi.png" class="clickable" @click="setPostingStamp('kyukanbi')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/oetsu.png" class="clickable" @click="setPostingStamp('oetsu')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/ohayo.png" class="clickable" @click="setPostingStamp('ohayo')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/oishi.png" class="clickable" @click="setPostingStamp('oishi')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/omedeto.png" class="clickable" @click="setPostingStamp('omedeto')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/onegai.png" class="clickable" @click="setPostingStamp('onegai')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/oyasumi.png" class="clickable" @click="setPostingStamp('oyasumi')" />
                          </v-col>
                          <v-col cols="6" sm="4" class="d-flex justify-center align-center">
                            <img src="../../../assets/images/stamps/snep.png" class="clickable" @click="setPostingStamp('snep')" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" outlined @click="toggleStampDialog">キャンセル</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    v-if="isPreview"
                    color="grey"
                    class="mb-3 d-block"
                    @click="clearFileAndStamp()"
                    dark
                    small>
                    <v-icon small>mdi-close</v-icon>削除
                  </v-btn>
                  <img
                    :id="'image_preview_area_' + notification.id"
                    style="width: 200px;">
                  <v-subheader :id="'post_file_name_' + notification.id"></v-subheader>
                </v-col>
                <v-col cols="12" class="text-center py-3">
                  <v-btn color="primary" @click="toggleCommentDialog" x-large>
                    コメントを投稿する
                  </v-btn>
                </v-col>
                <v-dialog v-model="showCommentDialog" width="500">
                  <v-card>
                    <v-card-title class="headline primary white--text" primary-title>確認</v-card-title>
                    <v-card-text class="pt-5 grey--text text--darken-2">コメントを投稿してよろしいですか？</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined @click="toggleCommentDialog">キャンセル</v-btn>
                      <v-btn class="primary white--text" @click="requestPostComment">投稿する</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="notificationsListPagenation.pages" :current="notificationsListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import loadImage from 'blueimp-load-image'
import objectFitImages from 'object-fit-images'

// storeTyopes
import { Types as GlobalTypes } from '../../store/types'
import { Types as AdminTypes } from '../../store/modules/admin/types'
import { Types as NotificationsTypes } from '../../store/modules/notifications/types'
import { Types as NotificationCommentsTypes } from '../../store/modules/notificationComments/types'
import { Types as NotificationReadersTypes } from '../../store/modules/notificationReaders/types'

// components
import ListPagenation from './ListPagenation'

// helpers
import { privateMediaApi } from '../helper'

// mixins
import { ImageCompressible } from '../mixins/ImageCompressible'

export default {
  name: 'NotificationsListTable',
  mixins: [ ImageCompressible ],
  components: {
    ListPagenation
  },
  data () {
    return {
      pagenations: {},
      commentAttributes: {
        content: '',
        stamp: '',
        attachments_attributes: []
      },
      commentAttachmentAttributes: '',
      openedPanels: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      openedNotification: [],
      comment: '',
      sortOrder: '',
      currentSortCategory: '',
      fileSrc: {},
      fileName: {},
      notificationId: '',
      report_content: '',
      bstr: '',
      stamp: '',
      isAsc: false,
      showCommentDialog: false,
      showApprovalDialog: false,
      showReportCommentDialog: false,
      showStampDialog: false,
      isPreview: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      currentAdmin: AdminTypes.getters.GET_ADMIN,
      notificationsList: NotificationsTypes.getters.GET_NOTIFICATIONS_LIST,
      notificationComments: NotificationCommentsTypes.getters.GET_NOTIFICATION_COMMENTS,
      notificationsListPagenation: NotificationsTypes.getters.GET_NOTIFICATIONS_LIST_PAGENATION,
      notificationsExport: NotificationsTypes.getters.GET_NOTIFICATIONS_EXPORT,
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS
    })
  },
  methods: {
    ...mapActions({
      apiGetNotifications: NotificationsTypes.actions.INDEX,
      apiGetNotificationComments: NotificationCommentsTypes.actions.INDEX,
      apiCreateNotificationComments: NotificationCommentsTypes.actions.CREATE,
      apiReportNotificationComments: NotificationCommentsTypes.actions.REPORT,
      apiReadNotification: NotificationReadersTypes.actions.READ,
      apiApproveNotification: NotificationReadersTypes.actions.APPROVE
    }),
    getImageSrc: function (notification) {
      if (notification.user.attributes.icon.url) {
        privateMediaApi.getPrivateMedia(notification.user.attributes.icon.url, (imageData) => {
          document.getElementById('image_' + notification.id).firstChild.src = window.URL.createObjectURL(imageData)
        })
      } else {
        document.getElementById('image_' + notification.id).firstChild.src = require('../../../assets/images/no_image.png')
      }
    },
    toggleCommentDialog: function () {
      this.showCommentDialog = !this.showCommentDialog
    },
    toggleApprovalDialog: function () {
      this.showApprovalDialog = !this.showApprovalDialog
    },
    toggleReportCommentDialog: function () {
      this.showReportCommentDialog = !this.showReportCommentDialog
    },
    toggleStampDialog: function () {
      this.showStampDialog = !this.showStampDialog
    },
    clearFileAndStamp: function () {
      const img = document.getElementById('image_preview_area_' + this.notificationId)
      const fileName = document.getElementById('post_file_name_' + this.notificationId)
      img.src = ''
      fileName.innerHTML = ''
      this.commentAttachmentAttributes = ''
      this.stamp = ''
      this.isPreview = false
    },
    focusFileInput: function (refsName) {
      this.$refs[refsName][0].click()
    },
    downloadFile (file) {
      if (file.url === null) return
      privateMediaApi.getPrivateMedia(file.url, (imageData) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(imageData)
        link.download = file.name
        link.click()
      })
    },
    setPostingStamp (stamp) {
      this.stamp = stamp
      const img = document.getElementById('image_preview_area_' + this.notificationId)
      const fileName = document.getElementById('post_file_name_' + this.notificationId)
      img.src = require('../../../assets/images/stamps/' + stamp + '.png')
      fileName.innerHTML = ''
      this.commentAttachmentAttributes = ''
      this.toggleStampDialog()
      this.isPreview = true
    },
    async requestPostComment () {
      this.commentAttributes.content = this.comment
      if (this.commentAttachmentAttributes) {
        this.commentAttributes.attachments_attributes.push(this.commentAttachmentAttributes)
        delete this.commentAttributes.content
        delete this.commentAttributes.stamp
      } else {
        delete this.commentAttributes.attachments_attributes
      }
      if (this.stamp) {
        this.commentAttributes.stamp = ':' + this.stamp
        this.commentAttachmentAttributes = ''
        delete this.commentAttributes.content
        delete this.commentAttributes.attachments_attributes
      } else {
        delete this.commentAttributes.stamp
      }
      await this.apiCreateNotificationComments({
        id: this.notificationId,
        notification_comment: this.commentAttributes
      })
      this.comment = ''
      this.commentAttachmentAttributes = ''
      this.stamp = ''
      this.commentAttributes = {
        content: '',
        stamp: '',
        attachments_attributes: []
      }
      this.toggleCommentDialog()
      this.clearFileAndStamp()
      this.isPreview = false
      await this.apiGetNotificationComments(this.notificationId)
      for (let i = 1; i <= this.notificationComments.length; i++) {
        await this.hasAttachments(this.notificationComments[i - 1].attachments[0])
        if (this.notificationComments[i - 1].stamp) {
          const img = this.$refs[this.notificationComments[i - 1].id]['0']
          img.src = require('../../../assets/images/stamps/' + this.notificationComments[i - 1].stamp.slice(1) + '.png')
        } else if (this.notificationComments[i - 1].attachments.length > 0) {
          privateMediaApi.getPrivateMedia(this.notificationComments[i - 1].attachments[0].url, (imageData) => {
            const pos = this.notificationComments[i - 1].attachments[0].name.lastIndexOf('.')
            if (this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'pdf') {
              this.fileSrc[this.notificationComments[i - 1].attachments[0].id] = require('../../../assets/images/pdf_icon.jpeg')
              this.fileName[this.notificationComments[i - 1].attachments[0].id] = this.notificationComments[i - 1].attachments[0].name
            } else if (
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'xls' ||
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'xlsx'
            ) {
              this.fileSrc[this.notificationComments[i - 1].attachments[0].id] = require('../../../assets/images/excel_icon.jpeg')
              this.fileName[this.notificationComments[i - 1].attachments[0].id] = this.notificationComments[i - 1].attachments[0].name
            } else if (
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'ppt' ||
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'pptx'
            ) {
              this.fileSrc[this.notificationComments[i - 1].attachments[0].id] = require('../../../assets/images/ppt_icon.jpeg')
              this.fileName[this.notificationComments[i - 1].attachments[0].id] = this.notificationComments[i - 1].attachments[0].name
            } else if (
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'doc' ||
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'docx'
            ) {
              this.fileSrc[this.notificationComments[i - 1].attachments[0].id] = require('../../../assets/images/docs_icon.jpeg')
              this.fileName[this.notificationComments[i - 1].attachments[0].id] = this.notificationComments[i - 1].attachments[0].name
            } else if (
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'mov' ||
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'MOV' ||
              this.notificationComments[i - 1].attachments[0].name.slice(pos + 1) === 'mp4'
            ) {
              this.fileSrc[this.notificationComments[i - 1].attachments[0].id] = require('../../../assets/images/movie_icon.jpeg')
              this.fileName[this.notificationComments[i - 1].attachments[0].id] = this.notificationComments[i - 1].attachments[0].name
            } else {
              this.fileSrc[this.notificationComments[i - 1].attachments[0].id] = window.URL.createObjectURL(imageData)
            }
          })
        }
      }
    },
    async requestReadNotification (notificationId) {
      await this.apiReadNotification(notificationId)
      await this.apiGetNotifications()
    },
    async requestApproveNotification () {
      await this.apiApproveNotification({
        id: this.notificationId,
        is_approved: true
      })
      await this.apiGetNotifications()
      this.toggleApprovalDialog()
    },
    async requestReportComment (commentId) {
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          this.apiReportNotificationComments({
            notificationId: this.notificationId,
            commentId: commentId,
            report_content: this.report_content
          })
          this.toggleReportCommentDialog()
          this.report_content = ''
        }
      })
    },
    async getFilesAndComments (notification, index) {
      if (this.openedPanels[index] === 0) {
        this.fileName = {}
        this.fileSrc = {}
        return
      }
      this.notificationId = notification.id
      await this.apiGetNotificationComments(notification.id)
      for (let i = 1; i <= notification.attachments.length; i++) {
        if (notification.attachments[i - 1].url === null) {
          setTimeout(() => {
            const fileName = document.getElementById('file_name_' + notification.attachments[i - 1].id)
            const img = document.getElementById('preview_image_' + notification.attachments[i - 1].id)
            img.src = require('../../../assets/images/expired_icon.jpeg')
            img.classList.remove('clickable')
            fileName.innerHTML = '期限切れファイル'
          }, 50)
        } else {
          privateMediaApi.getPrivateMedia(notification.attachments[i - 1].url, (imageData) => {
            const pos = notification.attachments[i - 1].name.lastIndexOf('.')
            const fileName = document.getElementById('file_name_' + notification.attachments[i - 1].id)
            const img = document.getElementById('preview_image_' + notification.attachments[i - 1].id)
            if (notification.attachments[i - 1].name.slice(pos + 1) === 'pdf') {
              img.src = require('../../../assets/images/pdf_icon.jpeg')
              fileName.innerHTML = notification.attachments[i - 1].name
            } else if (
              notification.attachments[i - 1].name.slice(pos + 1) === 'xls' ||
              notification.attachments[i - 1].name.slice(pos + 1) === 'xlsx'
            ) {
              img.src = require('../../../assets/images/excel_icon.jpeg')
              fileName.innerHTML = notification.attachments[i - 1].name
            } else if (
              notification.attachments[i - 1].name.slice(pos + 1) === 'ppt' ||
              notification.attachments[i - 1].name.slice(pos + 1) === 'pptx'
            ) {
              img.src = require('../../../assets/images/ppt_icon.jpeg')
              fileName.innerHTML = notification.attachments[i - 1].name
            } else if (
              notification.attachments[i - 1].name.slice(pos + 1) === 'doc' ||
              notification.attachments[i - 1].name.slice(pos + 1) === 'docx'
            ) {
              img.src = require('../../../assets/images/docs_icon.jpeg')
              fileName.innerHTML = notification.attachments[i - 1].name
            } else if (
              notification.attachments[i - 1].name.slice(pos + 1) === 'mov' ||
              notification.attachments[i - 1].name.slice(pos + 1) === 'MOV' ||
              notification.attachments[i - 1].name.slice(pos + 1) === 'mp4'
            ) {
              img.src = require('../../../assets/images/movie_icon.jpeg')
              fileName.innerHTML = notification.attachments[i - 1].name
            } else {
              const img = document.getElementById('preview_image_' + notification.attachments[i - 1].id)
              img.src = window.URL.createObjectURL(imageData)
            }
          })
        }
      }
      this.openedPanels = this.openedPanels.map((v, i) => {
        return index == i ? 0 : 1
      })
      if (this.checkIfCurrentUserIsInNotReaders(notification)) {
        await this.requestReadNotification(notification.id)
      }
      this.openedNotification.push(this.notificationId)
    },
    checkIfCurrentUserIsInNotReaders (notification) {
      return notification.not_readers.data.some(notReader => {
        return notReader.attributes.id === this.currentAdmin.id
      })
    },
    checkIfCurrentUserIsInNotApprovers (notification) {
      return notification.not_approving_users.data.some(notApprover => {
        return notApprover.attributes.id === this.currentAdmin.id
      })
    },
    hasAttachments (attachments) {
      if (!attachments) return
      if (attachments.length > 0) {
        const attachment = attachments[0]
        if (this.fileSrc[attachment.id]) return true
        let fileName, fileSrc
        if (attachment.url === null) {
          setTimeout(() => {
            fileSrc = require('../../../assets/images/expired_icon.jpeg')
            fileName = '期限切れファイル'
            if (!this.fileName[attachment.id] && fileName) this.fileName = { ...this.fileName, [attachment.id]: fileName }
            if (!this.fileSrc[attachment.id] && fileSrc) this.fileSrc = { ...this.fileSrc, [attachment.id]: fileSrc }
            return true
          }, 50)
        } else {
          privateMediaApi.getPrivateMedia(attachment.url, (imageData) => {
            const pos = attachment.name.lastIndexOf('.')
            if (attachment.name.slice(pos + 1) === 'pdf') {
              fileName = attachment.name
              fileSrc = require('../../../assets/images/pdf_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'xls' ||
              attachment.name.slice(pos + 1) === 'xlsx'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../assets/images/excel_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'ppt' ||
              attachment.name.slice(pos + 1) === 'pptx'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../assets/images/ppt_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'doc' ||
              attachment.name.slice(pos + 1) === 'docx'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../assets/images/docs_icon.jpeg')
            } else if (
              attachment.name.slice(pos + 1) === 'mov' ||
              attachment.name.slice(pos + 1) === 'MOV' ||
              attachment.name.slice(pos + 1) === 'mp4'
            ) {
              fileName = attachment.name
              fileSrc = require('../../../assets/images/movie_icon.jpeg')
            } else {
              fileSrc = window.URL.createObjectURL(imageData)
            }
            if (!this.fileName[attachment.id] && fileName) this.fileName = { ...this.fileName, [attachment.id]: fileName }
            if (!this.fileSrc[attachment.id] && fileSrc) this.fileSrc = { ...this.fileSrc, [attachment.id]: fileSrc }
          })
        }
        return true
      } else {
        return false
      }
    },
    async onImageChange (e) {
      const images = e.target.files || e.dataTransfer.files
      if (!images) {
        return
      }
      this.getBase64(images[0]).then(image => {
        this.commentAttachmentAttributes = {
          file: image,
          name: images[0].name
        }
      })
      loadImage.parseMetaData(images[0], (data) => {
        const options = {
          canvas: true
        }
        if (data.exif) {
          options.orientation = data.exif.get('Orientation')
        }
        loadImage(images[0], (canvas) => {
          const img = document.getElementById('image_preview_area_' + this.notificationId)
          const fileName = document.getElementById('post_file_name_' + this.notificationId)
          const pos = images[0].name.lastIndexOf('.')
          if (images[0].name.slice(pos + 1) === 'pdf') {
            img.src = require('../../../assets/images/pdf_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'xls' ||
            images[0].name.slice(pos + 1) === 'xlsx'
          ) {
            img.src = require('../../../assets/images/excel_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'ppt' ||
            images[0].name.slice(pos + 1) === 'pptx'
          ) {
            img.src = require('../../../assets/images/ppt_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'doc' ||
            images[0].name.slice(pos + 1) === 'docx'
          ) {
            img.src = require('../../../assets/images/docs_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else if (
            images[0].name.slice(pos + 1) === 'mov' ||
            images[0].name.slice(pos + 1) === 'mp4'
          ) {
            img.src = require('../../../assets/images/movie_icon.jpeg')
            fileName.innerHTML = images[0].name
          } else {
            const data = canvas.toDataURL(images[0].type)
            const blob = this.base64ToBlob(data, images[0].type)
            const originUrl = window.URL.createObjectURL(blob)
            const newimg = new Image()
            newimg.src = originUrl
            newimg.onload = () => {
              const resizedCanvas = this.createResizedCanvasElement(newimg)
              const resizedBase64 = resizedCanvas.toDataURL(images[0].type)
              const resizedBlob = this.base64ToBlob(resizedBase64, images[0].type)
              const url = window.URL.createObjectURL(resizedBlob)
              img.src = url
              this.commentAttachmentAttributes.file = resizedBase64
              fileName.innerHTML = ''
            }
          }
        }, options)
      })
      this.stamp = ''
      this.isPreview = true
    },
    async changePage (page) {
      this.pagenations.current = page
      await this.apiGetNotifications({
        page: page,
        status: 1
      })
      this.notificationsList.forEach(notification => {
        this.getImageSrc(notification)
      })
      this.openedPanels = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    }
  },
  async created () {
    await objectFitImages()
    await this.apiGetNotifications({
      status: 1
    })
    const self = this
    this.notificationsList.forEach(notification => {
      self.getImageSrc(notification)
    })
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>

<style scoped>
.avatar-area .v-card{
  border: none !important;
  overflow: hidden;
}
img{
  object-fit: cover;
  font-family: 'object-fit: cover;';
}
.attachment_files{
  width: 200px;
  height: 200px;
  border-radius: 4px;
}
.comment_area{
  border: solid 1px #BDBDBD;
  border-radius: 4px;
  padding: 20px;
}
.comment_attachment_files{
  height: 100px;
  width: 100px;
  border-radius: 4px;
}
.stamps_area img{
  transform: scale(0.6)
}
img.image_preview_area{
  width: 200px;
}
.comment_stamp img{
  width: 200px;
}
</style>
