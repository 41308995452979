import http from '../../../packs/http'
import { removeNamespace } from '../../helper'
import { Types as GlobalTypes } from '../../types'
import { Types } from './types'

const API_PATH = 'admin/roles/'
const _types = removeNamespace('roles/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null
  },
  defaultDetail: {}
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_ROLES_LIST]: state => state.list,
  [_types.getters.GET_ROLES_DETAIL]: state => state.detail
}

const mutations = {
  [_types.mutations.SET_ROLES_LIST] (state, payload) {
    if (payload.master && state.list.length) return
    state.list = Object.assign([], _initialState.list, payload.list)
  },
  [_types.mutations.SET_ROLES_DETAIL] (state, payload) {
    state.detail = Object.assign({}, _initialState.detail, payload)
    state.defaultDetail = Object.assign({}, _initialState.defaultDetail, payload)
  },
  [_types.mutations.RESET_ROLES_DETAIL] (state) {
    state.detail = Object.assign({}, _initialState.detail, state.defaultDetail)
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    await http.get(API_PATH).then(response => {
      commit(_types.mutations.SET_ROLES_LIST, {
        list: response.data.data.map(object => object.attributes)
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.SHOW] ({ commit }, id) {
    commit(_types.mutations.SET_ROLES_DETAIL, {})
    await http.get(API_PATH + id).then(response => {
      commit(_types.mutations.SET_ROLES_DETAIL, response.data.data.attributes)
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.UPDATE] ({ commit }, payload) {
    const params = JSON.stringify({
      role: payload
    })
    await http.put(API_PATH + payload.id, params).then(response => {
      commit(_types.mutations.SET_ROLES_DETAIL, response.data.data.attributes)
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, '権限を更新しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
      commit(_types.mutations.RESET_ROLES_DETAIL)
    })
  },
  [_types.actions.RESET_DETAIL] ({ commit }) {
    commit(_types.mutations.RESET_ROLES_DETAIL)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
