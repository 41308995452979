<template>
  <v-card class="pa-4">
    <v-row class="d-flex justify-end ma-3">
      <v-btn fab top right small color="primary" class="ma-3" @click="onClickNewIncentive"><v-icon>mdi-plus</v-icon></v-btn>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.text">
              <v-row v-if="header.sortable" class="clickable" @click="changeSort(header.value, header.sortable)">
                <v-col cols="6" class="d-flex justify-center align-center">
                  {{ header.text }}
                </v-col>
                <v-col cols="6" class="d-flex justify-start">
                  <v-row>
                    <v-col cols="12" class="pa-0 d-flex align-end">
                      <v-icon :color="currentSortCategory === header.value && isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-up</v-icon>
                    </v-col>
                    <v-col cols="12" class="pa-0 d-flex align-start">
                      <v-icon :color="currentSortCategory === header.value && !isAsc ? 'warning' : ''" v-if="header.sortable" style="height: 80%">mdi-menu-down</v-icon>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  {{ header.text }}
                </v-col>
              </v-row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="incentive in incentivesList" :key="incentive.id" class="clickable" @click="onClickTr(incentive.id)">
            <td>{{ incentive.name | truncate(8) }}</td>
            <td :id="'image_' + incentive.id">
              <img width="50" class="mt-2">
            </td>
            <td>{{ incentive.description | truncate(8) }}</td>
            <td>{{ incentive.point }}</td>
            <td>{{ incentive.left_stock }}</td>
            <td>{{ incentive.prepared_stock }}</td>
            <td>{{ incentive.exchangeable_per_user }}</td>
            <td>{{ incentive.start_exchange_at | moment }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="d-flex justify-end ma-5">
      <div>
        <ListPagenation :pages="incentivesListPagenation.pages" :current="incentivesListPagenation.current" :changePageCallback="changePage"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as IncentivesTypes } from '../../../store/modules/incentives/types'

// components
import ListPagenation from '../../molecules/ListPagenation'

// helper
import { privateMediaApi } from '../../helper'

export default {
  name: 'IncentivesListTable',
  components: {
    ListPagenation
  },
  data () {
    return {
      isAsc: false,
      headers: [
        {
          text: 'インセンティブ名',
          sortable: false,
          value: 'name'
        },
        {
          text: '画像',
          sortable: false,
          value: 'image'
        },
        {
          text: '説明',
          sortable: false,
          value: 'description'
        },
        {
          text: '必要ポイント',
          sortable: true,
          value: 'point'
        },
        {
          text: '残数',
          sortable: true,
          value: 'left_stock'
        },
        {
          text: '準備数',
          sortable: true,
          value: 'prepared_stock'
        },
        {
          text: '交換可能数',
          sortable: true,
          value: 'exchangeable_per_user'
        },
        {
          text: '交換開始日時',
          sortable: true,
          value: 'start_exchange_at'
        }
      ],
      images: {},
      pagenations: {},
      sortOrder: '',
      currentSortCategory: ''
    }
  },
  computed: {
    ...mapGetters({
      incentivesList: IncentivesTypes.getters.GET_INCENTIVES_LIST,
      incentivesListPagenation: IncentivesTypes.getters.GET_INCENTIVES_LIST_PAGENATION
    })
  },
  methods: {
    ...mapActions({
      apiGetIncentives: IncentivesTypes.actions.INDEX
    }),
    onClickTr: function (id) {
      this.$router.push({ name: 'showIncentive', params: { id: id } })
    },
    onClickNewIncentive: function () {
      this.$router.push({ name: 'newIncentive' })
    },
    getImageSrc: function (incentive) {
      privateMediaApi.getPrivateMedia(incentive.image, (imageData) => {
        document.getElementById('image_' + incentive.id).firstChild.src = window.URL.createObjectURL(imageData)
      })
    },
    changeSort (sort, sortable) {
      if (!sortable) return
      this.isAsc = !this.isAsc
      this.sortOrder = this.isAsc ? 'asc' : 'desc'
      // ソートするカテゴリを変えたときは昇順で並び替えて1ページ目に戻る
      if (this.currentSortCategory !== sort) {
        this.currentSortCategory = sort
        this.isAsc = true
        this.sortOrder = 'asc'
      }
      this.apiGetIncentives({
        page: 1,
        sort: sort + ' ' + this.sortOrder
      })
    },
    async changePage (page) {
      this.pagenations.current = page
      await this.apiGetIncentives({
        page: page
      })
      this.incentivesList.forEach(incentive => {
        this.getImageSrc(incentive)
      })
    }

  },
  async created () {
    await this.apiGetIncentives()
    const self = this
    this.incentivesList.forEach(incentive => {
      self.getImageSrc(incentive)
    })
  },
  filters: {
    moment (date) {
      return date ? moment(date).format('YYYY/MM/DD HH:mm') : ''
    },
    truncate (value, len, suf) {
      if (!value) return
      const length = +len
      const suffix = suf ? suf.toString() : '...'
      if (value.length <= length) return value
      return value.substring(0, length) + suffix
    }
  }
}
</script>
