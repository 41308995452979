<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="ID"
            disabled
            v-model="messageReportModel.id"
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="通報日時"
            disabled
            v-model="created_at"
            filled>
          </v-text-field>
        </v-col>
        <v-col v-if="postUserId" cols="12">
          <v-btn color="primary" @click="toPostUser(postUserId)" text x-large>通報されたメッセージの投稿者詳細へ</v-btn>
        </v-col>
        <v-col cols="12" class="pb-5">
          <div v-show="messageAttachments && messageAttachments.length > 0">
            <v-subheader class="font-weight-bold">通報された添付ファイル</v-subheader>
            <img
              @click="downloadFile()"
              class="clickable"
              id="attachment_file">
            <v-subheader id="post_file_name"></v-subheader>
          </div>
          <div v-show="stamp">
            <v-subheader class="font-weight-bold">通報されたスタンプ</v-subheader>
            <img
              id="attachment_stamp">
          </div>
          <v-textarea
            name="input-7-1"
            label="通報されたメッセージ"
            disabled
            v-model="content"
            auto-grow
            filled>
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="通報者"
            disabled
            v-model="whistleBlower"
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            name="input-7-1"
            label="通報者がこのメッセージを通報した理由"
            disabled
            v-model="report_content"
            auto-grow
            filled>
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="messageReportModel.status"
            :items="statusOptions"
            label="ステータス"
            :disabled="!isEditable">
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="対応内容"
            auto-grow
            :disabled="!isEditable"
            v-model="messageReportModel.support_content">
          </v-textarea>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">更新</v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as MessageReportsTypes } from '../../../store/modules/messageReports/types'

// components
import DestroyDialog from '../../molecules/DestroyDialog'

// helpers
import { privateMediaApi } from '../../helper'

export default {
  name: 'MessageReportsForm',
  components: {
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこの通報履歴を削除してもよろしいですか？'
      },
      messageReportModel: {},
      statusOptions: [
        { text: '未対応', value: 0 },
        { text: '対応中', value: 1 },
        { text: '対応完了', value: 2 }
      ],
      messageAttachments: [],
      stamp: '',
      postUserId: '',
      content: '',
      report_content: '',
      created_at: '',
      whistleBlower: '',
      isEditable: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      messageReport: MessageReportsTypes.getters.GET_MESSAGE_REPORTS_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetMessageReport: MessageReportsTypes.actions.SHOW,
      apiUpdateMessageReport: MessageReportsTypes.actions.UPDATE,
      apiDestroyMessageReport: MessageReportsTypes.actions.DESTROY,
      resetMessageReport: MessageReportsTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetMessageReport()
      this.messageReportModel = this.messageReport
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    downloadFile () {
      privateMediaApi.getPrivateMedia(this.messageReport.message.attributes.attachments[0].url, (imageData) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(imageData)
        link.download = this.messageReport.message.attributes.attachments[0].name
        link.click()
      })
    },
    async requestDestroy () {
      await this.apiDestroyMessageReport(this.messageReport.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexMessageReport' })
      }, 30)
    },
    onSubmit: function () {
      this.requestUpdate()
    },
    async requestUpdate () {
      await this.apiUpdateMessageReport(this.messageReportModel)
      if (this.httpSuccess) this.toggleEditable()
    },
    toPostUser: function (id) {
      this.$router.push({ name: 'showUser', params: { id: id } })
    },
    getImageSrc: function (url) {
      privateMediaApi.getPrivateMedia(url, (imageData) => {
        const fileName = this.commentAttachments[0].name
        const pos = fileName.lastIndexOf('.')
        const fileDescription = document.getElementById('post_file_name')
        const img = document.getElementById('attachment_file')
        if (fileName.slice(pos + 1) === 'pdf') {
          img.src = require('../../../../assets/images/pdf_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'xls' ||
          fileDescription.slice(pos + 1) === 'xlsx'
        ) {
          img.src = require('../../../../assets/images/excel_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'ppt' ||
          fileDescription.slice(pos + 1) === 'pptx'
        ) {
          img.src = require('../../../../assets/images/ppt_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'doc' ||
          fileDescription.slice(pos + 1) === 'docx'
        ) {
          img.src = require('../../../../assets/images/docs_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'mov' ||
          fileDescription.slice(pos + 1) === 'mp4'
        ) {
          img.src = require('../../../../assets/images/movie_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else {
          img.src = window.URL.createObjectURL(imageData)
        }
      })
    },
    getStampSrc (stamp) {
      const img = document.getElementById('attachment_stamp')
      img.src = require('../../../../assets/images/stamps/' + stamp.slice(1) + '.png')
    }
  },
  async created () {
    await this.apiGetMessageReport(this.$route.params.id)
    this.messageReportModel = this.messageReport
    this.report_content = this.messageReportModel.report_content
    this.created_at = moment(this.messageReport.created_at).format('YYYY/MM/DD')
    this.whistleBlower = this.messageReportModel.user ? this.messageReportModel.user.attributes.last_name + this.messageReportModel.user.attributes.first_name : '[ このユーザーは削除されました ]'
    if (this.messageReport.message === null) {
      this.postUserId = null
      this.messageAttachments = null
      this.stamp = null
      this.content = '[ このメッセージは削除されました ]'
    } else {
      this.postUserId = this.messageReport.message.attributes.user_id
      this.messageAttachments = this.messageReport.message.attributes.attachments
      this.stamp = this.messageReport.message.attributes.stamp
      this.content = this.messageReport.message.attributes.content
      if (this.messageAttachments.length > 0) {
        this.getImageSrc(this.messageAttachments[0].url)
      } else if (this.stamp) {
        this.getStampSrc(this.stamp)
      }
    }
  }
}
</script>

<style>
#attachment_file{
  width: 150px;
}
#attachment_stamp{
  width: 200px;
}
</style>
