const namespace = 'roles/'

export const Types = {
  getters: {
    GET_ROLES_LIST: namespace + 'GET_ROLES_LIST',
    GET_ROLES_DETAIL: namespace + 'GET_ROLES_DETAIL'
  },
  mutations: {
    SET_ROLES_LIST: namespace + 'SET_ROLES_LIST',
    SET_ROLES_DETAIL: namespace + 'SET_ROLES_DETAIL',
    RESET_ROLES_DETAIL: namespace + 'RESET_ROLES_DETAIL'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    UPDATE: namespace + 'UPDATE',
    RESTORE: namespace + 'RESTORE',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
