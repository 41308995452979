<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-if="type === 'edit'"
            v-model="helpCategoryModel.id"
            label="ID"
            disabled
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="helpCategoryModel.name"
            name="helpCategory[name]"
            v-validate="'required'"
            data-vv-as="カテゴリ名"
            :customValidation="isEditable && validated"
            :isValid="!errors.has('helpCategory[name]')"
            :error-messages="errors.collect('helpCategory[name]')"
            :disabled="!isEditable">
            <template v-slot:label>
              カテゴリ名<span class="ml-2 red--text text--accent-2">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">
          {{ this.type === 'new' ? '新規登録' : '更新' }}
        </v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as HelpCategoriesTypes } from '../../../store/modules/helpCategories/types'

// components
import DestroyDialog from '../../molecules/DestroyDialog'

export default {
  name: 'HelpCategoriesForm',
  components: {
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのヘルプカテゴリを削除してもよろしいですか？'
      },
      helpCategoryModel: {},
      isEditable: false,
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      helpCategory: HelpCategoriesTypes.getters.GET_HELP_CATEGORIES_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetHelpCategory: HelpCategoriesTypes.actions.SHOW,
      apiCreateHelpCategory: HelpCategoriesTypes.actions.CREATE,
      apiUpdateHelpCategory: HelpCategoriesTypes.actions.UPDATE,
      apiDestroyHelpCategory: HelpCategoriesTypes.actions.DESTROY,
      apiRestoreHelpCategory: HelpCategoriesTypes.actions.RESTORE,
      resetHelpCategory: HelpCategoriesTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetHelpCategory()
      this.helpCategoryModel = this.helpCategory
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    onSubmit: function () {
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          switch (this.type) {
            case 'new':
              this.requestCreate()
              break
            case 'edit':
              this.requestUpdate()
              break
          }
        }
      })
    },
    async requestDestroy () {
      await this.apiDestroyHelpCategory(this.helpCategory.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexHelpCategory' })
      }, 30)
    },
    async requestCreate () {
      await this.apiCreateHelpCategory(this.helpCategoryModel)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'showHelpCategory', params: { id: this.helpCategory.id } })
      }, 30)
    },
    async requestUpdate () {
      await this.apiUpdateHelpCategory(this.helpCategoryModel)
      setTimeout(() => {
        if (this.httpSuccess) this.toggleEditable()
      }, 30)
    }
  },
  async created () {
    if (this.$route.params.id) {
      await this.apiGetHelpCategory(this.$route.params.id)
      this.helpCategoryModel = this.helpCategory
    } else {
      this.isEditable = !this.isEditable
    }
  }
}
</script>
