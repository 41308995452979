<template>
  <v-card class="pa-4">
    <v-row v-if="type === 'edit'">
      <v-col cols="6" class="d-flex justify-start">
        <transition name="slide-fade">
          <h2 v-if="isEditable" class="edit-text ma-3 h2-responsive display-1 font-weight-bold grey--text text--darken-1">
            <span>編</span>
            <span>集</span>
            <span>中</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </h2>
        </transition>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn class="text-right ma-2 ma-sm-3" fab top right small :color="isEditable ? 'grey' : 'primary'" @click="toggleEditable">
          <v-icon v-if="!isEditable">mdi-pencil</v-icon>
          <v-icon v-else color="white">mdi-close</v-icon>
        </v-btn>
        <v-btn fab top right small color="red" class="ma-2 ma-sm-3" @click="toggleDestroyDialog">
          <v-icon color="white">mdi-delete</v-icon>
        </v-btn>
        <DestroyDialog
          :dialog="destroyDialogAttributes"
          :toggleDestroyDialog="toggleDestroyDialog"
          :requestDestroy="requestDestroy"/>
      </v-col>
    </v-row>
    <form @submit.prevent="onSubmit">
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="ID"
            disabled
            v-model="notificationCommentsReportModel.id"
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="通報日時"
            disabled
            v-model="created_at"
            filled>
          </v-text-field>
        </v-col>
        <v-col v-if="postUserId" cols="12">
          <v-btn color="primary" @click="toPostUser(postUserId)" text x-large>通報されたコメントの投稿者詳細へ</v-btn>
        </v-col>
        <v-col cols="12" class="pb-5">
          <div v-show="commentAttachments && commentAttachments.length > 0">
            <v-subheader class="font-weight-bold">通報された添付ファイル</v-subheader>
            <img
              @click="downloadFile()"
              class="clickable"
              id="attachment_file">
            <v-subheader id="post_file_name"></v-subheader>
          </div>
          <div v-show="stamp">
            <v-subheader class="font-weight-bold">通報されたスタンプ</v-subheader>
            <img
              id="attachment_stamp">
          </div>
          <v-textarea
            v-show="content"
            name="input-7-1"
            label="通報されたコメント"
            disabled
            v-model="content"
            auto-grow
            filled>
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="通報者"
            disabled
            v-model="whistleBlower"
            filled>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            name="input-7-1"
            label="通報者がこのコメントを通報した理由"
            disabled
            v-model="report_content"
            auto-grow
            filled>
          </v-textarea>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="notificationCommentsReportModel.status"
            :items="statusOptions"
            label="ステータス"
            :disabled="!isEditable">
          </v-select>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="対応内容"
            auto-grow
            :disabled="!isEditable"
            v-model="notificationCommentsReportModel.support_content">
          </v-textarea>
        </v-col>
      </v-row>
      <div v-if="isEditable" class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">更新</v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../../store/types'
import { Types as NotificationCommentsReportsTypes } from '../../../store/modules/notificationCommentsReports/types'

// components
import DestroyDialog from '../../molecules/DestroyDialog'

// helpers
import { privateMediaApi } from '../../helper'

export default {
  name: 'NotificationCommentsReportsForm',
  components: {
    DestroyDialog
  },
  props: {
    type: String
  },
  data () {
    return {
      destroyDialogAttributes: {
        active: false,
        text: '本当にこのコメント通報履歴を削除してもよろしいですか？'
      },
      notificationCommentsReportModel: {},
      statusOptions: [
        { text: '未対応', value: 0 },
        { text: '対応中', value: 1 },
        { text: '対応完了', value: 2 }
      ],
      commentAttachments: [],
      stamp: '',
      postUserId: '',
      content: '',
      report_content: '',
      created_at: '',
      whistleBlower: '',
      isEditable: false
    }
  },
  computed: {
    ...mapGetters({
      httpSuccess: GlobalTypes.getters.GET_HTTP_SUCCESS,
      notificationCommentsReport: NotificationCommentsReportsTypes.getters.GET_NOTIFICATION_COMMENT_REPORTS_DETAIL
    })
  },
  methods: {
    ...mapActions({
      apiGetNotificationCommentsReport: NotificationCommentsReportsTypes.actions.SHOW,
      apiUpdateNotificationCommentsReport: NotificationCommentsReportsTypes.actions.UPDATE,
      apiDestroyNotificationCommentsReport: NotificationCommentsReportsTypes.actions.DESTROY,
      resetNotificationCommentsReport: NotificationCommentsReportsTypes.actions.RESET_DETAIL
    }),
    toggleEditable: function () {
      this.isEditable = !this.isEditable
      this.resetNotificationCommentsReport()
      this.notificationCommentsReportModel = this.notificationCommentsReport
    },
    toggleDestroyDialog: function () {
      this.destroyDialogAttributes.active = !this.destroyDialogAttributes.active
    },
    downloadFile () {
      privateMediaApi.getPrivateMedia(this.notificationCommentsReport.comment.attributes.attachments[0].url, (imageData) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(imageData)
        link.download = this.notificationCommentsReport.comment.attributes.attachments[0].name
        link.click()
      })
    },
    async requestDestroy () {
      await this.apiDestroyNotificationCommentsReport(this.notificationCommentsReport.id)
      setTimeout(() => {
        if (this.httpSuccess) this.$router.push({ name: 'indexNotificationCommentReport' })
      }, 30)
    },
    onSubmit: function () {
      this.requestUpdate()
    },
    async requestUpdate () {
      await this.apiUpdateNotificationCommentsReport(this.notificationCommentsReportModel)
      if (this.httpSuccess) this.toggleEditable()
    },
    toPostUser: function (id) {
      this.$router.push({ name: 'showUser', params: { id: id } })
    },
    getImageSrc: function (url) {
      privateMediaApi.getPrivateMedia(url, (imageData) => {
        const fileName = this.commentAttachments[0].name
        const pos = fileName.lastIndexOf('.')
        const fileDescription = document.getElementById('post_file_name')
        const img = document.getElementById('attachment_file')
        if (fileName.slice(pos + 1) === 'pdf') {
          img.src = require('../../../../assets/images/pdf_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'xls' ||
          fileDescription.slice(pos + 1) === 'xlsx'
        ) {
          img.src = require('../../../../assets/images/excel_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'ppt' ||
          fileDescription.slice(pos + 1) === 'pptx'
        ) {
          img.src = require('../../../../assets/images/ppt_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'doc' ||
          fileDescription.slice(pos + 1) === 'docx'
        ) {
          img.src = require('../../../../assets/images/docs_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else if (
          fileDescription.slice(pos + 1) === 'mov' ||
          fileDescription.slice(pos + 1) === 'mp4'
        ) {
          img.src = require('../../../../assets/images/movie_icon.jpeg')
          fileDescription.innerHTML = fileName
        } else {
          img.src = window.URL.createObjectURL(imageData)
        }
      })
    },
    getStampSrc (stamp) {
      const img = document.getElementById('attachment_stamp')
      img.src = require('../../../../assets/images/stamps/' + stamp.slice(1) + '.png')
    }
  },
  async created () {
    await this.apiGetNotificationCommentsReport(this.$route.params.id)
    this.notificationCommentsReportModel = this.notificationCommentsReport
    this.report_content = this.notificationCommentsReport.report_content
    this.created_at = moment(this.notificationCommentsReport.created_at).format('YYYY/MM/DD HH:mm')
    this.whistleBlower = this.notificationCommentsReportModel.user ? this.notificationCommentsReportModel.user.attributes.last_name + this.notificationCommentsReportModel.user.attributes.first_name : '[ このユーザーは削除されました ]'
    if (this.notificationCommentsReport.comment === null) {
      this.postUserId = null
      this.commentAttachments = null
      this.stamp = null
      this.content = "[ このコメントは削除されました ]"
    } else {
      this.postUserId = this.notificationCommentsReport.comment.relationships.user.data.id
      this.commentAttachments = this.notificationCommentsReport.comment.attributes.attachments
      this.stamp = this.notificationCommentsReport.comment.attributes.stamp
      this.content = this.notificationCommentsReport.comment.attributes.content
      if (this.commentAttachments.length > 0) {
        this.getImageSrc(this.commentAttachments[0].url)
      } else if (this.stamp) {
        this.getStampSrc(this.stamp)
      }
    }
  }
}
</script>

<style>
#attachment_file{
  width: 150px;
}
#attachment_stamp{
  width: 200px;
}
</style>
