import http from '../../../packs/http'
import {
  removeNamespace,
  serializeObjectToQueryParameter,
  getFileNameFromContentDisposition
} from '../../helper'
import { Types } from './types'
import { Types as GlobalTypes } from '../../types'

const API_PATH = 'admin/presents/'
const _types = removeNamespace('presents/', Types)

const _initialState = {
  list: [],
  detail: {
    id: null,
    coin: [],
    from: [],
    to: []
  },
  defaultDetail: {},
  pagenation: {},
  queryParams: {},
  export: {
    data: null,
    fileName: null
  }
}

const state = Object.assign({}, _initialState)

const getters = {
  [_types.getters.GET_PRESENTS_LIST]: state => state.list,
  [_types.getters.GET_PRESENTS_LIST_PAGENATION]: state => state.pagenation,
  [_types.getters.GET_PRESENTS_DETAIL]: state => state.detail,
  [_types.getters.GET_PRESENTS_EXPORT]: state => state.export
}

const mutations = {
  [_types.mutations.SET_PRESENTS_LIST] (state, payload) {
    state.list = Object.assign([], _initialState.list, payload.list)
    state.pagenation = Object.assign({}, _initialState.list, payload.pagenation)
  },
  [_types.mutations.SET_PRESENTS_DETAIL] (state, payload) {
    state.detail = Object.assign({}, _initialState.detail, payload)
    state.defaultDetail = Object.assign({}, _initialState.defaultDetail, payload)
  },
  [_types.mutations.RESET_PRESENTS_DETAIL] (state) {
    state.detail = Object.assign({}, _initialState.detail, state.defaultDetail)
  },
  [_types.mutations.SET_QUERY_PARAMS] (state, payload) {
    state.queryParams = payload
  },
  [_types.mutations.SET_PRESENTS_EXPORT] (state, payload) {
    state.export.data = payload.data
    state.export.fileName = payload.fileName
  }
}

const actions = {
  async [_types.actions.INDEX] ({ commit, state }, payload) {
    var queryParams = {}
    if (payload) {
      // full取得する場合には前回のクエリパラメータを引き継がない
      queryParams = payload.full ? payload : Object.assign({}, state.queryParams, payload)
    }
    commit(_types.mutations.SET_QUERY_PARAMS, queryParams)
    await http.get(API_PATH + serializeObjectToQueryParameter(queryParams)).then(response => {
      commit(_types.mutations.SET_PRESENTS_LIST, {
        list: response.data.data.map(
          presents => Object.assign(
            presents.attributes,
            {
              coin: presents.relationships.coin.data ? response.data.included
                .filter(resource => resource.type === 'coin' && presents.relationships.coin.data.id === resource.id)[0] : null,
              from: presents.relationships.from.data ? response.data.included
                .filter(resource => resource.type === 'user' && presents.relationships.from.data.id === resource.id)[0] : null,
              to: presents.relationships.to.data ? response.data.included
                .filter(resource => resource.type === 'user' && presents.relationships.to.data.id === resource.id)[0] : null
            }
          )
        ),
        pagenation: response.data.pagenation
      })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.SHOW] ({ commit }, id) {
    commit(_types.mutations.SET_PRESENTS_DETAIL, {})
    await http.get(API_PATH + id).then(response => {
      commit(_types.mutations.SET_PRESENTS_DETAIL,
        Object.assign(
          response.data.data.attributes,
          {
            coin: response.data.data.relationships.coin.data ? response.data.included
              .filter(resource => resource.type === 'coin' && resource.id === response.data.data.relationships.coin.data.id)[0] : null,
            from: response.data.data.relationships.from.data ? response.data.included
              .filter(resource => resource.type === 'user' && resource.id === response.data.data.relationships.from.data.id)[0] : null,
            to: response.data.data.relationships.to.data ? response.data.included
              .filter(resource => resource.type === 'user' && resource.id === response.data.data.relationships.to.data.id)[0] : null
          }
        )
      )
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  async [_types.actions.UPDATE] ({ commit }, payload) {
    const params = JSON.stringify({
      present: payload
    })
    await http.put(API_PATH + payload.id, params).then(response => {
      commit(_types.mutations.SET_PRESENTS_DETAIL, response.data.data.attributes)
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'コイン贈呈履歴を更新しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
      commit(_types.mutations.RESET_PRESENTS_DETAIL)
    })
  },
  async [_types.actions.DESTROY] ({ commit }, id) {
    await http.delete(API_PATH + id).then(response => {
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'コイン贈呈履歴を削除しました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  },
  [_types.actions.RESET_DETAIL] ({ commit }) {
    commit(_types.mutations.RESET_PRESENTS_DETAIL)
  },
  async [_types.actions.EXPORT] ({ commit }, payload) {
    await http.get(API_PATH + 'export' + serializeObjectToQueryParameter(state.queryParams), {
      responseType: 'blob'
    }).then(response => {
      commit(_types.mutations.SET_PRESENTS_EXPORT, {
        data: response.data,
        fileName: getFileNameFromContentDisposition(response.headers['content-disposition'])
      })
      commit(GlobalTypes.mutations.SET_HTTP_SUCCESS, 'コイン贈呈履歴(CSV)をエクスポートしました。', { root: true })
    }).catch(error => {
      commit(GlobalTypes.mutations.SET_HTTP_ERROR, error, { root: true })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
