<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageHeading heading="ヘルプ詳細"/>
      </v-col>
      <v-col cols="12">
        <HelpsForm type="edit"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// components
import PageHeading from '../../atoms/PageHeading'
import HelpsForm from '../../organisms/helps/HelpsForm'

export default {
  components: {
    PageHeading,
    HelpsForm
  }
}
</script>
