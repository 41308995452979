const namespace = 'notifications/'

export const Types = {
  getters: {
    GET_NOTIFICATIONS_LIST: namespace + 'GET_NOTIFICATIONS_LIST',
    GET_NOTIFICATIONS_LIST_PAGENATION: namespace + 'GET_NOTIFICATIONS_LIST_PAGENATION',
    GET_NOTIFICATIONS_DETAIL: namespace + 'GET_NOTIFICATIONS_DETAIL',
    GET_NOTIFICATIONS_EXPORT: namespace + 'GET_NOTIFICATIONS_EXPORT'
  },
  mutations: {
    SET_NOTIFICATIONS_LIST: namespace + 'SET_NOTIFICATIONS_LIST',
    SET_NOTIFICATIONS_DETAIL: namespace + 'SET_NOTIFICATIONS_DETAIL',
    SET_NOTIFICATIONS_DETAIL_GROUPS: namespace + 'SET_NOTIFICATIONS_DETAIL_GROUPS',
    SET_NOTIFICATIONS_DETAIL_USER: namespace + 'SET_NOTIFICATIONS_DETAIL_USER',
    RESET_NOTIFICATIONS_DETAIL: namespace + 'RESET_NOTIFICATIONS_DETAIL',
    SET_QUERY_PARAMS: namespace + 'SET_QUERY_PARAMS',
    SET_NOTIFICATIONS_EXPORT: namespace + 'SET_NOTIFICATIONS_EXPORT'
  },
  actions: {
    INDEX: namespace + 'INDEX',
    SHOW: namespace + 'SHOW',
    CREATE: namespace + 'CREATE',
    UPDATE: namespace + 'UPDATE',
    DESTROY: namespace + 'DESTROY',
    RESTORE: namespace + 'RESTORE',
    EXPORT: namespace + 'EXPORT',
    NOTIFY_NOT_READERS: namespace + 'NOTIFY_NOT_READERS',
    RESET_DETAIL: namespace + 'RESET_DETAIL'
  }
}
