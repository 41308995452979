<template>
  <v-pagination
    :value="current"
    :length="pages"
    @input="changePageCallback"
    :total-visible="6"
    circle
  ></v-pagination>
</template>

<script>
export default {
  name: 'ListPagenation',
  props: {
    pages: Number,
    current: Number,
    changePageCallback: Function
  }
}
</script>
