<template>
  <v-card class="pa-4">
    <h2 class="h2-responsive font-weight-bold grey--text text--darken-1 text-center">ログイン</h2>
    <form @submit.prevent="onSubmit">
      <v-text-field
        v-model="sign_in_id"
        type="text"
        label="メールアドレスもしくは電話番号"
        name="user[id]"
        v-validate="'required'"
        data-vv-as="メールアドレスもしくは電話番号"
        :customValidation="validated"
        :isValid="!errors.has('user[id]')"
        :error-messages="errors.collect('user[id]')">
      </v-text-field>
      <v-text-field
        v-model="password"
        type="password"
        label="パスワード"
        name="user[password]"
        v-validate="'required'"
        data-vv-as="パスワード"
        :customValidation="validated"
        :isValid="!errors.has('user[password]')"
        :error-messages="errors.collect('user[password]')">
      </v-text-field>
      <div class="text-center py-4 mt-3">
        <v-btn color="primary" type="submit">
          ログイン
        </v-btn>
      </div>
    </form>
  </v-card>
</template>

<script>
// libraries
import { mapGetters, mapActions } from 'vuex'

// storeTyopes
import { Types as GlobalTypes } from '../../store/types'
import { Types as AuthTypes } from '../../store/modules/auth/types'

// components

// helper

export default {
  name: 'SignInForm',
  components: {
  },
  props: {
    type: String
  },
  data () {
    return {
      sign_in_id: '',
      password: '',
      validated: false
    }
  },
  computed: {
    ...mapGetters({
      hasSignedIn: AuthTypes.getters.HAS_SIGNED_IN
    })
  },
  methods: {
    ...mapActions({
      fetchMasters: GlobalTypes.actions.FETCH_MASTERS,
      signIn: AuthTypes.actions.SIGN_IN
    }),
    onSubmit: function () {
      this.$validator.validateAll().then((result) => {
        this.validated = true
        if (result) {
          this.requestSignIn()
        }
      })
    },
    async requestSignIn () {
      await this.signIn({
        sign_in_id: this.sign_in_id,
        password: this.password
      })
      if (this.hasSignedIn) {
        this.$router.push({ name: 'home' })
      }
    }
  },
  async created () {
  }
}
</script>
